import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { authenticatedState } from './recoil/authenticatedAtom';
import { authIntentState } from './recoil/authIntentAtom';

const ProtectedRoute = () => {
  const isAuthenticated = useRecoilValue(authenticatedState);
  const location = useLocation();
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  const searchParams = new URLSearchParams(location.search);
  const step = searchParams.get('step');
  const { id } = useParams();
  useEffect(() => {
    console.log(isAuthenticated, 'isAuthenticated');
    if (!isAuthenticated) {
      const pathName =
        id && location.pathname === `/booking/${id}`
          ? `${location.pathname}?step=information`
          : location.pathname === '/personal-info'
          ? '/main'
          : location.pathname.includes('host')
          ? '/host/manage-storage'
          : location.pathname;
      setAuthIntent({
        ...authIntent,
        previousPath: '/main',
        intentPath: pathName,
      });
    } else {
      setAuthIntent({
        ...authIntent,
        previousPath: '/main',
        intentPath: location.pathname,
      });
    }
  }, [isAuthenticated]);

  const returnComponent = () => {
    if (step && step === 'information') {
      return <Outlet />;
    } else if (isAuthenticated) {
      return <Outlet />;
    } else {
      return <Navigate to="/log-in" state={{ from: location }} replace />;
    }
  };
  return returnComponent();
};

export default ProtectedRoute;
