import styled from '@emotion/styled';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiResponseType } from '../apis/adminApi';
import { GetPersonalInfoType, getPersonalInfoApi, postPersonalInfoApi } from '../apis/loginApi';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { modalState } from '../recoil/modalAtom';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { authenticatedState } from '../recoil/authenticatedAtom';
import { adminAuthenticatedState } from '../recoil/adminAuthenticatedAtom';
import { PersonalInfoType, hasPersonalInfoState } from '../recoil/personalInfo';
import { maskLastFourDigits } from '../utils/phoneNumber';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { AxiosError } from 'axios';
import ToggleDown from '../assets/images/TimeToggleDown.png';
import ToggleUp from '../assets/images/TimeToggleUp.png';
import Spinner from '../components/common/Spinner';
import FaqDocument from '../components/etc/FaqDocument';
import TermsDocument from '../components/etc/TermsDocument';
import PrivacyPolicyDocument from '../components/etc/PrivacyPolicyDocument';
import CustomerServiceDocument from '../components/etc/CustomerServiceDocument';

interface CustomDataTypeForErrorDetail {
  detail: string;
}
const MyPage = () => {
  const { t } = useTranslation();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const setModal = useSetRecoilState(modalState);
  const navigate = useNavigate();
  const [, , removeCookie] = useCookies(['access_token', 'refresh_token']);
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authenticatedState);
  const setIsAdminAuthenticated = useSetRecoilState(adminAuthenticatedState);
  const setPersonalInfo = useSetRecoilState(hasPersonalInfoState);
  const [informationToggleOpen, setInformationToggleOpen] = useState({
    customer_service: false,
    faq: false,
    terms_of_service: false,
    privacy_policy: false,
  });

  const [nationalNumber, setNationalNumber] = useState<string>('');
  const {
    data,
    isError: personalInfoError,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['personalInfo'],
    queryFn: async (): Promise<ApiResponseType<GetPersonalInfoType>> => getPersonalInfoApi(),
    enabled: !!isAuthenticated,
    retry: (failCount, error) => {
      if ((error as AxiosError).response!.status === 404) return false;
      if (failCount >= 1) return false;
      return true;
    },
  });
  const [phone, setPhone] = useState<string>(
    data?.data.phoneNumber && data.data.phoneNumber === '+82 1000000000'
      ? ''
      : data?.data.phoneNumber || '',
  );
  useEffect(() => {
    console.log(data, 'phone');
  }, [data]);
  const {
    reset,
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: data?.data?.name || '',
      email: data?.data?.email || '',
    },
  });
  useEffect(() => {
    if (data?.data) {
      setValue('name', data.data.name);
      setValue('email', data.data.email);
    }
  }, [data, setValue]);
  const queryClient = useQueryClient();
  const name = watch('name');
  const email = watch('email');

  // 다국적인 데이터 입력 유효성 검사 필요. 정규표현식 수정 필요
  const validateName = (value: string) => {
    if (!value) return '이름을 입력해주세요';
    if (!/^[\p{L}\p{M}\p{Zs}.'-]{2,}$/u.test(value)) return '2글자 이상 적어주세요.';
    return true;
  };
  const validateEmail = (value: string) => {
    if (!value) return '이메일을 입력해주세요';
    if (!/\S+@\S+\.\S+/.test(value)) return '올바른 이메일 형식이 아닙니다.';
    return true;
  };
  const handleChange = (value: string, data: any) => {
    setPhone(value);
    setNationalNumber(value.slice(data.dialCode.length));
  };
  const isAxiosError = (error: unknown): error is AxiosError<CustomDataTypeForErrorDetail> => {
    return (error as AxiosError<CustomDataTypeForErrorDetail>).isAxiosError !== undefined;
  };
  const postPersonalInfoMutation = useMutation<ApiResponseType<{}>, Error, PersonalInfoType>({
    mutationFn: (formData: PersonalInfoType) => postPersonalInfoApi(formData),
    onSuccess: (data: ApiResponseType<any>) => {
      reset();
      queryClient.removeQueries({ queryKey: ['personalInfo'] });
      setIsEditOpen(false);
    },
    onError: (error) => {
      if (isAxiosError(error) && error?.response?.data?.detail === '유효하지 않은 전화번호') {
        alert(t('myPage.not_valid_phone'));
      } else {
        console.log(error, 'postPersonalInfoMutation error');
        alert(t('unexpected_error'));
      }
    },
  });
  const onSubmit = () => {
    if (!nationalNumber) {
      const formData: PersonalInfoType = {
        email: email,
        name: name,
        rawPhoneNumber: '+8201000000000',
      };
      postPersonalInfoMutation.mutate(formData);
    } else {
      const formData: PersonalInfoType = {
        email,
        name,
        rawPhoneNumber: `+${phone}`,
      };
      postPersonalInfoMutation.mutate(formData);
    }
  };
  const handleLogout = () => {
    // 로그인 상태일 시 - 로그아웃 로직
    if (isAuthenticated) {
      setModal('none');
      setIsAuthenticated(false);
      setIsAdminAuthenticated(false);
      setPersonalInfo(false);
      removeCookie('access_token', { path: '/' });
      removeCookie('refresh_token', { path: '/' });
      localStorage.removeItem('storageRegistration');
      localStorage.removeItem('hasPersonalInfo');
      localStorage.removeItem('bookingId');
      localStorage.removeItem('placeId');
      navigate('/main');
    }
  };
  if (isLoading) {
    return <Spinner />;
  }
  if (personalInfoError) {
    alert('information get error');
    return null;
  }
  if (postPersonalInfoMutation.isPending) {
    return <Spinner />;
  }
  return (
    <Container>
      {isEditOpen ? (
        <Wrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Title>
              <div style={{ fontSize: '1.25rem', fontFamily: 'DM Sans Bold' }}>Profile</div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CancelButton style={{ marginRight: 5 }} onClick={() => setIsEditOpen(false)}>
                  cancel
                </CancelButton>
                <EditButton type="submit" disabled={!isValid}>
                  save
                </EditButton>
              </div>
            </Title>

            <Content>
              <div>{t('myPage.name')}</div>
              <EditInput
                type="text"
                id="name"
                {...register('name', {
                  validate: validateName,
                })}
                style={{
                  borderColor: errors?.name?.message ? '#f74726' : name ? '#0094ff' : '#ffffff',
                }}
                placeholder={data?.data?.name && data.data.name}
              />
            </Content>
            <Content>
              <div>{t('myPage.email')}</div>
              <EditInput
                type="email"
                id="email"
                {...register('email', {
                  validate: validateEmail,
                })}
                style={{
                  borderColor: errors?.email?.message ? '#f74726' : email ? '#0094ff' : '#ffffff',
                }}
                placeholder={data?.data?.email && data.data.email}
              />
            </Content>
            <Content style={{ marginBottom: 50 }}>
              <div>{t('myPage.phone')}</div>

              <StyledPhoneInput
                country={'kr'}
                value={phone === '+82 1000000000' ? '' : phone}
                onChange={handleChange}
                inputProps={{
                  name: 'phoneNumber',
                  required: true,
                  autoFocus: true,
                }}
                enableSearch={true} // 드롭다운에서 국가 검색 활성화
                searchPlaceholder="Search" // 검색 입력 필드의 플레이스홀더
              ></StyledPhoneInput>
            </Content>
          </form>
        </Wrapper>
      ) : (
        <Wrapper>
          <Title>
            <div style={{ fontSize: '1.25rem', fontFamily: 'DM Sans Bold' }}>Profile</div>
            <EditButton onClick={() => setIsEditOpen(true)}>edit</EditButton>
          </Title>

          <Content>
            <div>{t('myPage.name')}</div>
            <div>{data?.data?.name && data.data.name}</div>
          </Content>
          <Content>
            <div>{t('myPage.email')}</div>
            <div>{data?.data?.email && data.data.email}</div>
          </Content>
          <Content style={{ marginBottom: 50 }}>
            <div>{t('myPage.phone')}</div>
            <div>
              {data?.data?.phoneNumber &&
                (data.data.phoneNumber === '+82 1000000000'
                  ? ''
                  : maskLastFourDigits(data.data.phoneNumber))}
            </div>
          </Content>
        </Wrapper>
      )}

      {!isEditOpen && (
        <Wrapper style={{ marginBottom: 70 }}>
          <Title style={{ fontSize: '1.25rem', fontFamily: 'DM Sans Bold' }}>Information</Title>
          <Content>
            <div>{t('myPage.customer_service')}</div>
            <ToggleImg
              onClick={() =>
                setInformationToggleOpen((prev) => ({
                  ...prev,
                  customer_service: !prev.customer_service,
                }))
              }
              src={informationToggleOpen.customer_service ? ToggleUp : ToggleDown}
            />
          </Content>
          {informationToggleOpen.customer_service && <CustomerServiceDocument />}
          <Content>
            <div>{t('myPage.faq')}</div>
            <ToggleImg
              onClick={() =>
                setInformationToggleOpen((prev) => ({
                  ...prev,
                  faq: !prev.faq,
                }))
              }
              src={informationToggleOpen.faq ? ToggleUp : ToggleDown}
            />
          </Content>
          {informationToggleOpen.faq && <FaqDocument />}
          <Content>
            <div>{t('myPage.terms_of_service')}</div>
            <ToggleImg
              onClick={() =>
                setInformationToggleOpen((prev) => ({
                  ...prev,
                  terms_of_service: !prev.terms_of_service,
                }))
              }
              src={informationToggleOpen.terms_of_service ? ToggleUp : ToggleDown}
            />
          </Content>
          {informationToggleOpen.terms_of_service && <TermsDocument />}
          <Content>
            <div>{t('myPage.privacy_policy')}</div>
            <ToggleImg
              onClick={() =>
                setInformationToggleOpen((prev) => ({
                  ...prev,
                  privacy_policy: !prev.privacy_policy,
                }))
              }
              src={informationToggleOpen.privacy_policy ? ToggleUp : ToggleDown}
            />
          </Content>
          {informationToggleOpen.privacy_policy && <PrivacyPolicyDocument />}
        </Wrapper>
      )}
      {isAuthenticated && !isEditOpen && <Logout onClick={handleLogout}>로그아웃</Logout>}
    </Container>
  );
};
const InformationDetail = styled.div`
  padding-left: 10px;
`;
const ToggleImg = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;
const CancelButton = styled.div`
  cursor: pointer;
  border-radius: 30px;
  border: 2px solid #675e5e;
  font-size: 0.75rem;
  padding: 5px 10px 8px 10px;
  line-height: 7px;
`;
const StyledPhoneInput = styled(PhoneInput)`
  width: 70%;
  border-bottom: 1px solid #675e5e;
  .form-control {
    width: 100%;
    all: unset;
    position: relative;
    font-size: 0.75rem;
    margin-bottom: 0 !important;
    margin-left: 0;
    outline: none;
    padding-left: 3.0625rem;
    padding-top: 0.8125rem;
    padding-bottom: 0.8125rem;
  }
  .flag-dropdown {
    /* width: 6.25rem; */
    border: none;
    /* border-right: 1px solid #1b303f; */
  }
  .selected-flag {
    width: 50px;
    display: flex;
    justify-content: space-between;
  }
  .react-tel-input .flag-dropdown .selected-flag .flag .arrow {
    left: 30px;
  }
`;
const EditInput = styled.input`
  all: unset;
  font-size: 0.9375rem;
  width: 70%;
  border-bottom: 1px solid #675e5e;
`;
const EditButton = styled.button`
  all: unset;
  cursor: pointer;
  border-radius: 30px;
  border: 2px solid #675e5e;
  font-size: 0.75rem;
  padding: 5px 10px 8px 10px;
  line-height: 7px;
`;
const Logout = styled.div`
  padding-bottom: 70px;
  color: #828282;
  font-size: 0.9375rem;
  text-decoration: underline;
  text-align: center;
  width: 100%;
  cursor: pointer;
`;
const Title = styled.div`
  padding: 0 0 10px 10px;
  border-bottom: 2px solid #1b303f;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 28px;
  font-size: 0.9375rem;
  font-family: 'DM Sans Medium';
  padding-left: 10px;
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
  padding: 40px 20px 0 20px;
  height: calc(100% - 140px);
  overflow: scroll;

  /* Chrome, Safari */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  scrollbar-width: none;

  /* Internet Explorer */
  -ms-overflow-style: none;
`;
export default MyPage;
