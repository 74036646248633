import styled from '@emotion/styled';
import NaverLogo from '../../assets/images/NaverLogo.png';
import LineLogo from '../../assets/images/LineLogo.png';
const Line = () => {
  const lineURL = `${process.env.REACT_APP_API_URL}/oauth2/authorization/line`;
  // const lineURL = 'https://dev-api.jimventory.com/oauth2/authorization/line';
  const handleLineLogin = () => {
    window.location.href = lineURL;
  };
  return (
    <Container onClick={handleLineLogin}>
      <img style={{ width: '3.375rem', height: 'auto' }} src={LineLogo} />
      <Text>Sign In with Line</Text>
    </Container>
  );
};
const Text = styled.div`
  font-size: 1.25rem;
  color: #ffffff;
  font-family: 'DM Sans Medium';
`;
const Container = styled.div`
  width: 100%;
  background-color: #06c755;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
`;
export default Line;
// 서버쪽에서 client_id와 redirect_url을 따로 설정하고,
// 클라이언트 쪼겡서 href를 사용하여 구글 등의 소셜로그인으로 보낸뒤 로그인 성공하면
// redirect_url로 이동하여 토큰을 담고 서버에 인증을 보낸다.
