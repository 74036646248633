import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const HostPage = () => {
  const navigate = useNavigate();

  // const { data, isError, error, isLoading } = useQuery({
  //   queryKey: ['place'],
  //   queryFn: async (): Promise<ApiResponseType<GetPlaceType>> => getPlacesByHostApi(),
  // });
  useEffect(() => {
    navigate('/host/manage-storage');
  }, []);
  // useEffect(() => {
  //   if (isLoading) return; // 데이터 로딩 중에는 아무 것도 하지 않음

  //   if (isError || !data?.data.placeId) {
  //     navigate('/host/register-storage?step=category'); // 데이터가 없거나 에러가 발생하면 등록 페이지로 이동
  //   }
  //   else if (data?.data.placeId) {
  //     navigate(`/host/manage-storage?placeId=${data.data.placeId}`); // 데이터가 있으면 관리 페이지로 이동
  //   }
  // }, [isLoading, isError, data]);

  // if (isLoading) {
  //   return <div>...is loading</div>;
  // }
  // if (isError) {
  //   console.error('/places/me error', error);
  //   return <div>Error occurred</div>;
  // }
  // 현재 가지고 있는 계정 중 google, line은 두 개 이상의 보관소가 등록되어
  // /places/me 조회 시 500에러가 난다.
  return <div></div>;
};

export default HostPage;
