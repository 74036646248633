import styled from '@emotion/styled';
import PhoneIcon from '../../assets/images/InfoCall.png';
import EmailIcon from '../../assets/images/Mail.png';
const CustomerServiceDocument = () => {
  return (
    <Container>
      <Flex style={{ marginBottom: 5 }}>
        <Image src={PhoneIcon} />
        <Text> +82 010-4018-1368</Text>
      </Flex>
      <Flex>
        <Image src={EmailIcon} />
        <Text> jimventory@gmail.com</Text>
      </Flex>
    </Container>
  );
};
const Text = styled.div`
  text-decoration: underline;
`;
const Image = styled.img`
  width: 1.0625rem;
  height: auto;
  margin-right: 7px;
`;
const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Container = styled.div`
  padding-left: 15px;
  margin-top: 10px;
`;
export default CustomerServiceDocument;
