import styled from '@emotion/styled';
interface ProgressBarType {
  section: number;
  page?: string;
}
const ProgressBar = ({ section, page }: ProgressBarType) => {
  const totalSteps = page === 'register' ? 9 : 3; // 'register' 페이지인 경우 9개의 스텝을 가짐

  return (
    <Container>
      {totalSteps === 9 &&
        Array.from({ length: totalSteps }, (_, index) => (
          <Progress
            key={index}
            className={`${section >= index ? 'available' : ''}
    ${section > index ? 'borderNone' : ''}`}
          />
        ))}
      {totalSteps === 3 &&
        Array.from({ length: totalSteps }, (_, index) => (
          <Progress
            key={index}
            className={`${section >= index + 1 ? 'available' : ''}
   ${section > index ? 'borderNone' : ''}`}
          />
        ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 7px;
  display: flex;
`;
const Progress = styled.div`
  background-color: #faead8;
  flex: 1;
  border-radius: 0 5px 5px 0;

  &.available {
    background-color: #fcb331;
  }

  &.borderNone {
    border-radius: 0px;
  }
`;
export default ProgressBar;
