import styled from '@emotion/styled';
import SearchIcon from '../../assets/images/SearchIcon.png';
import CurrentSearchIcon from '../../assets/images/CurrentSearchIcon.png';
import React from 'react';
import { useTranslation } from 'react-i18next';
interface SearchBarType {
  searchValue: string;
  setSearchValue: (value: string) => void; // Dispatch<SetStateAction<string>>
  onSearchLocation: () => void;
  handleCenterToCurrentLocation: () => void;
}

const SearchBar = ({
  searchValue,
  setSearchValue,
  onSearchLocation,
  handleCenterToCurrentLocation,
}: SearchBarType) => {
  const { t } = useTranslation();
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSearchLocation(); // 작성한 댓글 post 요청하는 함수
    }
  };

  return (
    <Container>
      <ButtonDiv>
        <SearchButton type="submit" onClick={onSearchLocation}></SearchButton>
      </ButtonDiv>

      <SearchInput
        value={searchValue}
        onKeyDown={handleKeyDown}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={t('search')}
      ></SearchInput>
      <ButtonDiv>
        <CurrentButton onClick={handleCenterToCurrentLocation}></CurrentButton>
      </ButtonDiv>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 55px;
  background-color: #f6f6f6;
  border-bottom: 2px solid #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin: 0 auto;
`;
const SearchInput = styled.input`
  all: unset;
  min-width: 260px;
  padding-left: 10px;

  ::placeholder {
    font-size: 15px;
    font-weight: 600;
  }
`;
const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
`;

const CurrentButton = styled.button`
  all: unset;
  width: 30px;
  height: 30px;
  background-image: url(${CurrentSearchIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
const SearchButton = styled.button`
  all: unset;
  width: 20px;
  height: 20px;
  background-image: url(${SearchIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
export default React.memo(SearchBar);
