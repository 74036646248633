import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';
import { reservationState } from '../../recoil/reservationRecoil';
import { useTranslation } from 'react-i18next';

interface BookingQuantityType {
  handleIncreaseBagCount: () => void;
  handleDecreaseBagCount: () => void;
  isOverCapacity: boolean;
  capacity: number | undefined;
}
const BookingQuantity = ({
  handleDecreaseBagCount,
  handleIncreaseBagCount,
  isOverCapacity,
  capacity,
}: BookingQuantityType) => {
  const { numOfBags } = useRecoilValue(reservationState);
  const { t } = useTranslation();
  return (
    <Container>
      <TextWrapper>
        <MainText>{t('bookingPage.bags_count')}</MainText>
        <SmallText>{t('bookingPage.suitcase_shopping_bag')}</SmallText>
      </TextWrapper>
      <CountWrapper>
        <div onClick={handleDecreaseBagCount} style={{ cursor: 'pointer' }}>
          -
        </div>
        <div>{capacity === 0 ? 0 : numOfBags}</div>
        <div onClick={handleIncreaseBagCount} style={{ cursor: 'pointer' }}>
          +
        </div>
      </CountWrapper>
      {isOverCapacity && (
        <div
          style={{
            color: '#F74726',
            marginTop: 70,
            fontSize: '0.875rem',
            fontFamily: 'DM Sans Medium',
          }}
        >
          {t('bookingPage.maximum_capacity', { capacity })}
        </div>
      )}
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1b303f;
`;
const MainText = styled.div`
  font-family: 'DM Sans Bold';
  font-size: 1.375rem;
`;

const SmallText = styled.div`
  font-family: 'DM Sans Regular';
  font-size: 1rem;
  color: #868686;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 28px;
  width: 100%;
  padding-top: 36px;
`;

const CountWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 72px;
  padding-top: 102px;
  font-size: 2.5rem;
  color: #061625;
  font-family: 'DM Sans Bold';
  justify-content: space-between;
`;
export default BookingQuantity;
