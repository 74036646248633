import { GetBusinessHourType } from '../types/storageType';

// 화면에 표시되는 오늘 날짜에 대한 영업시간 표시를 위해 인덱스 한 칸 조정하여 구하는 today string
// 배열에서 일치하는 오늘을 찾는 것
export const getTodayStringForShow = (businessHours: GetBusinessHourType[]) => {
  const days = businessHours.map((it) => it.dayOfWeek);
  const dayIndex = (new Date().getDay() + 6) % 7;
  const today = days[dayIndex];
  return today;
};
const closedDaysFilter = (businessHours: GetBusinessHourType[]) => {
  // 날짜 별 인덱스와 문자열 사용을 위한 객체
  const dayOfWeekMap: {
    [key: string]: number;
  } = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
  };

  const days = businessHours.map((it) => it.dayOfWeek); // ['MONDAY', 'TUESDAY', ...]

  // ex : MONDAY -> 선택된 날짜가 무슨 요일인지 문자열로 반환
  const getTodayStringForFilter = (time: Date) => {
    const day = time.getDay(); // 선택된 날짜 객체의 인덱스 ( getDay()는 인덱스를 반환)
    return Object.keys(dayOfWeekMap).find((key) => dayOfWeekMap[key] === day);
  };
  const getPrevDayStringForFilter = (time: Date) => {
    const day = (time.getDay() + 6) % 7; // 현재 요일에서 1을 빼고 7로 모듈로 연산하여 전날을 계산
    return Object.keys(dayOfWeekMap).find((key) => dayOfWeekMap[key] === day);
  };

  // ex : 다음날의 무슨 요일인지 문자열로 반환
  const getNextDayStringForFilter = (time: Date) => {
    const day = (time.getDay() + 1) % 7; // 현재 요일에서 1을 더하고 7로 모듈로 연산하여 다음날을 계산
    return Object.keys(dayOfWeekMap).find((key) => dayOfWeekMap[key] === day);
  };
  const openForPrevDayBusinessHour = (date: Date | null, businessHours: GetBusinessHourType[]) => {
    if (date) {
      const todayString = getTodayStringForFilter(date);
      const prevDayString = getPrevDayStringForFilter(date);
      const todayBusinessHour = businessHours.find((bh) => bh.dayOfWeek === todayString);
      const prevDayBusinessHour = businessHours.find((bh) => bh.dayOfWeek === prevDayString);
      if (
        todayBusinessHour?.closed &&
        !prevDayBusinessHour?.closed &&
        prevDayBusinessHour?.isSpanToNextDay
      ) {
        return true;
      } else return false;
    }
  };
  // 휴무일로 이루어진 배열
  const closedDays = businessHours.filter((day) => day.closed).map((day) => day.dayOfWeek); // ex : ['SATURDAY', 'SUNDAY']

  // 휴무일 인덱스
  const closedDaysNumbers = closedDays.map((day) => dayOfWeekMap[day as keyof typeof dayOfWeekMap]); // ex : [ '6', '0' ]

  // all day 24시간 영업 필터링
  const isOpen24Hours = (): boolean => {
    const allOpen24 = businessHours.every((hour) => hour.businessHour === '00:00 ~ 23:59');
    return allOpen24;
  };
  return {
    getTodayStringForFilter,
    getPrevDayStringForFilter,
    getNextDayStringForFilter,
    closedDays,
    closedDaysNumbers,
    isOpen24Hours,
    openForPrevDayBusinessHour,
  };
};
export default closedDaysFilter;
