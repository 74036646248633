import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import ArrowToDetail from '../../assets/images/ClickToDetail.png';
import { formatDateTime } from '../../utils/isoToString';
import { forwardRef } from 'react';
import { BookingDataType } from '../../types/BookingType';
interface TicketItemPropsType {
  reservation: BookingDataType;
}
const TicketItem = forwardRef<HTMLDivElement, TicketItemPropsType>(({ reservation }, ref) => {
  const { place, booking } = reservation;
  const navigate = useNavigate();
  const goToDetail = () => {
    navigate(`/ticket/${booking.bookingId}`);
  };
  const { t } = useTranslation();
  const { paymentAmount } = booking;
  return (
    <Container ref={ref}>
      <ReservationWrapper>
        <StoreWrapper>
          <StoreImage src={place.picture} />
          <div>
            <StoreName>{place.name}</StoreName>
            <StoreAddress>{place.addr}</StoreAddress>
          </div>
        </StoreWrapper>
        <div style={{ display: 'flex', paddingTop: 20 }}>
          <div style={{ marginRight: 24 }}>
            <GreyText>{t('dropOff')}</GreyText>
            <BlackText>{formatDateTime(booking.dropOffAt)}</BlackText>
          </div>
          <div>
            <GreyText>{t('bags')}</GreyText>
            <BlackText>{booking.numOfBags}</BlackText>
          </div>
        </div>
        <div style={{ display: 'flex', paddingTop: 20 }}>
          <div style={{ marginRight: 24 }}>
            <GreyText>{t('pickUp')}</GreyText>
            <BlackText>{formatDateTime(booking.pickUpAt)}</BlackText>
          </div>
          <div>
            <GreyText>{t('price')}</GreyText>
            <BlackText>
              {' '}
              <Trans i18nKey="paymentAmount">{{ paymentAmount }} KRW</Trans>
            </BlackText>
          </div>
        </div>
      </ReservationWrapper>
      <ArrowWrapper onClick={goToDetail}>
        <ArrowImage src={ArrowToDetail} />
      </ArrowWrapper>
    </Container>
  );
});

const ArrowWrapper = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const ArrowImage = styled.img`
  width: 3.125rem;
  height: auto;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  border-top: 2px solid #e2e2e2;
  border-bottom: 2px solid #e2e2e2;
`;

const ReservationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0 16px 16px;
  width: 100%;
`;

const StoreImage = styled.img`
  width: 3.75rem;
  height: 3.75rem;
  margin-right: 15px;
`;
const StoreAddress = styled.div`
  font-size: 0.625rem;
  font-family: 'DM Sans Regular';
  color: #b5aeae;
`;
const StoreWrapper = styled.div`
  display: flex;
`;

const StoreName = styled.div`
  font-size: 1.25rem;
  font-family: 'DM Sans Bold';
`;
const GreyText = styled.div`
  color: #675e5e;
  font-size: 0.625rem;
  font-family: 'DM Sans Regular';
`;
const BlackText = styled.div`
  color: #1b303f;
  font-size: 0.9375rem;
  font-family: 'DM Sans Regular';
  white-space: 0.5px;
`;

export default TicketItem;
