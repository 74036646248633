import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Container, NaverMap, Marker } from 'react-naver-maps';
import SearchBar from '../components/main/SearchBar';
import CurrentMarker from '../assets/images/CurrentMarker.png';
import MarkerOpen from '../assets/images/MarkerOpen.png';
import MarkerClosed from '../assets/images/MarkerClosed.png';
import StorageCard from '../components/main/StorageCard';
import apiClient from '../apis/utils/apiClient';
import { GetPlaceType } from '../types/storageType';
import { useRecoilState } from 'recoil';
import { authIntentState } from '../recoil/authIntentAtom';
import { GetPlacesParamsType, getPlacesApi } from '../apis/placeApi';
import { getTodayStringForShow } from '../utils/dateFilter';

interface LocationType {
  lat: number;
  lng: number;
}
interface ChangedCenterType {
  x: number;
  y: number;
  _lat: number;
  _lng: number;
}
const MainPage = () => {
  const [zoomLevel, setZoomLevel] = useState(16);
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentLocation, setCurrentLocation] = useState<LocationType | null>(null);
  const [centerLocation, setCenterLocation] = useState<LocationType>({
    lat: 37.5665,
    lng: 126.978,
  });
  const { lat, lng } = centerLocation;
  const [storageData, setStorageData] = useState<GetPlaceType[] | null>(null);
  const [selectedItem, setSelectedItem] = useState<GetPlaceType | null>(null);
  const centerChangeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  useEffect(() => {
    setAuthIntent({
      ...authIntent,
      intent: 'user',
      previousPath: '/main',
    });
  }, []);

  useEffect(() => {
    const getPlaces = async () => {
      const params: GetPlacesParamsType = {
        lat,
        lng,
        zoomLevel,
      };
      try {
        const response = await getPlacesApi(params);
        setStorageData(response.data);
      } catch (error) {
        console.error('places nearby error');
      }
    };
    getPlaces();
  }, [zoomLevel, lat, lng]);

  const handleCenterToCurrentLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const location: LocationType = { lat: latitude, lng: longitude };
          setCenterLocation(location);
          setCurrentLocation(location);
        },
        (error) => {
          console.error(error);
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  const onSearchLocation = useCallback(async () => {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      searchValue,
    )}&key=${apiKey}&language=ko`;

    try {
      const response = await axios.get(url);
      if (response.data.results.length > 0) {
        const { lat, lng } = response.data.results[0].geometry.location;
        setCenterLocation({ lat: lat, lng: lng });
      } else {
        alert('No results found');
      }
    } catch (error) {
      console.error(error);
    }
  }, [searchValue]);

  const handleCenterChanged = useCallback((center: ChangedCenterType) => {
    if (centerChangeTimeoutRef.current) {
      clearTimeout(centerChangeTimeoutRef.current);
    }
    centerChangeTimeoutRef.current = setTimeout(() => {
      setCenterLocation({ lat: center._lat, lng: center._lng });
    }, 500); // 500ms의 딜레이 후에 centerLocation을 업데이트. center가 섬세하게 바뀌므로 사용자가 이동 후 움직임을 멈췄을 때 업데이트
  }, []);

  const onClickStorageCard = async (placeId: number) => {
    try {
      const response = await apiClient.get(`/places/${placeId}`);
      setSelectedItem(response.data.data);
    } catch (error) {
      console.error('get detail storages error');
    }
  };

  return (
    <Container style={{ width: '100%', height: 'calc(100% - 70px)' }}>
      <SearchBar
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onSearchLocation={onSearchLocation}
        handleCenterToCurrentLocation={handleCenterToCurrentLocation}
      />
      <StorageCard selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
      <NaverMap
        onZoomChanged={(zoom) => {
          setZoomLevel(zoom);
        }}
        defaultZoom={16}
        center={centerLocation}
        maxZoom={20}
        onCenterChanged={handleCenterChanged}
      >
        {currentLocation && (
          <Marker
            position={currentLocation}
            icon={{
              url: CurrentMarker,
              anchor: { x: 12, y: 36 },
              scaledSize: { width: 26, height: 26 },
            }}
          />
        )}
        {storageData &&
          storageData.map((storage) => (
            <div key={storage.placeId}>
              <Marker
                position={{
                  lat: storage.lat,
                  lng: storage.lng,
                }}
                onClick={() => onClickStorageCard(storage.placeId)}
                icon={{
                  url: storage.enabled ? MarkerOpen : MarkerClosed,
                  anchor: { x: 12, y: 36 },
                  scaledSize: { width: 42, height: 42 },
                }}
              />
            </div>
          ))}
      </NaverMap>
    </Container>
  );
};

export default MainPage;
