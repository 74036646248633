import styled from '@emotion/styled';
import Landing from '../../assets/images/UserLanding.png';
import Map from '../../assets/images/Map.png';
import Ticket from '../../assets/images/Receipt.png';
import MyPage from '../../assets/images/User.png';
import LandingClicked from '../../assets/images/UserLandingClicked.png';
import MapClicked from '../../assets/images/MapClicked.png';
import TicketClicked from '../../assets/images/ReceiptClicked.png';
import MyPageClicked from '../../assets/images/UserClicked.png';
import { useLocation, useNavigate } from 'react-router-dom';

const MenuFooter = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const isTicketActive = /^\/ticket\/\d+$/.test(location.pathname); // ticket/:id 여서 'ticket'이라는 글자가 있는지

  const handleClickMenu = (menu: string = '') => {
    navigate('/' + menu);
  };

  return (
    <Container>
      <Box onClick={() => handleClickMenu()}>
        <IconContainer src={location.pathname === '/' ? LandingClicked : Landing} />
      </Box>
      <Box onClick={() => handleClickMenu('main')}>
        <IconContainer src={location.pathname === '/main' ? MapClicked : Map} />
      </Box>
      <Box onClick={() => handleClickMenu('tickets')}>
        <IconContainer
          src={location.pathname === '/tickets' || isTicketActive ? TicketClicked : Ticket}
        />
      </Box>
      <Box onClick={() => handleClickMenu('my-page')}>
        <IconContainer src={location.pathname === '/my-page' ? MyPageClicked : MyPage} />
      </Box>
    </Container>
  );
};
const Container = styled.div`
  border-top: 2px solid #000000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f6f6;
`;
const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0094ff;
`;
const IconContainer = styled.img`
  width: 30px;
  height: 30px;
`;

export default MenuFooter;
