import styled from '@emotion/styled';
import NextTrue from '../../assets/images/NextTrue.png';
import NextFalse from '../../assets/images/NextFalse.png';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ReservationStateType, basePriceState } from '../../recoil/reservationRecoil';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { authIntentState } from '../../recoil/authIntentAtom';
import { authenticatedState } from '../../recoil/authenticatedAtom';
import { modalState } from '../../recoil/modalAtom';

interface BookingFooterType {
  nextAvailable: string;
  reservationInfo: ReservationStateType;
  section: number;
  setSection: (value: number | ((prev: number) => number)) => void;
  id: string | undefined;
  isValidTime: boolean;
  checkInObj: Date | null;
  checkOutDate: string;
  isOverCapacity: boolean;
}

interface NextButtonType {
  isActivate: boolean;
}

const BookingFooter = ({
  reservationInfo,
  section,
  setSection,
  id,
  isValidTime,
  checkInObj,
  checkOutDate,
  isOverCapacity,
}: BookingFooterType) => {
  const setModal = useSetRecoilState(modalState);
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authenticatedState);
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  const { paymentAmount, numOfBags, period } = reservationInfo;
  const basePrice = useRecoilValue(basePriceState);
  const navigate = useNavigate();
  const goToNextSection = () => {
    if (section !== 0 && !isValidTime) {
      return;
    }
    if (section === 0) {
      // 로그인 되어있을 경우 isAuthenticated true
      if (isAuthenticated) {
        navigate(`/booking/${id}?step=date-selection`);
        setSection((prev) => prev + 1);
      } else {
        // 로그인을 해야할 경우
        setAuthIntent({
          ...authIntent,
          intent: 'booking',
          previousPath: `/booking/${id}?step=information`,
          intentPath: `/booking/${id}?step=date-selection`,
        });
        localStorage.setItem('placeId', id as string); // 로컬 스토리지에 id 저장
        // 로그인 후, 혹은 하지않고 bookingPage로 다시 돌아올 때 필요하기 때문에 저장한다.
        // navigate('/log-in');
        setModal('login');
      }
    } else if (section === 1) {
      navigate(`/booking/${id}?step=quantity-selection`);
      setSection((prev) => prev + 1);
    }
  };
  const handleClickConfirm = () => {
    if (!isOverCapacity) {
      setModal('modal');
    } else return;
  };
  return (
    <Container>
      {(section === 0 || section === 1) && (
        <SmallWrapper>
          <div>
            <Price>{basePrice} KRW</Price>
            <ItemWrapper>
              <div>PER ITEM /</div>
              <div>{period} HOURS</div>
            </ItemWrapper>
          </div>
          <NextButton
            onClick={goToNextSection}
            isActivate={section === 0 || isValidTime} // 섹션이 0이면 true로 냅둬라. 선택할 수 있게 해라.
          ></NextButton>
        </SmallWrapper>
      )}
      {section === 2 && (
        <BigWrapper>
          {/* won */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Price>{paymentAmount} KRW</Price>
              <ItemWrapper>
                <div>{numOfBags} ITEM /</div>
                <div>{period} HOURS</div>
              </ItemWrapper>
            </div>
            <div></div>
          </div>
          {/* date */}
          <div style={{ paddingTop: 15 }}>
            <DateWrapper style={{ paddingBottom: 5 }}>
              <DateText>Drop off</DateText>
              <DateDate>{checkInObj && format(checkInObj, 'yyyy-MM-dd HH:mm')}</DateDate>
            </DateWrapper>
            <DateWrapper>
              <DateText>Pick up</DateText>
              <DateDate>{checkOutDate}</DateDate>
            </DateWrapper>
          </div>
          <div
            onClick={handleClickConfirm}
            style={{
              alignSelf: 'flex-end',
              paddingTop: 20,
              fontSize: '1.875rem',
              fontFamily: 'DM Sans Bold',
              cursor: 'pointer',
            }}
          >
            CONFIRM
          </div>
        </BigWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  /* height: 70px; */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media only screen and (max-width: 600px) {
    position: fixed;
  }

  background-color: #1b303f;
  /* width: 100%;
  height: 100%; */
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  color: #ffffff;
  padding: 10px 14px;
`;

const SmallWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
`;

const BigWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Price = styled.div`
  font-size: 1.125rem;
  font-family: 'DM Sans Medium';
`;
const ItemWrapper = styled.div`
  font-size: 0.6875rem;
  font-family: 'DM Sans Regular';
  display: flex;
`;
const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DateText = styled.div`
  font-family: 'DM Sans Medium';
  font-size: 0.875rem;
`;
const DateDate = styled.div`
  font-family: 'DM Sans Bold';
  font-size: 1.125rem;
`;
const NextButton = styled.div<NextButtonType>`
  background-image: url(${(props) => (props.isActivate ? NextTrue : NextFalse)});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 3.125rem;
  height: 3.125rem;
`;

export default BookingFooter;
