import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const createAuthApiClient = () => {
  let retry = false;
  const authApiBase = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // baseURL: 'https://dev-api.jimventory.com',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  // 요청 인터셉터 추가
  authApiBase.interceptors.request.use(
    (config) => {
      const token = Cookies.get('access_token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  // 응답 인터셉터 추가
  authApiBase.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalConfig = error.config;
      const status = error.response?.status;
      const msg = error.response?.data?.msg;
      if (status === 401 && !retry) {
        // originalConfig._retry = true;

        retry = true;
        // const navigate = useNavigate();
        //여기까지는 잘 들어감. retry에 대한 재시도 없음. 문제 없음.
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/reissue`, {
            headers: {
              Authorization: `Bearer ${Cookies.get('refresh_token')}`,
            },
          });
          console.log('is /auth/reissue response', res);
          Cookies.set('access_token', res.data.data.accessToken, {
            path: '/',
            secure: true,
            sameSite: 'strict',
          });

          Cookies.set('refresh_token', res.data.data.refreshToken, {
            path: '/',
            secure: true,
            sameSite: 'strict',
          });

          originalConfig.headers['Authorization'] = `Bearer ${res.data.accessToken}`;
          console.log('Retrying original request with new token', originalConfig);
          return await authApiBase(originalConfig);
        } catch (refreshError) {
          if ((refreshError as AxiosError)?.response?.status === 401) {
            Cookies.remove('access_token', { path: '/' });
            Cookies.remove('refresh_token', { path: '/' });
            alert('Your session has expired. Please log in again.');
            window.location.href = `/log-in?cause=session_expired`;
          }

          // else {
          //   const errorMessage = (refreshError as AxiosError)?.response?.data &&
          // }
          return Promise.reject(refreshError);
        }
      } else if (status !== 401) {
        console.error('Error message:', error);
      }

      return Promise.reject(error);
    },
  );

  return authApiBase;
};

const authApiClient = createAuthApiClient();

export default authApiClient;
