import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locale/trans.en.json';
import translationKO from '../locale/trans.ko.json';
import translationJA from '../locale/trans.ja.json';
import translationZH from '../locale/trans.zh.json';
import LanguageDetector from 'i18next-browser-languagedetector';
const resources = {
  en: {
    translation: translationEN, // json 파일
  },
  ko: {
    translation: translationKO,
  },
  ja: {
    translation: translationJA,
  },
  zh: {
    translation: translationZH,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('language') || 'en', // 기본 설정 언어, 'cimode'로 설정할 경우 키 값으로 출력된다.
    fallbackLng: 'en', // 번역 파일에서 찾을 수 없는 경우 기본 언어
    interpolation: {
      // 스크립트 그대로 출력 - 태그 등
      escapeValue: false, // React에서는 XSS 방지를 위해 escape 처리하지 않음
    },
  });

export default i18n;
