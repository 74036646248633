export const roundedUpTime = (time: Date): number => {
  if (time.getMinutes() % 10 !== 0) {
    return Math.floor(time.getMinutes() / 10) * 10 + 10;
  }
  return time.getMinutes();
};

export const defaultCheckInDate = new Date(new Date().setMinutes(roundedUpTime(new Date())));
// export const roundedUpTime = (time: Date): Date => {
//   const currentMinutes = time.getMinutes();
//   const remainder = 10 - (currentMinutes % 10);
//   const newMinutes = currentMinutes + remainder;
//   const newTime = new Date(time);

//   if (newMinutes >= 60) {
//     newTime.setHours(newTime.getHours() + 1);
//     newTime.setMinutes(0);
//   } else {
//     newTime.setMinutes(newMinutes);
//   }

//   return newTime;
// };

// export const defaultCheckInDate = roundedUpTime(new Date());
