import styled from '@emotion/styled';
import ProgressBar from '../common/ProgressBar';
import { useNavigate } from 'react-router-dom';
import { useRegisterNavigation } from '../../hooks/useRegisterNavigation';
import { RegisterPlaceType } from '../../types/storageType';
import { useRecoilState, useRecoilValue } from 'recoil';
import { registrationValidState, storageRegistrationState } from '../../recoil/storageRecoil';
import { modalState } from '../../recoil/modalAtom';

interface RegisterFooterType {
  isNextEnabled: boolean;
  registerSection: number;
  setRegisterSection: (value: number | ((prev: number) => number)) => void;
  validateForms: () => Promise<void>;
  isMutating: boolean;
}
const RegisterFooter = ({
  isMutating,
  isNextEnabled,
  registerSection,
  setRegisterSection,
  validateForms,
}: RegisterFooterType) => {
  const [modal, setModal] = useRecoilState(modalState);
  const { navigateToStep, steps } = useRegisterNavigation(setRegisterSection);
  const storageRegistration = useRecoilValue(storageRegistrationState);
  const { pictures } = storageRegistration;
  const navigate = useNavigate();
  const nextRegisterSection = async () => {
    if (isNextEnabled) {
      if (registerSection < 8) {
        navigateToStep(registerSection + 1);
      } else if (registerSection === 8) {
        if (isMutating) {
          return;
        } else {
          setModal('modal');
        }
      }
    }
  };
  const prevRegisterSection = () => {
    if (registerSection === 0) navigate('/host/manage-storage');
    if (registerSection >= 1) navigateToStep(registerSection - 1);
  };

  return (
    <Container>
      <ProgressBar page="register" section={registerSection} />
      <TextWrapper>
        <Before onClick={prevRegisterSection}>이전</Before>
        <After
          // type="submit"
          className={isNextEnabled ? 'enabled' : 'disabled'}
          onClick={nextRegisterSection}
        >
          {registerSection === 8 ? '등록하기' : '다음'}
        </After>
      </TextWrapper>
    </Container>
  );
};
const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px 12px 40px;
  font-size: 1.375rem;
  font-family: 'DM Sans Bold';
  background-color: #f6f6f6;
  cursor: pointer;
`;
const Before = styled.div`
  text-decoration: underline;
`;

const After = styled.button`
  all: unset;
  &.enabled {
    color: #ffffff;
    background-color: #0094ff;
  }
  &.disabled {
    color: #b9adad;
    background-color: #d9d9d9;
  }
  padding: 8px 17px;

  border-radius: 16px;
`;
const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media only screen and (max-width: 600px) {
    position: fixed;
  }

  /* height: 100%; */
`;
export default RegisterFooter;
