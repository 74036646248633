import { forwardRef, useEffect, useState } from 'react';
import {
  ContentContainer,
  ContentTitle,
  DayText,
  ErrorMessage,
  FlexBox,
  SectionContainer,
  SectionInput,
  SectionTitle,
  ToggleContainer,
  ToggleFlexBox,
} from '../../assets/css/RegisterCommonStyles';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { RegisterPlaceType } from '../../types/storageType';
import { registrationValidState, storageRegistrationState } from '../../recoil/storageRecoil';
import { useFormContext } from 'react-hook-form';
import ToggleButton from '../common/ToggleButton';
import styled from '@emotion/styled';
import ReactDatePicker from 'react-datepicker';
import TimeDropDown from '../../assets/images/TimeDropDown.png';
import { format } from 'date-fns';
import { EachDayType, businessHoursState } from '../../recoil/businessHourAtom';

const getDefaultTime = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  const initialTime = new Date();
  initialTime.setHours(hours, minutes, 0, 0); // 시, 분, 초, 밀리초 설정
  return initialTime;
};

const days = [
  {
    name: '월요일',
    key: 'MONDAY',
  },
  {
    name: '화요일',
    key: 'TUESDAY',
  },
  {
    name: '수요일',
    key: 'WEDNESDAY',
  },
  {
    name: '목요일',
    key: 'THURSDAY',
  },
  {
    name: '금요일',
    key: 'FRIDAY',
  },
  {
    name: '토요일',
    key: 'SATURDAY',
  },
  {
    name: '일요일',
    key: 'SUNDAY',
  },
];
const RegisterContact = () => {
  const {
    register,
    watch,
    formState: { isValid, errors },
  } = useFormContext();
  const contact = watch('contact');
  const [storageRegistration, setStorageRegistration] =
    useRecoilState<RegisterPlaceType>(storageRegistrationState);
  const { businessHours } = storageRegistration;
  const setIsStepValid = useSetRecoilState(registrationValidState);
  const [isNextDayChecked, setIsNextDayChecked] = useState<boolean>(false);
  const [isDayOn, setIsDayOn] = useRecoilState(businessHoursState);
  const { isAllDayOn, isEachDayOn } = isDayOn;
  const [initialSetOpenTime, setInitialSetOpenTime] = useState<boolean>(false);
  const [initialSetCloseTime, setInitialSetCloseTime] = useState<boolean>(false);

  // start, end time storageRegistration에서 긁어오기. => useState로 인해 초기화 되지 않도록
  // string을 Date객체로 바꾸어 저장해야 함.
  // const [startTime, setStartTime] = useState<Record<string, Date>>(
  //   businessHours.reduce((acc, day) => {
  //     if (!day.closed && day.openAt) {
  //       acc[day.dayOfWeek] = getDefaultTime(day.openAt) || getDefaultTime('10:00');
  //     } else {
  //       acc[day.dayOfWeek] = getDefaultTime('10:00');
  //     }
  //     return acc;
  //   }, {} as Record<string, Date>) || {},
  // );
  const [startTime, setStartTime] = useState<Record<string, Date>>(
    businessHours.reduce((acc, day) => {
      acc[day.dayOfWeek] = day.openAt ? getDefaultTime(day.openAt) : getDefaultTime('10:00');
      return acc;
    }, {} as Record<string, Date>),
  );

  const [endTime, setEndTime] = useState<Record<string, Date>>(
    businessHours.reduce((acc, day) => {
      acc[day.dayOfWeek] = day.closeAt ? getDefaultTime(day.closeAt) : getDefaultTime('22:00');
      return acc;
    }, {} as Record<string, Date>),
  );
  const handleChangeOpenTime = (date: Date, dayKey: string) => {
    if (date) {
      if (!initialSetOpenTime && dayKey === 'MONDAY') {
        const newStartTime = businessHours.reduce((acc, day) => {
          acc[day.dayOfWeek] = date;
          return acc;
        }, {} as Record<string, Date>);
        setStartTime(newStartTime);
        setInitialSetOpenTime(true);
      } else {
        setStartTime((prev) => ({
          ...prev,
          [dayKey]: date,
        }));
        setInitialSetOpenTime(true);
      }
    }
  };
  const handleChangeCloseTime = (date: Date, dayKey: string) => {
    if (date) {
      if (!initialSetCloseTime && dayKey === 'MONDAY') {
        const newStartTime = businessHours.reduce((acc, day) => {
          acc[day.dayOfWeek] = date;
          return acc;
        }, {} as Record<string, Date>);
        setEndTime(newStartTime);
        setInitialSetCloseTime(true);
      } else {
        setEndTime((prev) => ({
          ...prev,
          [dayKey]: date,
        }));
        setInitialSetCloseTime(true);
      }
    }
  };
  // 하나라도 익일영업 체크했다면
  const isAnyDaySpanToNextDay = () => {
    return storageRegistration.businessHours.some((day) => day.isSpanToNextDay);
  };

  const toggleHandler = (
    setFunc: React.Dispatch<
      React.SetStateAction<{ isAllDayOn: boolean; isEachDayOn: EachDayType }>
    >,
    value: boolean | keyof EachDayType,
  ) => {
    setFunc((prev) => {
      if (typeof value === 'boolean') {
        // isAllDayOn이 true가 되면 isEachDayOn을 모두 false로 초기화 시키는데, 이게 필요할까?
        const newIsEachDayOn = Object.keys(prev.isEachDayOn).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {} as EachDayType);

        return { ...prev, isAllDayOn: !prev.isAllDayOn, isEachDayOn: newIsEachDayOn };
      } else if (typeof value === 'string') {
        // 현재 요일을 오픈할 때 모든 요일의 isSpanToNextDay 체크
        const newIsEachDayOn = { ...prev.isEachDayOn, [value]: !prev.isEachDayOn[value] };

        return { ...prev, isEachDayOn: newIsEachDayOn };
      }
      return prev;
    });
  };

  // 업체 연락처 유효성 검사
  const validateContact = (value: string) => {
    if (!value) return '업체 연락처를 입력해주세요';
    if (!/^(\d{2,3})-?(\d{3,4})-?(\d{4})$/.test(value)) return '올바른 전화번호 형식이 아닙니다.';
    return true;
  };
  // datepicker input창 커스텀
  const CustomInput = forwardRef((props, ref: React.ForwardedRef<HTMLInputElement>) => {
    return (
      <div>
        <Input {...props} ref={ref} type="text" inputMode="none" />
        <Polygon src={TimeDropDown} />
      </div>
    );
  });

  useEffect(() => {
    if (isValid) {
      setStorageRegistration({
        ...storageRegistration,
        contact: contact,
      });
    }
  }, [isValid, contact]);
  useEffect(() => {
    // 24시간 영업 || 각 eachDay 중 하나라도 체크 시 다음 버튼 활성화
    // 연락처 입력 여부 포함
    const isAnyDayOn = Object.values(isEachDayOn).some((isOn) => isOn);
    const isBusinessHoursValid = isAllDayOn || isAnyDayOn;

    if (isValid && isBusinessHoursValid) {
      setIsStepValid((prev) => ({
        ...prev,
        contact: true,
      }));
    } else {
      setIsStepValid((prev) => ({
        ...prev,
        contact: false,
      }));
    }
  }, [isValid, isEachDayOn, isAllDayOn]);
  useEffect(() => {
    // 24시간 영업의 경우 00:00 - 00:00
    if (isAllDayOn) {
      const allDayBusinessHours = days.map((day) => ({
        dayOfWeek: day.key,
        closed: false,
        openAt: '00:00',
        closeAt: '23:59',
        isSpanToNextDay: false,
      }));
      setStorageRegistration({
        ...storageRegistration,
        businessHours: allDayBusinessHours,
      });
    }
    // 개별 영업일 경우 각각 closed여부와(isEachDayOn의 각 요일별 true/false)
    // startTime - openAt . endTime - closeAt 연결짓기
    if (!isAllDayOn) {
      const eachDayBusinessHours = storageRegistration.businessHours.map((day) => ({
        dayOfWeek: day.dayOfWeek,
        closed: !isEachDayOn[day.dayOfWeek],
        openAt: isEachDayOn[day.dayOfWeek] ? format(startTime[day.dayOfWeek], 'HH:mm') : '',
        closeAt: isEachDayOn[day.dayOfWeek]
          ? format(endTime[day.dayOfWeek], 'HH:mm') === '00:00'
            ? '23:59'
            : format(endTime[day.dayOfWeek], 'HH:mm')
          : '',
        isSpanToNextDay:
          format(endTime[day.dayOfWeek], 'HH:mm') === '00:00' ||
          format(endTime[day.dayOfWeek], 'HH:mm') === '23:59'
            ? false
            : day.isSpanToNextDay,
      }));
      setStorageRegistration({
        ...storageRegistration,
        businessHours: eachDayBusinessHours || storageRegistration.businessHours,
      });
    }
  }, [isAllDayOn, isEachDayOn, startTime, endTime]);

  const handleNextDayCheck = (dayKey: string) => {
    if (!isAnyDaySpanToNextDay()) {
      const updatedBusinessHours = storageRegistration.businessHours.map((day) => {
        return { ...day, isSpanToNextDay: true };
      });
      setStorageRegistration({
        ...storageRegistration,
        businessHours: updatedBusinessHours,
      });
    } else {
      const updatedBusinessHours = storageRegistration.businessHours.map((day) => {
        if (day.dayOfWeek === dayKey) {
          return { ...day, isSpanToNextDay: !day.isSpanToNextDay };
        }
        return day;
      });
      setStorageRegistration({
        ...storageRegistration,
        businessHours: updatedBusinessHours,
      });
    }
  };
  useEffect(() => {
    console.log(format(startTime['MONDAY'], 'HH:mm'));
  }, [startTime, endTime]);
  return (
    <SectionContainer>
      <SectionTitle>업체에 대한 정보를 알려주세요</SectionTitle>
      <ContentContainer>
        <FlexBox>
          <ContentTitle htmlFor="contact">업체 연락처</ContentTitle>
          {errors.contact?.message && (
            <ErrorMessage role="alert">{errors?.contact?.message.toString()}</ErrorMessage>
          )}
        </FlexBox>

        <SectionInput
          placeholder="업체 연락처"
          id="contact"
          {...register('contact', {
            validate: validateContact,
          })}
          style={{
            borderColor: errors?.contact?.message ? '#f74726' : contact ? '#0094ff' : '#ffffff',
          }}
        />

        <ContentTitle>영업 시간</ContentTitle>
        <ToggleFlexBox style={{ padding: '15px 27px' }}>
          <DayText>24시간 영업</DayText>
          <ToggleButton
            toggleHandler={() => toggleHandler(setIsDayOn, isAllDayOn)}
            isOn={isAllDayOn}
          ></ToggleButton>
        </ToggleFlexBox>
        {!isAllDayOn && (
          <DayWrapper>
            <GuideMessage>* 24시 영업은 00:00 - 23:59로 입력해 주세요.</GuideMessage>
            <GuideMessage style={{ marginTop: 5 }}>
              * 마감시간이 다음날로 넘어가는 새벽이라면 익일영업란에 체크해주세요!
            </GuideMessage>
            {days.map((day, index) => (
              <div key={index}>
                <ToggleFlexBox>
                  <DayText>{day.name}</DayText>
                  {isEachDayOn[day.key] && (
                    <ToggleWrapper>
                      <ToggleText>익일영업</ToggleText>
                      <input
                        onChange={() => handleNextDayCheck(day.key)}
                        checked={
                          businessHours.find((b) => b.dayOfWeek === day.key)?.isSpanToNextDay
                        }
                        type="checkbox"
                      />
                    </ToggleWrapper>
                  )}
                  <ToggleWrapper>
                    <ToggleText>open</ToggleText>
                    <ToggleButton
                      toggleHandler={() => toggleHandler(setIsDayOn, day.key)}
                      isOn={isEachDayOn[day.key]}
                    ></ToggleButton>
                  </ToggleWrapper>
                </ToggleFlexBox>
                {isEachDayOn[day.key] && (
                  <BusinessHourWrapper>
                    <div>
                      <TimeText>오픈 시간</TimeText>
                      <ReactDatePicker
                        selected={isAllDayOn ? getDefaultTime('10:00') : startTime[day.key]}
                        // onChange={(date) => {
                        //   if (date) {
                        //     setStartTime((prev) => ({
                        //       ...prev,
                        //       [day.key]: date,
                        //     }));
                        //   }
                        // }}
                        onChange={(date) => {
                          if (date) {
                            handleChangeOpenTime(date, day.key);
                          }
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat="HH:mm" //시간 포맷
                        timeIntervals={1} // 1분 단위로 선택 가능한 box가 나옴
                        dateFormat="HH:mm" // 입력 필드에 시간만 표시하도록 설정
                        customInput={<CustomInput />}
                      />
                    </div>
                    <div>
                      <TimeText>마감 시간</TimeText>
                      <ReactDatePicker
                        selected={isAllDayOn ? getDefaultTime('22:00') : endTime[day.key]}
                        // onChange={(date) => {
                        //   if (date) {
                        //     setEndTime((prev) => ({
                        //       ...prev,
                        //       [day.key]: date,
                        //     }));
                        //   }
                        // }}
                        onChange={(date) => {
                          if (date) {
                            handleChangeCloseTime(date, day.key);
                          }
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat="HH:mm" //시간 포맷
                        timeIntervals={1} // 1분 단위로 선택 가능한 box가 나옴
                        dateFormat="HH:mm" // 입력 필드에 시간만 표시하도록 설정
                        customInput={<CustomInput />}
                      />
                    </div>
                  </BusinessHourWrapper>
                )}
              </div>
            ))}
          </DayWrapper>
        )}
      </ContentContainer>
    </SectionContainer>
  );
};
const ToggleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ToggleText = styled.span`
  margin-right: 5px;
  font-size: 0.75rem;
  color: #bdb5b5;
  font-family: 'DM Sans Regular';
`;
const GuideMessage = styled.div`
  color: #0094ff;
  font-size: 0.75rem;
  padding: 0 25px;
  margin-top: 15px;
`;
const Polygon = styled.img`
  position: absolute;
  right: 10px;
  top: 14px;
  z-index: -2;
  cursor: pointer;
  width: 0.875rem;
  height: auto;
`;
const DayWrapper = styled.div`
  width: 100%;
  border: 2px solid #ffffff;
  border-radius: 10px;
  /* -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-sizing: border-box;
  -o-sizing: border-box;
  box-sizing: border-box; */
`;
const BusinessHourWrapper = styled.div`
  display: flex;
  padding: 0 25px;
  justify-content: space-between;
`;

const Input = styled.input`
  position: relative;
  all: unset;
  border: 1px solid black;
  width: 5.5rem;
  font-size: 0.625rem;
  padding: 10px;
  cursor: pointer;
`;
const TimeText = styled.div`
  color: #838181;
  font-size: 0.75rem;
  font-family: 'DM Sans Bold';
  margin-bottom: 2px;
`;
export default RegisterContact;
