import Slider from 'react-slick';
import './StartPage.css';
import 'slick-carousel/slick/slick.css';
import styled from '@emotion/styled';
import LandingSecondBackground from '../assets/images/LandingSecondBackground.png';
import { useRecoilState } from 'recoil';
import { authIntentState } from '../recoil/authIntentAtom';
import { useEffect, useState } from 'react';
import LandingBag from '../assets/images/LandingBag.png';
import LandingPhone from '../assets/images/LandingPhone.png';
import { useTranslation } from 'react-i18next';
import i18n from '../locale/i18n';
import LugezLogo from '../assets/images/LugezLogo.png';
import LugezBag from '../assets/images/LugezBag.png';

const languages: string[][] = [
  ['en', 'English'],
  ['ko', '한국어'],
  ['ja', '日本語'],
  ['zh', '中国人'],
];
const StartPage = () => {
  const [isTranslateOpen, setIsTranslateOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  useEffect(() => {
    setAuthIntent({
      ...authIntent,
      intent: 'user',
    });
  }, []);
  const handleClickTranslate = () => {
    setIsTranslateOpen(!isTranslateOpen);
  };
  const handleLanguageSelect = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
    window.location.reload(); // 페이지를 리로드하여 언어 변경을 적용
  };
  const getLanguageFromLocal = () => {
    if (localStorage.getItem('language') === 'en') {
      return 'English';
    } else if (localStorage.getItem('language') === 'ko') {
      return '한국어';
    } else if (localStorage.getItem('language') === 'zh') {
      return '中国人';
    } else if (localStorage.getItem('language') === 'ja') {
      return '日本語';
    } else {
      return 'English';
    }
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // 옆으로 이동하는 화살표 표시 여부
    // autoplay: true, // 자동 스크롤 사용 여부
    // autoplaySpeed: 10000, // 자동 스크롤 시 다음으로 넘어가는데 걸리는 시간 (ms)
    pauseOnHover: true, // 슬라이드 이동	시 마우스 호버하면 슬라이더 멈추게 설정
    draggable: true, //드래그 가능 여부
  };

  const languageSection = (section: number) => {
    return (
      <LanguageBox
        style={{ top: section === 2 || section === 3 || section === 4 ? '17px' : 0 }}
        onClick={handleClickTranslate}
      >
        <div
          style={{
            display: 'flex',
            color: '#1B303F',
            margin: '0 10px',
            borderBottom: isTranslateOpen ? '2px solid #d9d9d9' : 'none',
          }}
        >
          <Language style={{ marginBottom: isTranslateOpen ? '5px' : 0 }}>language</Language>
          {/* <div>{getLanguageFromLocal()}</div> */}
          {/* <div>
          <img src={TranslateLogo} />
        </div> */}
        </div>
        {isTranslateOpen &&
          languages.map((language, index) => (
            <Language
              style={{ display: 'flex', justifyContent: 'space-between' }}
              key={index}
              onClick={() => handleLanguageSelect(language[0])}
            >
              {/* <div>{language[0] === localStorage.getItem('language') && 'v'}</div> */}
              <div>{language[1]}</div>
              <div></div>
            </Language>
          ))}
      </LanguageBox>
    );
  };
  return (
    <Container className="slide-container">
      <Slider {...settings}>
        <section>
          <div className="slick-section-div">
            {languageSection(1)}
            {/* <OpenImage src={OpenYourJim} /> */}
            <div></div>
            <FirstLogo src={LugezLogo} />
            <TextContainer>
              <Text>{t('startPage.description.easiest_way')}</Text>
              <Text>{t('startPage.description.to_store_items')}</Text>
            </TextContainer>
          </div>
        </section>
        <section>
          <div className="slick-section-div">
            {languageSection(2)}
            {/* <SecondOpenImage src={OpenYourJimGrey} /> */}
            <img style={{ width: '100%', height: 'auto' }} src={LandingSecondBackground} />
            <GText>
              <div>{t('startPage.find.find_luggage_storage')}</div>
              <div>{t('startPage.find.location_near_you')}</div>
            </GText>
          </div>
        </section>
        <section>
          <div className="slick-section-div">
            {languageSection(3)}
            {/* <OpenImage style={{ marginBottom: 17 }} src={OpenYourJimGrey} /> */}
            <div style={{ marginBottom: 17, height: 56, marginTop: 50 }}></div>
            <img style={{ width: '100%', height: 'auto', marginBottom: 17 }} src={LandingPhone} />
            <Text>{t('startPage.book.book_jimventory')}</Text>
            <Text>{t('startPage.book.quickly_and_easily')}</Text>
          </div>
        </section>
        <section>
          <div className="slick-section-div">
            {languageSection(4)}
            {/* <OpenImage style={{ marginBottom: 15 }} src={OpenYourJimGrey} /> */}
            <div style={{ marginBottom: 15, height: 56, marginTop: 50 }}></div>
            {/* <img style={{ width: '100%', height: 'auto', marginBottom: 7 }} src={LandingBag} /> */}
            <img
              style={{ width: '60%', height: 'auto', marginBottom: 7, marginLeft: '15%' }}
              src={LugezBag}
            />
            <Text>{t('startPage.drop_off.drop_off_your')}</Text>
            <Text>{t('startPage.drop_off.items_securely')}</Text>
          </div>
        </section>
        <section>
          <div className="slick-section-div">
            {languageSection(5)}
            {/* <OpenImage style={{ marginBottom: 30 }} src={OpenYourJim} />
            <img style={{ width: '100%', height: 'auto', marginBottom: 17 }} src={LandingFinal} /> */}
            <div></div>
            <FirstLogo src={LugezLogo} />
            <TextContainer>
              <Text>{t('startPage.enjoy.enjoy_your_adventure')}</Text>
              <Text>{t('startPage.enjoy.hassle_free')}</Text>
            </TextContainer>
          </div>
        </section>
      </Slider>
    </Container>
  );
};
// const Section = styled.section`
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;
const GText = styled.div`
  position: absolute;
  bottom: 0;
  /* padding-top: 20px; */
  /* margin-bottom: 30px; */
  font-family: 'DM Sans Bold';
  font-size: 24px;
  background-image: linear-gradient(
    rgba(246, 246, 246, 0.2),
    rgba(246, 246, 246, 0.7),
    rgba(246, 246, 246, 1)
  );
  width: 100%;
  /* height: 70px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const LanguageBox = styled.div`
  background-color: #f6f6f6;
  position: absolute;
  top: 0;
  right: 14px;
  border: 2px solid #1b303f;
  border-radius: 30px;
  padding: 2px 7px 6px 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Language = styled.div`
  font-size: 1rem;
  color: #1b303f;
  margin-bottom: 5px;
`;
const LanguageButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 20px;
  font-family: 'DM Sans Bold';
  font-size: 1.25rem;
  cursor: pointer;
  margin: 0 10px;
`;
const Container = styled.div`
  /* safe-area 적용. layout내의 컨테이너는 content-box이므로 각각 적용해야함 */
  /* padding-top: 10px; */
  width: 100%;
  height: 100%;
  overflow: hidden;

  color: #1b303f;
`;

const OpenImage = styled.img`
  width: 237px;
  height: 56px;
  margin-top: 50px;
  margin-bottom: 30px;
`;
const SecondOpenImage = styled.img`
  position: absolute;
  top: 50px;
  width: 237px;
  height: 56px;
`;
const FirstLogo = styled.img`
  width: auto;
  height: 5rem;
  margin-top: 50px;
  /* margin-bottom: 20px; */
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;
const Text = styled.div`
  font-family: 'DM Sans Bold';

  font-size: 24px;
`;
export default StartPage;
