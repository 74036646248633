// pages.js
import StartPage from '../src/pages/StartPage';
import MainPage from '../src/pages/MainPage';
import TicketPage from './pages/booking/TicketPage';
import MyPage from '../src/pages/MyPage';
import BookingPage from './pages/booking/BookingPage';
import LogInPage from '../src/pages/login/LogInPage';
import SignUpPage from '../src/pages/login/SignUpPage';
import TicketDetailPage from './pages/booking/TicketDetailPage';
import RegisterStoragePage from '../src/pages/host/RegisterStoragePage';
import ManageStoragePage from '../src/pages/host/ManageStoragePage';
import HostPage from '../src/pages/host/HostPage';
import AdminPage from './admin/pages/AdminPage';
import AdminPlacesPage from './admin/pages/AdminPlacesPage';
import GoogleCallback from './components/socialLogin/GoogleCallback';
import RegisterSuccessPage from './pages/host/RegisterSuccessPage';
import KakaoCallback from './components/socialLogin/KakaoCallback';
import NaverCallback from './components/socialLogin/LineCallback';
import LineCallback from './components/socialLogin/LineCallback';
import LandingPage from './landing/LandingPage';
import PersonalInfoPage from './pages/login/PersonalInfoPage';
import BookingSuccessPage from './pages/booking/BookingSuccessPage';
import TicketByPlacePage from './pages/booking/TicketByPlacePage';

export const pages = [
  { path: '/', component: StartPage, header: 'start', footer: 'start' },
  { path: '/main', component: MainPage, header: 'menu', footer: 'menu' },
  // api : bookings/me
  { path: '/tickets', component: TicketPage, header: 'menu', footer: 'menu' },
  // api : bookings/me + placeId
  { path: '/tickets/:id', component: TicketByPlacePage, header: 'menu', footer: 'menu' }, // id : placeId
  // api : booking/bookingId
  { path: '/ticket/:id', component: TicketDetailPage, header: 'menu', footer: 'menu' }, // id : bookingId
  { path: '/my-page', component: MyPage, header: 'menu', footer: 'menu' },
  { path: '/booking/:id', component: BookingPage, header: 'booking', footer: 'none' }, // id : placeId
  { path: '/personal-info', component: PersonalInfoPage, header: 'none', footer: 'none' },
  { path: '/log-in', component: LogInPage, header: 'none', footer: 'none' },
  { path: '/sign-up', component: SignUpPage, header: 'none', footer: 'none' },
  { path: '/host', component: HostPage, header: 'none', footer: 'none' },
  {
    path: '/host/register-storage',
    component: RegisterStoragePage,
    header: 'register',
    footer: 'none',
  },
  { path: '/host/manage-storage', component: ManageStoragePage, header: 'none', footer: 'none' },
  // { path: '/host/landing', component: LandingPage, header: 'none', footer: 'none' },
  // { path: '/admin/places', component: AdminPlacesPage, header: 'admin', footer: 'none' },
  { path: '/google_callback', component: GoogleCallback, header: 'none', footer: 'none' },
  {
    path: '/kakao_callback',
    component: KakaoCallback,
    header: 'none',
    footer: 'none',
  },
  {
    path: '/line_callback',
    component: LineCallback,
    header: 'none',
    footer: 'none',
  },
  {
    path: '/naver_callback',
    component: NaverCallback,
    header: 'none',
    footer: 'none',
  },
  {
    path: '/host/register-success',
    component: RegisterSuccessPage,
    header: 'none',
    footer: 'none',
  },
  {
    path: '/booking-success/:id', // id : bookingId
    component: BookingSuccessPage,
    header: 'none',
    footer: 'none',
  },
];
