import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  createRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { SectionContainer, SectionTitle } from '../../assets/css/RegisterCommonStyles';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { RegisterPlaceType } from '../../types/storageType';
import { registrationValidState, storageRegistrationState } from '../../recoil/storageRecoil';
import styled from '@emotion/styled';
import ImageDelete from '../../assets/images/ImageDelete.png';
import LabelImageSrc from '../../assets/images/LabelImage.png';
import axios from 'axios';
import { useFormContext } from 'react-hook-form';
import { Cropper, ReactCropperElement } from 'react-cropper';
import CropperModal from '../common/CropperModal';

interface RegisterImageTypes {
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDeleteImage: () => void;
  handleCrop: (croppedImage: string) => void;
  openCropper: boolean;
  originalImageSrc: string;
  setOpenCropper: Dispatch<SetStateAction<boolean>>;
  cropData: string;
  fileInputRef: React.RefObject<HTMLInputElement>;
}
const RegisterImage = ({
  handleFileChange,
  handleDeleteImage,
  handleCrop,
  originalImageSrc,
  openCropper,
  setOpenCropper,
  cropData,
  fileInputRef,
}: RegisterImageTypes) => {
  const handleCloseCropper = () => {
    handleDeleteImage();
    setOpenCropper(false);
  };
  return (
    <SectionContainer>
      <SectionTitle>대표 이미지를 지정해주세요</SectionTitle>
      <ImgWrapper style={{ border: cropData ? '2px solid #0094ff' : '2px solid #ffffff' }}>
        {cropData ? (
          <>
            <SelectedImage src={cropData} />
            <DeleteIcon onClick={handleDeleteImage} src={ImageDelete} />
          </>
        ) : (
          <>
            <Label htmlFor="fileInput">
              <LabelImage src={LabelImageSrc} />
              <LabelText>기기에서 업로드</LabelText>
            </Label>
            <input
              ref={fileInputRef}
              onChange={handleFileChange}
              id="fileInput"
              type="file"
              name="file"
              accept=".jpg, .jpeg, .png"
              style={{ display: 'none' }}
            />
          </>
        )}
      </ImgWrapper>
      {openCropper && originalImageSrc && (
        <CropperModal
          originalImageSrc={originalImageSrc}
          onCrop={handleCrop}
          onClose={handleCloseCropper}
        />
      )}
    </SectionContainer>
  );
};

const LabelText = styled.div`
  text-decoration: underline;
  font-size: 0.9375rem;
  font-family: 'DM Sans Bold';
`;
const Label = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const LabelImage = styled.img`
  width: 3.125rem;
  height: auto;
`;

const DeleteIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 1.875rem;
  height: auto;
`;
const ImgWrapper = styled.div`
  margin-top: 70px;
  width: 100%;
  border: 2px solid #ffffff;
  &::before {
    content: '';
    display: block;
    padding-top: 100%; /* 1:1 Aspect Ratio */
  }
  aspect-ratio: 1;
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectedImage = styled.img`
  width: calc(100% - 4px);
  height: calc(100% - 4px);
`;
export default RegisterImage;
