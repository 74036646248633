import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { StorageType } from '../../types/storageType';
import { keyframes } from '@emotion/react';
import { GetPlaceType } from '../../types/storageType';
import closedDaysFilter, { getTodayStringForShow } from '../../utils/dateFilter';
import { useTranslation } from 'react-i18next';
import { splitBusinessHour } from '../../utils/parseOperatingTime';

interface StorageCardType {
  selectedItem: GetPlaceType | null;
  setSelectedItem: (value: GetPlaceType | null) => void;
}
const StorageCard = ({ selectedItem, setSelectedItem }: StorageCardType) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  useEffect(() => {
    // console.log(selectedItem, 'selectedItem');
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setSelectedItem(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedItem, setSelectedItem]);

  if (!selectedItem) {
    // alert('The storage facility is currently unavailable.');
    return null;
  }

  const { enabled, businessHours, name, category, facilities, pictures, placeId } = selectedItem;
  //enabled (영업중 여부)와 도보를 표시해야 함.
  // 도보 데이터는 어떻게 할 것인지 논의가 필요함.
  const todayBusinessHour = businessHours.find(
    (it) => it.dayOfWeek === getTodayStringForShow(businessHours),
  )?.businessHour;
  const handleGoBooking = () => {
    if (!enabled) {
      return;
    }
    // 예약 가능하면
    navigate(`/booking/${placeId}?step=information`);
    // 예약 불가능하면 현재 이용할 수 없다는 모달을 띄워줄 것인가?
    // 아니라면 이용할 수 없는 이유를 어떻게 알려줄 것인가?
  };

  const { isOpen24Hours } = closedDaysFilter(businessHours);
  return (
    <Container ref={containerRef}>
      <ImageWrapper>
        <img style={{ width: '100%', height: '100%' }} src={pictures[0]?.url} />
      </ImageWrapper>
      <ContentWrapper>
        <StoreTypeText>{t(`type.${category}`)}</StoreTypeText>
        <TitleText>{name}</TitleText>
        <TimeText>
          {!enabled
            ? '지금은 이용할 수 없습니다'
            : isOpen24Hours()
            ? t('operateDate.day_24')
            : splitBusinessHour(todayBusinessHour)?.endTime === '23:59'
            ? `${splitBusinessHour(todayBusinessHour)?.startTime} ~ 24:00`
            : todayBusinessHour || 'CLOSED'}
        </TimeText>
        <TextsWrapper></TextsWrapper>
        <TextsWrapper>
          {facilities.map((item, index) => (
            <DescriptionText
              key={index}
              className={index !== facilities.length - 1 ? 'margin-right' : ''}
            >
              {item.includes('F') ? item : t(`facility.${item}`)}
            </DescriptionText>
          ))}
        </TextsWrapper>
      </ContentWrapper>
      <BookNow onClick={handleGoBooking}>
        <BookButton disabled={!enabled}>BOOK NOW</BookButton>
      </BookNow>
    </Container>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const Container = styled.div`
  padding: 60px 23px 15px 23px;
  border-top: 2px solid #000000;
  position: absolute;
  bottom: 0;
  width: 100%;
  /* height: auto; */
  animation: ${fadeIn} 1s ease;
  z-index: 10;
  background-color: #f6f6f6;
  border-radius: 10px 10px 0 0;
  color: #1b303f;
  @media only screen and (max-width: 600px) {
    position: fixed;
  }
`;
const ImageWrapper = styled.div`
  position: absolute;
  left: 50%; /* 요소의 시작 지점을 부모 요소의 가운데로 설정 */
  transform: translateX(-50%); /* 요소를 자신의 너비의 50%만큼 왼쪽으로 이동 */
  top: -2.85rem;
  width: 5.75rem;
  height: 5.75rem;
  border-radius: 50%;
  border: 2px solid #1b303f;
  overflow: hidden;
`;
const ContentWrapper = styled.div`
  /* padding-top: 60px; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Bold = styled.div`
  font-family: 'DM Sans Bold';
`;
const Regular = styled.div`
  font-family: 'DM Sans Regular';
`;
const StoreTypeText = styled(Bold)`
  font-size: 0.75rem;
  line-height: 0.5rem;
  color: #868686;
  font-family: 'DM Sans Bold';
`;
const TitleText = styled(Bold)`
  color: #1b303f;
  font-family: 'DM Sans Bold';
  font-size: 1.5625rem;
`;
const TimeText = styled(Bold)`
  color: #828282;
  font-size: 0.9375rem;
  font-family: 'DM Sans Bold';
  padding-bottom: 19px;
`;

const TextsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 6px;
`;
const DescriptionText = styled(Regular)`
  color: #5b5656;
  border: 1px solid #868686;
  border-radius: 3px;
  padding: 0 8px;
  font-size: 0.75rem;
  &.margin-right {
    margin-right: 3px;
  }

  white-space: nowrap;
`;
const BookNow = styled(TextsWrapper)`
  background-color: #1b303f;
  color: #f6f6f6;
  margin-top: 10px;
  font-size: 1.875rem;
  width: 100%;
  border-radius: 30px;
  text-align: center;
  padding-top: 6px;
  font-family: 'DM Sans ExtraBold';
  /* padding : 6px 50px; */
`;
const BookButton = styled.button`
  all: unset;
`;
export default StorageCard;
