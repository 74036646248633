import styled from '@emotion/styled';

export const SectionTitle = styled.div`
  font-size: 1.25rem;
  font-family: 'DM Sans Bold';
`;
export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;
export const SectionInput = styled.input`
  all: unset;
  box-sizing: border-box;
  border: 2px solid #ffffff;
  border-radius: 10px;
  width: 100%;
  padding: 30px;
  font-size: 1.125rem;
  margin-bottom: 30px;
`;

export const ContentContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;
export const HostInput = styled.input`
  all: unset;
  box-sizing: border-box;
  border: 2px solid #ffffff;
  border-radius: 10px;
  width: 100%;
  padding: 30px;
  font-size: 1.125rem;
  margin-bottom: 30px;
`;
export const ContentTitle = styled.label`
  font-size: 0.9375rem;
  font-family: 'DM Sans SemiBold';
  color: #828282;
`;
export const ErrorMessage = styled.span`
  margin-left: 12px;
  color: #f74726;
  font-size: 0.75rem;
`;
export const ToggleContainer = styled.div`
  position: relative;

  cursor: pointer;

  > .toggle-container {
    width: 3rem;
    height: 1.5rem;
    border-radius: 30px;
    background-color: #d9d9d9;
  }
  //.toggle--checked 클래스가 활성화 되었을 경우의 CSS를 구현
  > .toggle--checked {
    background-color: #fcb331;
    transition: 0.5s;
  }

  > .toggle-circle {
    position: absolute;
    top: 1.75px;
    left: 2px;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: rgb(255, 254, 255);
    transition: 0.5s;
    //.toggle--checked 클래스가 활성화 되었을 경우의 CSS를 구현
  }
  > .toggle--checked {
    left: 1.6rem;
    transition: 0.5s;
  }
`;
export const ToggleFlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
  width: 100%;
`;
export const DayText = styled.div`
  font-size: 1.125rem;
  font-family: 'DM Sans Bold';
  margin-right: 40px;
`;
