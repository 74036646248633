import styled from '@emotion/styled';
import TicketItem from '../../components/reservation/TicketItem';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { ApiResponseType } from '../../apis/adminApi';
import { GetBookingsParamsType, getBookingsApi } from '../../apis/bookingApi';
import { BookingDataType, GetBookingsDataType } from '../../types/BookingType';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { authenticatedState } from '../../recoil/authenticatedAtom';
import { useLocation, useNavigate } from 'react-router-dom';
import { authIntentState } from '../../recoil/authIntentAtom';
import { useIntersectionObserverForUser } from '../../hooks/userIntersectionObserverForUser';
import Spinner from '../../components/common/Spinner';
import { modalState } from '../../recoil/modalAtom';

type TicketStatusType = 'CANCELLED' | 'WAIT' | 'IN_PROGRESS' | 'COMPLETED';
const TicketPage = () => {
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authenticatedState);
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [isClicked, setIsClicked] = useState<'active' | 'history'>('active');
  // const [page, setPage] = useState<number>(0);
  // const [ticketStatus, setTicketStatus] = useState<TicketStatusType>('WAIT');
  const setModal = useSetRecoilState(modalState);

  const handleClicked = (item: 'active' | 'history') => {
    setIsClicked(item);
  };
  const handleGoToLogin = () => {
    setAuthIntent({
      ...authIntent,
      previousPath: location.pathname,
      intent: 'tickets_all',
      intentPath: location.pathname,
    });
    // navigate('/log-in');
    setModal('login');
  };
  const {
    data: waitData,
    isLoading: waitLoading,
    isError: waitError,
    hasNextPage: hasNextPageWait,
    fetchNextPage: fetchNextPageWait,
  } = useInfiniteQuery({
    queryKey: ['bookings', 'WAIT'], // 밑의 any타입을 /bookings/me data type으로 변경
    queryFn: async ({ pageParam = 0 }): Promise<ApiResponseType<GetBookingsDataType>> => {
      const apiParams: GetBookingsParamsType = {
        status: 'WAIT',
        page: pageParam,
      };
      return getBookingsApi(apiParams);
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.data.data.length === 10 ? pages.length : undefined;
    },
    enabled: !!(isClicked === 'active') && !!isAuthenticated,
    initialPageParam: 0,
  });

  const {
    data: inProgressData,
    isLoading: inProgressLoading,
    hasNextPage: hasNextPageInProgress,
    fetchNextPage: fetchNextPageInProgress,
    isError: inProgressError,
    refetch,
    error,
  } = useInfiniteQuery({
    queryKey: ['bookings', 'IN_PROGRESS'], // 밑의 any타입을 /bookings/me data type으로 변경
    queryFn: async ({ pageParam = 0 }): Promise<ApiResponseType<GetBookingsDataType>> => {
      const apiParams: GetBookingsParamsType = {
        status: 'IN_PROGRESS',
        page: pageParam,
      };
      return getBookingsApi(apiParams);
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.data.data.length === 10 ? pages.length : undefined;
    },
    enabled: !!(isClicked === 'active') && !!isAuthenticated,
    initialPageParam: 0,
  });
  const {
    data: completedData,
    isLoading: completedLoading,
    hasNextPage: hasNextPageCompleted,
    fetchNextPage: fetchNextPageCompleted,
    isError: completedError,
    //  isError, refetch, error
  } = useInfiniteQuery({
    queryKey: ['bookings', 'COMPLETED'],
    queryFn: async ({ pageParam = 0 }): Promise<ApiResponseType<GetBookingsDataType>> => {
      const apiParams: GetBookingsParamsType = {
        status: 'COMPLETED',
        page: pageParam,
      };
      return getBookingsApi(apiParams);
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.data.data.length === 10 ? pages.length : undefined;
    },
    enabled: !!(isClicked === 'history') && !!isAuthenticated,
    initialPageParam: 0,
  });

  const { setTarget: setTargetWait } = useIntersectionObserverForUser({
    hasNextPage: hasNextPageWait,
    fetchNextPage: fetchNextPageWait,
  });
  const { setTarget: setTargetInProgress } = useIntersectionObserverForUser({
    hasNextPage: hasNextPageInProgress,
    fetchNextPage: fetchNextPageInProgress,
  });
  const { setTarget: setTargetCompleted } = useIntersectionObserverForUser({
    hasNextPage: hasNextPageCompleted,
    fetchNextPage: fetchNextPageCompleted,
  });

  if (waitLoading || inProgressLoading || completedLoading) {
    return <Spinner />;
  }
  if (waitError || inProgressError || completedError) {
    return <div>There is no ticket history</div>;
  }

  const renderBookings = () => {
    if (!isAuthenticated) {
      return (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            marginTop: 150,
          }}
        >
          <div>{t('ticketPage.no_ticket_message')}</div>
          <div
            onClick={handleGoToLogin}
            style={{
              cursor: 'pointer',
              color: '#0094ff',
              fontFamily: 'DM Sans Bold',
              textDecoration: 'underline',
            }}
          >
            {t('ticketPage.login_first')}
          </div>
        </div>
      );
    } else if (isClicked === 'active') {
      if (
        waitData?.pages.length === 1 &&
        waitData.pages[0].data.data.length === 0 &&
        inProgressData?.pages.length === 1 &&
        inProgressData.pages[0].data.data.length === 0
      ) {
        return (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              marginTop: 150,
            }}
          >
            There is no ticket active.
          </div>
        );
      } else
        return (
          <>
            {inProgressData?.pages.map((page, pageIndex) =>
              page.data.data.map((reservation, index) => (
                <TicketItem
                  key={reservation.booking.bookingId}
                  reservation={reservation}
                  ref={
                    pageIndex === inProgressData.pages.length - 1 &&
                    index === page.data.data.length - 1
                      ? setTargetInProgress
                      : null
                  }
                />
              )),
            )}
            {/* {waitData?.data.data.map((reservation) => (
            <TicketItem key={reservation.booking.bookingId} reservation={reservation}></TicketItem>
          ))} */}
            {waitData?.pages.map((page, pageIndex) =>
              page.data.data.map((reservation, index) => (
                <TicketItem
                  key={reservation.booking.bookingId}
                  reservation={reservation}
                  ref={
                    pageIndex === waitData.pages.length - 1 && index === page.data.data.length - 1
                      ? setTargetWait
                      : null
                  }
                />
              )),
            )}
          </>
        );
    } else if (isClicked === 'history') {
      if (completedData?.pages.length === 1 && completedData.pages[0].data.data.length === 0) {
        return (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              marginTop: 150,
            }}
          >
            There is no ticket history.
          </div>
        );
      } else
        return (
          <>
            {completedData?.pages.map((page, pageIndex) =>
              page.data.data.map((reservation, index) => (
                <TicketItem
                  key={reservation.booking.bookingId}
                  reservation={reservation}
                  ref={
                    pageIndex === completedData.pages.length - 1 &&
                    index === page.data.data.length - 1
                      ? setTargetCompleted
                      : null
                  }
                />
              )),
            )}
          </>
        );
    }
  };

  return (
    <Container>
      <Title>Bookings</Title>
      <div style={{ display: 'flex', width: '100%' }}>
        <ClickText
          style={{
            color: isClicked === 'active' ? '#1B303F' : '#868686',
            backgroundColor: isClicked === 'active' ? '#0094ff' : '#F6F6F6',
          }}
          onClick={() => handleClicked('active')}
        >
          {t('active')}
        </ClickText>
        <ClickText
          style={{
            color: isClicked === 'history' ? '#1B303F' : '#868686',
            backgroundColor: isClicked === 'history' ? '#0094ff' : '#F6F6F6',
          }}
          onClick={() => handleClicked('history')}
        >
          {t('history')}
        </ClickText>
      </div>

      {/* {inProgressData?.data.data.map((reservation) => {
        return (
          <TicketItem key={reservation.booking.bookingId} reservation={reservation}></TicketItem>
        );
      })} */}
      {renderBookings()}
    </Container>
  );
};
const Title = styled.div`
  font-size: 1.625rem;
  font-family: 'DM Sans ExtraBold';
  margin-bottom: 8px;
  margin-left: 10px;
`;
const Container = styled.div`
  padding: 30px 10px 0 10px;
  height: calc(100% - 140px);
  overflow: scroll;

  /* Chrome, Safari */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  scrollbar-width: none;

  /* Internet Explorer */
  -ms-overflow-style: none;
`;

const ClickText = styled.div`
  font-size: 1rem;
  font-family: 'DM Sans Medium';
  cursor: pointer;
  /* border-bottom: 2px solid #868686; */
  flex: 1;
  padding: 10px 0;
  text-align: center;
`;
export default TicketPage;
