import { DefaultValue, atom, selector } from 'recoil';
import { RegisterPlaceType } from '../types/storageType';
import { recoilPersist } from 'recoil-persist';
// 호스트 등록 시 데이터 상태 관리. post 요청 시 사용
export interface ValidType {
  category: boolean;
  about: boolean;
  facilities: boolean;
  quantity: boolean;
  addr: boolean;
  detailAddr: boolean;
  owner: boolean;
  contact: boolean;
  image: boolean;
}
export const defaultStepValidState: ValidType = {
  category: false,
  about: false,
  facilities: false,
  quantity: false,
  addr: false,
  detailAddr: false,
  owner: false,
  contact: false,
  image: false,
};

export const registrationValidState = atom<ValidType>({
  key: 'registrationValidState',
  default: defaultStepValidState,
});

export const defaultRegistrationState: RegisterPlaceType = {
  name: '',
  description: '',
  detailAddr: '',
  addr: '',
  roadAddr: '',
  lat: 0,
  lng: 0,
  contact: '',
  businessHours: [
    {
      dayOfWeek: 'MONDAY',
      closed: true,
      openAt: '',
      closeAt: '',
      isSpanToNextDay: false,
    },
    {
      dayOfWeek: 'TUESDAY',
      closed: true,
      openAt: '',
      closeAt: '',
      isSpanToNextDay: false,
    },
    {
      dayOfWeek: 'WEDNESDAY',
      closed: true,
      openAt: '',
      closeAt: '',
      isSpanToNextDay: false,
    },
    {
      dayOfWeek: 'THURSDAY',
      closed: true,
      openAt: '',
      closeAt: '',
      isSpanToNextDay: false,
    },
    {
      dayOfWeek: 'FRIDAY',
      closed: true,
      openAt: '',
      closeAt: '',
      isSpanToNextDay: false,
    },
    {
      dayOfWeek: 'SATURDAY',
      closed: true,
      openAt: '',
      closeAt: '',
      isSpanToNextDay: false,
    },
    {
      dayOfWeek: 'SUNDAY',
      closed: true,
      openAt: '',
      closeAt: '',
      isSpanToNextDay: false,
    },
  ],
  owner: {
    name: '',
    email: '',
    contact: '',
  },
  capacity: 1,
  facilities: null,
  category: '',
  pictures: [
    {
      key: '',

      originalFileName: '',
      mimeType: '',
      fileSize: 0,
    },
  ],
};
const { persistAtom } = recoilPersist({
  key: 'storageRegistration',
  storage: localStorage,
});
export const storageRegistrationState = atom<RegisterPlaceType>({
  key: 'storageRegistrationState',
  default: defaultRegistrationState,
  effects_UNSTABLE: [persistAtom],
});
