import { ReservationStateType } from '../recoil/reservationRecoil';
import { ApiResponseType } from './adminApi';
import authApiClient from './utils/authApiClient';

export const postBookingApi = async (bookingData: ReservationStateType) => {
  const response = await authApiClient.post('/bookings', bookingData);
  return response.data;
};
export interface GetBookingsParamsType {
  placeId?: number;
  status: 'CANCELLED' | 'WAIT' | 'IN_PROGRESS' | 'COMPLETED';
  page?: number;
  size?: number;
  sort?: string[];
}
export const getBookingsApi = async (params: GetBookingsParamsType) => {
  const response = await authApiClient.get('/bookings/me', {
    params,
  });
  return response.data;
};
export const getBookingByIdApi = async (bookingId: string) => {
  const response = await authApiClient.get(`/bookings/${bookingId}`);
  return response.data;
};

export const postCheckInApi = async (bookingId: string): Promise<ApiResponseType<{}>> => {
  const response = await authApiClient.post(`/bookings/${bookingId}/checkin`, {});
  return response.data;
};
export const postCheckOutApi = async (bookingId: string) => {
  const response = await authApiClient.post(`/bookings/${bookingId}/checkout`, {});
  return response.data;
};
export const cancelBookingApi = async (bookingId: string) => {
  const response = await authApiClient.delete(`/bookings/${bookingId}`);
  return response.data;
};
