import styled from '@emotion/styled';
import { RegisterPlaceType } from '../../types/storageType';
import { SectionTitle, SectionContainer } from '../../assets/css/RegisterCommonStyles';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  ValidType,
  registrationValidState,
  storageRegistrationState,
} from '../../recoil/storageRecoil';
import { useEffect } from 'react';

const RegisterCategory = () => {
  const setIsStepValid = useSetRecoilState<ValidType>(registrationValidState);
  const [storageRegistration, setStorageRegistration] =
    useRecoilState<RegisterPlaceType>(storageRegistrationState);
  const categories = [
    '카페',
    '식당',
    '샵',
    '대여점',
    '호텔',
    '펍 및 바',
    '슈퍼마켓',
    '옷가게',
    '편의점',
  ];
  const handleClickCategory = (category: string) => {
    setStorageRegistration({
      ...storageRegistration,
      category,
    });
    setIsStepValid((prev) => ({ ...prev, category: true }));
  };

  useEffect(() => {
    if (storageRegistration.category) {
      setIsStepValid((prev) => ({ ...prev, category: true }));
    }
  }, [storageRegistration.category]);

  return (
    <SectionContainer>
      <SectionTitle>다음 중 업체를 가장</SectionTitle>
      <SectionTitle>잘 설명하는것이 무엇인가요?</SectionTitle>
      <FlexContainer>
        {categories.map((item, index) => (
          <FlexItem
            className={item === storageRegistration.category ? 'clicked' : ''}
            onClick={() => handleClickCategory(item)}
            key={index}
          >
            <div>{item}</div>
          </FlexItem>
        ))}
      </FlexContainer>
    </SectionContainer>
  );
};

const FlexItem = styled.div`
  border: 2px solid #ffffff;
  border-radius: 10px;
  position: relative;
  width: calc(33.333% - 10px);
  margin: 5px;
  cursor: pointer;
  font-size: 1.125rem;
  font-family: 'DM Sans Bold';
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    display: block;
    padding-top: 100%; /* 1:1 Aspect Ratio */
  }

  & > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.clicked {
    border: 2px solid #0094ff;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap; // 아이템을 여러 줄로 배치
  justify-content: space-between; // 아이템 간의 간격을 균등하게 배치
  width: 100%; // 컨테이너의 너비 설정
  margin: 70px 20px 0 20px; // 페이지 중앙 정렬
`;

export default RegisterCategory;
