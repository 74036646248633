import { DefaultValue, atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { v4 as uuidv4 } from 'uuid';
export interface ReservationStateType {
  placeId: number | undefined;
  dropOffAt: string;
  pickUpAt: string;
  numOfBags: number;
  paymentAmount: number;
  paymentMethod: 'ON_SITE' | 'MOBILE' | 'CREDIT';
  period: number;
  type: 'HOURLY' | 'ROUGHLY' | 'ALL_DAY';
}

const { persistAtom } = recoilPersist({
  key: 'reservationInfo',
  storage: localStorage,
});
export const defaultReservationState: ReservationStateType = {
  placeId: undefined,
  dropOffAt: '', // 예약 날짜
  pickUpAt: '', //
  period: 1,
  numOfBags: 1, // 예약 수량
  paymentAmount: 1800, // 결제금액
  paymentMethod: 'ON_SITE', // 결제 방식
  type: 'HOURLY',
};

// reservation data 전체 관리
export const reservationState = atom<ReservationStateType>({
  key: `reservationState/${uuidv4()}`, // 고유한 키
  default: defaultReservationState,
  effects_UNSTABLE: [persistAtom],
});

// date section에서 선택하는 이용시간에 따라 정해지는 base price
export const basePriceState = atom({
  key: `basePriceState/${uuidv4()}`,
  default: 1800,
});

// base price에 quantity section에서 정하는 quantity를 곱하여 total price
export const totalPriceSelector = selector({
  key: `totalPriceState/${uuidv4()}`,
  get: ({ get }) => {
    const basePrice = get(basePriceState);
    const reservation = get(reservationState);
    return basePrice * reservation.numOfBags;
  },
});
export const selectedHourState = atom({
  // key: `selectedHourState/${uuidv4()}`,
  key: `selectedHourState`,
  default: 1,
});

// export const totalHourSelector = selector({
//   key: 'totalHour',
//   get: ({ get }) => {
//     const selectedHour = get(selectedHourState);
//     return selectedHour;
//   },
// });

export const checkInDateSelector = selector({
  key: `checkInDateSelector/${uuidv4()}`,
  get: ({ get }) => {
    const reservation = get(reservationState);
    return reservation.dropOffAt;
  },
  set: ({ set }, newValue: string | DefaultValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(reservationState, (prev) => ({
        ...prev,
        dropOffAt: newValue,
      }));
    }
  },
});

export const checkOutDateSelector = selector({
  key: `checkOutDateSelector/${uuidv4()}`,
  get: ({ get }) => {
    const reservation = get(reservationState);
    return reservation.pickUpAt;
  },
  set: ({ set }, newValue: string | DefaultValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(reservationState, (prev) => ({
        ...prev,
        pickUpAt: newValue,
      }));
    }
  },
});
