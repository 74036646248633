import styled from '@emotion/styled';
import KakaoLogo from '../../assets/images/KakaoLogo.png';
const Kakao = () => {
  const kakaoURL = `${process.env.REACT_APP_API_URL}/oauth2/authorization/kakao`;
  // const kakaoURL = 'https://dev-api.jimventory.com/oauth2/authorization/kakao';
  const handleKakaoLogin = () => {
    window.location.href = kakaoURL;
  };
  return (
    <Container onClick={handleKakaoLogin}>
      <img style={{ width: '3.375rem', height: 'auto' }} src={KakaoLogo} />
      <Text>Sign In with Kakao</Text>
    </Container>
  );
};
const Text = styled.div`
  font-size: 1.25rem;
  color: #000000;
  font-family: 'DM Sans Medium';
`;
const Container = styled.div`
  width: 100%;
  background-color: #fee500;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
`;

export default Kakao;
