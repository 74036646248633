import styled from '@emotion/styled';
import { SectionTitle, SectionContainer } from '../../assets/css/RegisterCommonStyles';
import ShoppingBag from '../../assets/images/ShoppingBag.png';
import { useEffect, useState } from 'react';
import MinusDisabled from '../../assets/images/StairDown.png';
import PlusEnabled from '../../assets/images/PolygonUpMoreGray.png';
import MinusEnabled from '../../assets/images/PolygonDownMoreGray.png';
import { RegisterPlaceType } from '../../types/storageType';
import { useRecoilState } from 'recoil';
import { registrationValidState, storageRegistrationState } from '../../recoil/storageRecoil';
import LugezBag from '../../assets/images/LugezBag.png';
const RegisterQuantity = () => {
  const [isStepValid, setIsStepValid] = useRecoilState(registrationValidState);
  const [storageRegistration, setStorageRegistration] =
    useRecoilState<RegisterPlaceType>(storageRegistrationState);
  const { capacity } = storageRegistration;
  const handleBagsQuantity = (intention: 'up' | 'down') => {
    if (intention === 'up') {
      setStorageRegistration({
        ...storageRegistration,
        capacity: capacity + 1,
      });
    } else {
      if (capacity === 1) return;
      setStorageRegistration({
        ...storageRegistration,
        capacity: capacity - 1,
      });
    }
  };
  useEffect(() => {
    setIsStepValid((prev) => ({
      ...prev,
      quantity: !!capacity,
    }));
  }, [capacity]);
  return (
    <SectionContainer>
      <SectionTitle>몇 개의 짐 보관이 가능하신가요?</SectionTitle>
      <DetailTitle>대략적인 갯수를 알려주세요</DetailTitle>
      <Img src={LugezBag} />
      <QuantityFlex>
        <div onClick={() => handleBagsQuantity('down')}>
          <Polygon src={capacity === 1 ? MinusDisabled : MinusEnabled} />
        </div>
        <BagText>{capacity && capacity}</BagText>
        <div onClick={() => handleBagsQuantity('up')}>
          <Polygon src={PlusEnabled} />
        </div>
      </QuantityFlex>
    </SectionContainer>
  );
};

const Polygon = styled.img`
  width: 1rem;
  height: auto;
`;
const BagText = styled.div`
  font-size: 1.125rem;
  color: #1b1919;
  font-family: 'DM Sans Bold';
`;
const DetailTitle = styled.div`
  margin-top: 10px;
  font-size: 0.75rem;
  font-family: 'DM Sans Light';
  color: #343739;
`;
const Img = styled.img`
  width: 80%;
  height: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 20%;
`;
const QuantityFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 110px;
  width: 100%;
`;
export default RegisterQuantity;
