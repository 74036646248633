import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { authIntentState } from '../../recoil/authIntentAtom';
import { authenticatedState } from '../../recoil/authenticatedAtom';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { adminAuthenticatedState } from '../../recoil/adminAuthenticatedAtom';
import { useCookies } from 'react-cookie';

const AdminPage = () => {
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useRecoilState(adminAuthenticatedState);
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authenticatedState);
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  const [, , removeCookie] = useCookies(['access_token', 'refresh_token']);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAdminAuthenticated) {
      setAuthIntent({ ...authIntent, intent: 'admin', intentPath: '/admin' });
      setIsAuthenticated(false);
      removeCookie('access_token', { path: '/' });
      removeCookie('refresh_token', { path: '/' });
      navigate('/log-in');
    }
  }, [isAdminAuthenticated]);

  const adminLogout = () => {
    setIsAdminAuthenticated(false);
    setIsAuthenticated(false);
    removeCookie('access_token', { path: '/' });
    removeCookie('refresh_token', { path: '/' });
    // setAuthIntent({ ...authIntent, intent: 'admin' });
    // navigate('/log-in');
  };
  return (
    <Container>
      <Text onClick={() => navigate('/admin/places')}>사업체 관리</Text>
      <Text>유저 관리</Text>
      <Text>호스트 관리</Text>
      <Text onClick={adminLogout}>로그아웃</Text>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;
const Text = styled.div`
  text-decoration: underline;
  margin-bottom: 10px;
  cursor: pointer;
`;
export default AdminPage;
