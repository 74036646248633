import { useRecoilState } from 'recoil';
import XLayout from '../../components/common/XLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import UserInput from '../../components/common/UserInput';
import { modalState } from '../../recoil/modalAtom';
import { authIntentState } from '../../recoil/authIntentAtom';
import { authenticatedState } from '../../recoil/authenticatedAtom';
import axios from 'axios';
import Google from '../../components/socialLogin/Google';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import apiClient from '../../apis/utils/apiClient';
import Kakao from '../../components/socialLogin/Kakao';
import Line from '../../components/socialLogin/Line';
import { adminAuthenticatedState } from '../../recoil/adminAuthenticatedAtom';
import LugezLogo from '../../assets/images/LugezLogo.png';
import Naver from '../../components/socialLogin/Naver';
const LoginModal = () => {
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useRecoilState(adminAuthenticatedState);
  const [modal, setModal] = useRecoilState(modalState);
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  const [cookies, setCookie] = useCookies(['access_token', 'refresh_token']);
  const { intent, previousPath } = authIntent;
  const navigate = useNavigate();
  const location = useLocation();
  const { placeId } = location.state || {};
  const [adminInfo, setAdminInfo] = useState({
    email: '',
    password: '',
  });
  const handleClickXButton = () => {
    // navigate(previousPath);
    // 모달이기 때문에 x 버튼 누르면 페이지 이동하지 않는다.
    // 버튼 눌러서 login페이지로 이동하던 애들을 무조건 다 modal 띄우는걸로 해주기만 하면 되고,
    // x 버튼 누르면 모달 닫히기만 하면 됨. 여기선.
    setModal('none');
  };

  const adminResponse = async () => {
    try {
      const response = await apiClient.post('/auth/admin/login', {
        email: adminInfo.email,
        password: adminInfo.password,
      });
      if (response.status === 200) {
        setCookie('access_token', response.data.data.accessToken, {
          path: '/',
          secure: true,
          sameSite: 'strict',
        });
        setCookie('refresh_token', response.data.data.refreshToken, {
          path: '/',
          secure: true,
          sameSite: 'strict',
        });
        setIsAdminAuthenticated(true);
        navigate('/admin');
      }
    } catch (error) {
      console.error(error, 'admin token get error');
    }
  };

  const handleChangeSignUp = () => {
    setAuthIntent({ ...authIntent, authMode: 'signUp' });
    navigate('/sign-up', { state: { placeId } });
  };

  return (
    <>
      {intent === 'admin' ? (
        <AdminContainer>
          <img style={{ width: 'auto', height: '2.5rem' }} src={LugezLogo} />
          <Form>
            <UserInput
              inputValue={adminInfo.email}
              handleChange={(e) => setAdminInfo({ ...adminInfo, email: e.target.value })}
              placeholder="email"
            />
            <UserInput
              inputValue={adminInfo.password}
              handleChange={(e) => setAdminInfo({ ...adminInfo, password: e.target.value })}
              placeholder="password"
            />
            <LogInButton onClick={adminResponse}>
              <div>LOG IN</div>
            </LogInButton>
          </Form>
        </AdminContainer>
      ) : (
        <XLayout handleClickXButton={handleClickXButton}>
          <Container>
            <Google />
            <Line />
            <Kakao />

            <Naver />
            {/* <Message>Don't you have an account?</Message>
        <SignUp onClick={handleChangeSignUp}>Sign Up!</SignUp> */}
          </Container>
        </XLayout>
      )}
    </>
  );
};

const AdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 150px;
`;
const Container = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 28px;
  padding: 10px;
`;

const LogInButton = styled.div`
  background-color: #000000;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 5px 0;
  font-size: 1.875rem;
  font-family: 'DM Sans Bold';
  cursor: pointer;
`;
const Message = styled.div`
  margin-top: 45px;
  font-size: 0.875rem;
  font-family: 'DM Sans Medium';
  margin-bottom: 20px;
`;
const SignUp = styled.div`
  text-decoration: underline;
  color: #0094ff;
  font-size: 1.125rem;
  font-family: 'DM Sans Bold';
  cursor: pointer;
`;
export default LoginModal;
