import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export type AdminAuthenticatedStateType = boolean;

const { persistAtom } = recoilPersist({
  key: 'adminAuthenticated',
  storage: localStorage,
});
export const adminAuthenticatedState = atom<AdminAuthenticatedStateType>({
  key: 'adminAuthenticatedState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});
