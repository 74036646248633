import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const PrivacyPolicyDocument = () => {
  const { t } = useTranslation();
  return (
    <Container>
      {/* collection */}
      <Title>{t('privacyPolicy.collection.title')}</Title>
      <div>{t('privacyPolicy.collection.content1')}</div>
      <div>{t('privacyPolicy.collection.content2')}</div>
      {/* usage */}
      <Title>{t('privacyPolicy.usage.title')}</Title>
      <div>{t('privacyPolicy.usage.content1')}</div>
      <div>{t('privacyPolicy.usage.content2')}</div>
      <div>{t('privacyPolicy.usage.content3')}</div>
      {/* provisionAndSharing */}
      <Title>{t('privacyPolicy.provisionAndSharing.title')}</Title>
      <div>{t('privacyPolicy.provisionAndSharing.content1')}</div>
      <div>{t('privacyPolicy.provisionAndSharing.content2')}</div>
      <div>{t('privacyPolicy.provisionAndSharing.content3')}</div>
      {/* protection */}
      <Title>{t('privacyPolicy.protection.title')}</Title>
      <div>{t('privacyPolicy.protection.content1')}</div>
      <div>{t('privacyPolicy.protection.content2')}</div>
      {/* accessCorrectionDeletion */}
      <Title>{t('privacyPolicy.accessCorrectionDeletion.title')}</Title>
      <div>{t('privacyPolicy.accessCorrectionDeletion.content1')}</div>
      <div>{t('privacyPolicy.accessCorrectionDeletion.content2')}</div>
      <div>{t('privacyPolicy.accessCorrectionDeletion.content3')}</div>
      {/* cookies */}
      <Title>{t('privacyPolicy.cookies.title')}</Title>
      <div>{t('privacyPolicy.cookies.content1')}</div>
      <div>{t('privacyPolicy.cookies.content2')}</div>
      <div>{t('privacyPolicy.cookies.content3')}</div>
      {/* others */}
      <Title>{t('privacyPolicy.others.title')}</Title>
      <div>{t('privacyPolicy.others.content1')}</div>
      <div>{t('privacyPolicy.others.content2')}</div>
    </Container>
  );
};

const Container = styled.div`
  padding-left: 15px;
  padding-right: 10px;
  font-size: 0.875rem;
`;
const Title = styled.div`
  margin-top: 10px;
`;
export default PrivacyPolicyDocument;
