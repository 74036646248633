import styled from '@emotion/styled';
import {
  SectionTitle,
  SectionContainer,
  ContentContainer,
  FlexBox,
  SectionInput,
  ContentTitle,
  ErrorMessage,
} from '../../assets/css/RegisterCommonStyles';
import { useFormContext } from 'react-hook-form';
import { RegisterPlaceType } from '../../types/storageType';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { registrationValidState, storageRegistrationState } from '../../recoil/storageRecoil';
import { useEffect } from 'react';

const RegisterAbout = () => {
  const {
    register,
    watch,
    formState: { isValid, errors },
  } = useFormContext();
  const [storageRegistration, setStorageRegistration] =
    useRecoilState<RegisterPlaceType>(storageRegistrationState);
  const setIsStepValid = useSetRecoilState(registrationValidState);
  const name = watch('name');
  const description = watch('description');
  const validateName = (value: string) => {
    if (!value) return '업체명을 입력해주세요';
    return true;
  };
  const validateDescription = (value: string) => {
    if (!value) return '업체를 소개해주세요';
    if (value.length > 100) return false;
    return true;
  };
  useEffect(() => {
    if (isValid) {
      setIsStepValid((prev) => ({
        ...prev,
        about: true,
      }));
      setStorageRegistration({
        ...storageRegistration,
        name: name.toString(),
        description: description.toString(),
      });
    } else {
      setIsStepValid((prev) => ({
        ...prev,
        about: false,
      }));
    }
  }, [name, description, isValid]);

  return (
    <SectionContainer>
      <SectionTitle>업체에 대해 알려주세요</SectionTitle>

      <ContentContainer>
        <FlexBox>
          <ContentTitle htmlFor="name">업체명</ContentTitle>
          {errors.name?.message && <ErrorMessage role="alert">업체명을 입력해주세요</ErrorMessage>}
        </FlexBox>

        <SectionInput
          placeholder="업체명"
          id="name"
          {...register('name', {
            validate: validateName,
          })}
          style={{
            borderColor: errors?.name?.message ? '#f74726' : name ? '#0094ff' : '#ffffff',
          }}
        />
        <FlexBox>
          <ContentTitle htmlFor="description">소개</ContentTitle>
          {errors.description?.message && (
            <ErrorMessage role="alert">{errors?.description?.message?.toString()}</ErrorMessage>
          )}
        </FlexBox>

        <TextArea
          placeholder="설명 (100자 내로 적어주세요.)"
          id="description"
          {...register('description', {
            validate: validateDescription,
            maxLength: { value: 100, message: '업체 설명은 100자 이내로 작성 가능합니다' },
          })}
          style={{
            borderColor: errors?.description?.message
              ? '#f74726'
              : description
              ? '#0094ff'
              : '#ffffff',
          }}
        />
      </ContentContainer>
    </SectionContainer>
  );
};

const TextArea = styled.textarea`
  all: unset;
  box-sizing: border-box;
  border: 2px solid #ffffff;
  border-radius: 10px;
  width: 100%;
  padding: 40px 34px;
  min-height: 250px;
  font-size: 1.125rem;
  word-wrap: break-word;
`;

export default RegisterAbout;
