import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export interface PersonalInfoType {
  name: string;
  email: string;
  rawPhoneNumber?: string;
}

export const defaultInfoState: PersonalInfoType = {
  name: '',
  email: '',
  rawPhoneNumber: '',
};
const { persistAtom } = recoilPersist({
  key: 'hasPersonalInfo',
  storage: localStorage,
});
export const hasPersonalInfoState = atom({
  key: 'hasPersonalInfoState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});
// state 종류 : login, signup, menu, 번역
