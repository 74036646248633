import styled from '@emotion/styled';
import ClockImage from '../../assets/images/DateOperating.png';
import CalendarImage from '../..//assets/images/Calendar.png';
import { Dispatch, SetStateAction, forwardRef, useEffect, useState } from 'react';
import ConfirmFalse from '../../assets/images/ConfirmFalse.png';
import ConfirmTrue from '../../assets/images/ConfirmTrue.png';
import * as S from '../../assets/css/BookingDateStyles';
import ReactDatePicker from 'react-datepicker';
import { format, setDate } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { setHours, setMinutes } from 'date-fns';
import { defaultCheckInDate, roundedUpTime } from '../../utils/roundUpTime';
import {
  ReservationStateType,
  reservationState,
  selectedHourState,
} from '../../recoil/reservationRecoil';
import { useRecoilState } from 'recoil';
import ChatSupport from '../../assets/images/Chat.png';
import CancelCheckIn from '../../assets/images/CancelCheckIn.png';
import { parseOperatingTime } from '../../utils/parseOperatingTime';
import { GetBusinessHourType } from '../../types/storageType';
import closedDaysFilter from '../../utils/dateFilter';
import AlertTriangle from '../../assets/images/AlertTriangle.png';
import { useTranslation } from 'react-i18next';
import { checkInState } from '../../recoil/checkInObject';

interface BookingDateType {
  operatingTime: string;
  // handleChangeCheckInDate: (e: ChangeEvent<HTMLInputElement>) => void;
  checkInObj: Date | null;
  getHoursAndPriceToAdd: (hours: number, price: number) => void;
  hoursToAdd: number;
  setHoursToAdd: Dispatch<SetStateAction<number>>;
  setCheckInObj: Dispatch<SetStateAction<Date | null>>;
  checkOutDate: string;
  isValidTime: boolean;
  businessHours: GetBusinessHourType[] | [];
}
const BookingDate = ({
  isValidTime,
  getHoursAndPriceToAdd,
  setCheckInObj,
  checkOutDate,
  operatingTime,
  checkInObj,
  businessHours,
}: BookingDateType) => {
  // const [checkInObj, setCheckInObj] = useRecoilState<Date>(checkInState);
  const [checked, setChecked] = useState<number>(1);
  const [selectedHour, setSelectedHour] = useRecoilState(selectedHourState);
  const [reservationInfo, setReservationInfo] =
    useRecoilState<ReservationStateType>(reservationState);
  const { t } = useTranslation();

  const hourOption = [
    {
      id: 1,
      price: 1800 * selectedHour,
      label: `${selectedHour} ${selectedHour === 1 ? 'hour' : 'hours'}`,
      hoursToAdd: selectedHour,
      optionLabel: 'calculate',
      type: 'HOURLY',
    },
    {
      id: 2,
      price: 5000,
      label: 'Roughly',
      optionLabel: 'roughly',
      hoursToAdd: 4,
      type: 'ROUGHLY',
    },
    {
      id: 3,
      price: 12000,
      label: '24hours',
      hoursToAdd: 24,
      optionLabel: 'none',
      type: 'ALL_DAY',
    },
  ];

  // 커스텀 인풋
  const CustomInput = forwardRef((props, ref: React.ForwardedRef<HTMLInputElement>) => {
    return (
      <S.InputWrapper>
        <S.CalendarImage src={CalendarImage} />
        <S.DateInput {...props} ref={ref} type="text" />
      </S.InputWrapper>
    );
  });

  // 1 hours 기준으로 +, -
  const increaseSelectedHour = () => {
    setSelectedHour((prev) => prev + 1);
    getHoursAndPriceToAdd(selectedHour + 1, 1800 * (selectedHour + 1)); // 증가하거나 감소한 selectedHour이 바로 반영되지 않기 때문에 먼저 더하거나 빼서 getHoursToAdd에 전달
    setChecked(1);
  };
  const decreaseSelectedHour = () => {
    if (selectedHour > 1) {
      setSelectedHour((prev) => prev - 1);
      getHoursAndPriceToAdd(selectedHour - 1, 1800 * (selectedHour - 1));
      setChecked(1);
    }
  };

  // 날짜 비교 로직을 별도 함수로 추출하여 중복 최소화
  const isSameDay = (date1: Date, date2: Date) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const {
    closedDaysNumbers,
    closedDays,
    getTodayStringForFilter,
    getPrevDayStringForFilter,
    openForPrevDayBusinessHour,
  } = closedDaysFilter(businessHours);
  const isDayOpen = (date: Date, businessHours: GetBusinessHourType[]) => {
    if (openForPrevDayBusinessHour(date, businessHours)) {
      return true;
    } else {
      const day = date.getDay();
      return !closedDaysNumbers.includes(day);
    }
  };

  // 현재 날짜와 선택된 날짜가 같은지 비교하여 최소,최대 시간을 설정
  const filterPassedTime = (time: Date | null, businessHours: GetBusinessHourType[]) => {
    if (time) {
      const currentDate = new Date();
      const selectedDate = new Date(time);
      const selectedHour = selectedDate.getHours();
      const selectedMinute = selectedDate.getMinutes();
      const currentHours = currentDate.getHours();
      const currentMinutes = currentDate.getMinutes();
      const todayString = getTodayStringForFilter(time);
      const prevDayString = getPrevDayStringForFilter(time);
      const todayBusinessHour = businessHours.find((bh) => bh.dayOfWeek === todayString);
      const prevDayBusinessHour = businessHours.find((bh) => bh.dayOfWeek === prevDayString);

      if (todayBusinessHour) {
        const { closed, businessHour, isSpanToNextDay } = todayBusinessHour;
        if (closed) {
          if (openForPrevDayBusinessHour(time, businessHours) && prevDayBusinessHour) {
            // 당일은 휴무지만 전 날이 익일영업 + 오픈이면 새벽시간대를 열어줘야 해서 만든 함수.
            // 전 날의 마감시간을 적용시키기 위해 prevDayBusinessHour을 가공하여 사용한다.
            if (prevDayBusinessHour.businessHour) {
              const { endHour, endMinute } = parseOperatingTime(prevDayBusinessHour.businessHour);

              return {
                minTime: setHours(setMinutes(selectedDate, 0), 0),
                maxTime:
                  endMinute >= 10
                    ? setHours(setMinutes(currentDate, endMinute - 10), endHour - 1)
                    : setHours(setMinutes(currentDate, endMinute + 50), endHour - 2),
              };
            } else return;
          }
          return; // 영업을 하지 않으면 함수 종료
        } else if (businessHour) {
          const { startHour, startMinute, endHour, endMinute } = parseOperatingTime(businessHour);

          // 24시간 영업인지 여부 확인 => 00:00마감은 익일영업이 아니라면 서버에서 오류가 난다.
          // 우리는 24시간 영업 혹은 자정 마감을 당일영업으로 간주하기로 했으므로
          // 들어오는 데이터 중 23:59으로 판단.
          const is24Hours =
            startHour === 0 && startMinute === 0 && endHour === 24 && endMinute === 0;

          // maxTime 정의
          const maxTime = is24Hours
            ? setHours(setMinutes(currentDate, 59), 23)
            : endMinute >= 10
            ? setHours(setMinutes(currentDate, endMinute - 10), endHour - 1)
            : setHours(setMinutes(currentDate, endMinute + 50), endHour - 2); // 영업시간 기준으로 마감 1시간 10분전까지만 체크인 가능
          // minTime get함수
          const getMinTime = () => {
            if (isSpanToNextDay) {
              // 이미 영업시간 범위 내에 있는 경우
              if (
                currentHours < endHour ||
                (currentHours === endHour && currentMinutes < endMinute) ||
                currentHours > startHour ||
                (currentHours === startHour && currentMinutes > startMinute)
              ) {
                return setHours(
                  setMinutes(currentDate, roundedUpTime(currentDate)),
                  roundedUpTime(currentDate) === 60 ? currentHours + 1 : currentHours,
                );
              }
              // 영업시간 바깥 (익일영업이므로 마감-오픈 사이 중간의 시간대)
              else {
                return setHours(setMinutes(currentDate, startMinute), startHour);
              }
            }
            // overNight아닌 경우
            else {
              if (
                // 현재 시각이 문 열지 않은 시각
                currentHours < startHour ||
                (currentHours === startHour && currentMinutes < startMinute)
              ) {
                return setHours(setMinutes(currentDate, startMinute), startHour);
              } else {
                if (roundedUpTime(currentDate) === 60 && currentHours === 23) {
                  return setHours(setMinutes(currentDate, 59), 23);
                }
                return setHours(
                  setMinutes(currentDate, roundedUpTime(currentDate)),
                  roundedUpTime(currentDate) === 60 ? currentHours + 1 : currentHours,
                );
              }
            }
          };
          // 여기서부터 필터링 함수 return문
          // 1. 오늘 선택
          if (isSameDay(currentDate, selectedDate)) {
            return {
              minTime: getMinTime(),
              maxTime: isSpanToNextDay ? setHours(setMinutes(selectedDate, 59), 23) : maxTime,
            };
          }
          // 2. 다른 날짜 선택 - 영업시간 내에서 선택 가능(익일케이스 제외)
          else {
            // overNight? 최소, 최대따위 걸지 말자. detail 함수에서 그 사이 시간을 걸러주면 된다.
            if (isSpanToNextDay) {
              // 전 날이 휴무라면
              if (closedDays.length !== 0 && closedDays.includes(prevDayString!)) {
                return {
                  minTime: setHours(setMinutes(selectedDate, startMinute), startHour),
                  maxTime: setHours(setMinutes(selectedDate, 59), 23),
                };
              } else {
                return {
                  minTime: setHours(setMinutes(selectedDate, 0), 0),
                  maxTime: setHours(setMinutes(selectedDate, 59), 23),
                };
              }
            }
            // 익일 영업 아니라면
            else
              return {
                minTime: setHours(setMinutes(selectedDate, startMinute), startHour),
                maxTime: maxTime,
              };
          }
        }
      }
    }
  };

  // 디테일한 시간 제어, 오늘 날짜 선택 시 현재 시간이 8시30분을 초과하면 오늘은 예약불가
  const filterDetailTimes = (time: Date | null, businessHours: GetBusinessHourType[]): boolean => {
    if (time) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      const currentMinute = currentDate.getMinutes();
      const selectedDate = new Date(time);
      const selectedHour = selectedDate.getHours();
      const selectedMinute = selectedDate.getMinutes();
      const todayString = getTodayStringForFilter(time);
      const todayBusinessHour = businessHours.find((bh) => bh.dayOfWeek === todayString);

      if (todayBusinessHour) {
        const { closed, businessHour, isSpanToNextDay } = todayBusinessHour;
        if (closed) {
          if (openForPrevDayBusinessHour(time, businessHours)) return true;
          return false; // 영업을 하지 않으면 함수 종료
        } else if (businessHour) {
          const { startHour, startMinute, endHour, endMinute } = parseOperatingTime(businessHour);

          // 24시간 영업인지 여부 확인
          const is24Hours =
            startHour === 0 && startMinute === 0 && endHour === 24 && endMinute === 0;

          if (isSameDay(currentDate, selectedDate)) {
            // 지금 시각만 따진다.
            if (is24Hours) {
              return true;
            }

            // 오류 생길 수 있음. 오늘 선택인데 current대신 selected로 변경해놔서
            // 다시 바꿔야 할지도 => 바꿨음. 왜? 현재 시각이 마감을 지났는지등을 확인할 수 없었기 때문.
            else if (isSpanToNextDay) {
              if (startMinute === 0) {
                if (endMinute > 10) {
                  return (
                    (selectedHour < endHour - 1 && selectedMinute <= endMinute - 10) ||
                    selectedHour >= startHour
                  );
                } else {
                  // console.log('sffafa');
                  return (
                    (selectedHour <= endHour - 2 && selectedMinute <= endMinute + 50) ||
                    selectedHour >= startHour
                  );
                }
              } else if (startMinute !== 0) {
                if (endMinute > 10) {
                  return (
                    (selectedHour < endHour - 1 && selectedMinute <= endMinute - 10) ||
                    selectedHour > startHour ||
                    (selectedHour === startHour && selectedMinute >= startMinute)
                  );
                } else {
                  return (
                    (selectedHour < endHour - 2 && selectedMinute <= endMinute + 50) ||
                    selectedHour > startHour ||
                    (selectedHour === startHour && selectedMinute >= startMinute)
                  );
                }
              }
              // if(startMinute > 10) {
              //   return (startMinute > selectedMinute && selectedHour - 1 > selectedHour )

              // } else if(startMinute <=10) {
              //   return (startMinute + 50 > selectedMinute && selectedHour - 2 > selectedHour)
              // }
            } else if (
              (endMinute >= 10 && endHour - 1 < selectedHour) ||
              (endHour - 1 === selectedHour && endMinute - 10 < selectedMinute)
            ) {
              return false;
            } else if (
              (endMinute < 10 && endHour - 2 < selectedHour) ||
              (endHour - 2 === selectedHour && endMinute + 50 <= selectedMinute)
            ) {
              return false;
            } else if (
              (endHour === selectedHour && endMinute <= selectedMinute) ||
              endHour < selectedHour
            ) {
              return false;
            } else return true;
          }
          // else return true;
          else {
            // 오늘이 아닌 날짜의 경우 마감시간 -2 보다 크고(오전3시 마감이면 2시부터는 안 된다는 뜻)
            // 오픈 시간보다 작은 경우(오픈 시간 이전인 경우) , 즉 가운데 시간은 모두 false로 선택 못 함.
            // 이것도 3시 50문 마감인 경우를 생각했을 때 들어맞지 않음. 코드 수정 필요.
            // 오늘이 아닌 날짜의 경우 왜 overNight인 경우만 생각하나?
            // overNight아닌 경우는 filteredTime에서 이미 제어 끝났기 때문
            if (isSpanToNextDay) {
              if (endMinute < 10) {
                if (startMinute < 10) {
                  return !(selectedHour > endHour - 2 && selectedHour < startHour);
                } else {
                  return !(
                    selectedHour > endHour - 2 &&
                    ((selectedHour === startHour && selectedMinute < startMinute) ||
                      selectedHour < startHour)
                  );
                }
              } else {
                if (startMinute < 10) {
                  return !(
                    selectedHour < startHour &&
                    ((selectedHour === endHour - 1 && selectedMinute > endMinute - 10) ||
                      selectedHour > endHour)
                  );
                } else {
                  return !(
                    ((selectedHour === startHour && selectedMinute < startMinute) ||
                      selectedHour < startHour) &&
                    ((selectedHour === endHour - 1 && selectedMinute > endMinute - 10) ||
                      selectedHour >= endHour)
                  );
                }
              }
            } else return true;
          }
        }
      }
    }
    return true;
  };

  return (
    <S.BigContainer>
      <S.Container className="date-container">
        <S.DropOffWrapper style={{ justifyContent: 'space-between' }}>
          <S.DropOff>Drop-off</S.DropOff>
          <S.ClockWrapper>
            <S.Clock src={ClockImage} />
            <S.OperatingText>{operatingTime}</S.OperatingText>
          </S.ClockWrapper>
        </S.DropOffWrapper>
        <S.DropOffWrapper>
          <ReactDatePicker
            selected={checkInObj || defaultCheckInDate}
            onChange={(date: Date | null) => {
              if (date) {
                setCheckInObj(date);
              }
            }}
            customInput={<CustomInput />}
            dateFormat="yyyy.MM.dd HH:mm" // 원하는 날짜 형식 지정
            showTimeSelect // 시간 선택 가능하게 설정
            timeFormat="HH:mm" // 시간 형식도 지정
            minDate={new Date()}
            minTime={
              checkInObj
                ? filterPassedTime(checkInObj, businessHours)?.minTime
                : filterPassedTime(new Date(), businessHours)?.minTime
            }
            maxTime={
              checkInObj
                ? filterPassedTime(checkInObj, businessHours)?.maxTime
                : filterPassedTime(new Date(), businessHours)?.maxTime
            } // 운영시간으로 받아오는 값에서 한시간 반 전
            timeIntervals={10}
            filterTime={(checkInDate: Date) => filterDetailTimes(checkInDate, businessHours)}
            filterDate={(date) => isDayOpen(date, businessHours)}
          />
        </S.DropOffWrapper>
        <div style={{ marginTop: 40 }}>
          {hourOption.map((option) => (
            <S.HoursWrapper key={option.id}>
              <S.OneHourWrapper>
                <S.HourBigText>{option.label}</S.HourBigText>
                <S.PlusMinusWrapper>
                  {option.optionLabel === 'calculate' ? (
                    <>
                      <S.CalculateButton onClick={decreaseSelectedHour}>-</S.CalculateButton>
                      <S.CalculateButton onClick={increaseSelectedHour}>+</S.CalculateButton>
                    </>
                  ) : option.optionLabel === 'roughly' ? (
                    <S.About4Hours>about 4hours</S.About4Hours>
                  ) : option.optionLabel === 'none' ? null : null}
                </S.PlusMinusWrapper>
              </S.OneHourWrapper>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <S.HourBigText>{option.price} KRW</S.HourBigText>
                <div
                  onClick={() => {
                    getHoursAndPriceToAdd(option.hoursToAdd, option.price);
                    setChecked(option.id);
                    setReservationInfo((prevState) => ({
                      ...prevState,
                      type: option.type as ReservationStateType['type'],
                    }));
                  }}
                >
                  <S.CheckBoxImage src={checked === option.id ? ConfirmTrue : ConfirmFalse} />
                </div>
              </div>
            </S.HoursWrapper>
          ))}
        </div>
        {isValidTime ? (
          <S.CheckOutMessage>
            <div className="check-out_1">Pick-up</div>
            <div className="check-out_2">{checkOutDate}</div>
          </S.CheckOutMessage>
        ) : (
          <S.CheckOutError>{t('bookingPage.cannot_reserve')}</S.CheckOutError>
        )}
      </S.Container>
      <S.MoreInfoContainer>
        <div
          style={{
            color: '#828282',
            marginBottom: 10,
            fontSize: '0.9375rem',
            fontFamily: 'DM Sans Bold',
          }}
        >
          {t('bookingPage.your_booking_includes')}
        </div>
        <S.MoreInfoWrapper>
          <S.MoreInfoImage src={CancelCheckIn} />
          <div>{t('bookingPage.pickup_anytime')}</div>
        </S.MoreInfoWrapper>
        <S.MoreInfoWrapper>
          <S.MoreInfoImage src={CancelCheckIn} />
          <div>{t('bookingPage.free_cancellation')}</div>
        </S.MoreInfoWrapper>
        <S.MoreInfoWrapper>
          <S.MoreInfoImage src={ChatSupport} />
          <div>{t('bookingPage.customer_support')}</div>
        </S.MoreInfoWrapper>
        <S.MoreInfoWrapper style={{ marginBottom: 20 }}>
          <S.MoreInfoImage src={AlertTriangle} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{t('bookingPage.for_safety')}</div>
            <div>{t('bookingPage.refuse_items')}</div>
          </div>
        </S.MoreInfoWrapper>
      </S.MoreInfoContainer>
    </S.BigContainer>
  );
};

export default BookingDate;
