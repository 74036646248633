import { useCallback, useState } from 'react';
import DaumPostcode from 'react-daum-postcode';
import {
  SectionContainer,
  SectionInput,
  SectionTitle,
} from '../../assets/css/RegisterCommonStyles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { RegisterPlaceType } from '../../types/storageType';
import { storageRegistrationState } from '../../recoil/storageRecoil';
interface LanLngType {
  lat: number | null;
  lng: number | null;
}
const RegisterAddr = () => {
  const [isAddrModalOpen, setIsAddrModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [storageRegistration, setStorageRegistration] =
    useRecoilState<RegisterPlaceType>(storageRegistrationState);
  const themeObj = {
    bgColor: '#F6F6F6', //바탕 배경색
    searchBgColor: '#F6F6F6', //검색창 배경색
    contentBgColor: '#F6F6F6', //본문 배경색(검색결과,결과없음,첫화면,검색서제스트)
    pageBgColor: '#F6F6F6', //페이지 배경색
    textColor: '#444444', //기본 글자색
    queryTextColor: '#222222', //검색창 글자색
    postcodeTextColor: '#F74726', //우편번호 글자색
    emphTextColor: '#0094ff', //강조 글자색
    outlineColor: '#F6F6F6', //테두리
    // outerWidth: '100%',
  };
  const handleSearchComplete = async (data: any) => {
    const { jibunAddress, roadAddress, address, autoJibunAddress } = data;
    const userSearched = address.split(' ', 1);
    if (userSearched[0] !== '서울') {
      alert('현재는 서울지역만 서비스하고 있어요 😢');
      setIsAddrModalOpen(false);
    } else {
      const location = await onSearchLocation(roadAddress);
      setStorageRegistration({
        ...storageRegistration,
        addr: jibunAddress || autoJibunAddress,
        roadAddr: roadAddress,
        lat: location.lat,
        lng: location.lng,
      });
      navigate('/host/register-storage?step=detailAddr', {
        state: { jibunAddress, roadAddress, location },
      });
    }
  };
  const onSearchLocation = async (roadAddress: string) => {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      roadAddress,
    )}&key=${apiKey}&language=ko`;

    try {
      const response = await axios.get(url);
      if (response.data.results.length > 0) {
        console.log(response.data, 'google api search data');
        const { lat, lng } = response.data.results[0].geometry.location;
        return { lat, lng };
      } else {
        alert('No results found');
        return { lat: null, lng: null };
      }
    } catch (error) {
      console.error(error);
      return { lat: null, lng: null };
    }
  };
  return (
    <>
      {isAddrModalOpen ? (
        <div
          style={{ marginTop: 40, position: 'absolute', top: -25, bottom: 0, left: 0, right: 0 }}
        >
          <DaumPostcode
            theme={themeObj}
            style={{ height: 'calc(100% - 70px)' }}
            onClose={() => setIsAddrModalOpen(false)}
            onComplete={(data) => handleSearchComplete(data)}
          />
        </div>
      ) : (
        <SectionContainer>
          <SectionTitle>업체의 주소를 알려주세요</SectionTitle>

          <SectionInput
            style={{ marginTop: 70 }}
            placeholder="지번, 도로명, 건물명으로 검색"
            readOnly
            // value={address}
            onClick={() => setIsAddrModalOpen(true)}
          />
        </SectionContainer>
      )}
    </>
  );
};

export default RegisterAddr;
