import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { RegisterPlaceType } from '../../types/storageType';
import { registrationValidState, storageRegistrationState } from '../../recoil/storageRecoil';
import { useFormContext } from 'react-hook-form';
import {
  ContentContainer,
  ContentTitle,
  ErrorMessage,
  FlexBox,
  SectionInput,
  SectionContainer,
  SectionTitle,
} from '../../assets/css/RegisterCommonStyles';

const RegisterOwner = () => {
  const {
    register,
    watch,
    formState: { isValid, errors },
  } = useFormContext();
  const [storageRegistration, setStorageRegistration] =
    useRecoilState<RegisterPlaceType>(storageRegistrationState);
  const setIsStepValid = useSetRecoilState(registrationValidState);
  const hostName = watch('hostName');
  const email = watch('email');
  const hostContact = watch('hostContact');
  const validateHostName = (value: string) => {
    if (!value) return '관리자 이름을 입력해주세요';
    if (!/^[A-za-z0-9가-힣]{2,}$/.test(value)) return '2글자 이상 적어주세요.';
    return true;
  };
  const validateEmail = (value: string) => {
    if (!value) return '이메일을 입력해주세요';
    if (!/\S+@\S+\.\S+/.test(value)) return '올바른 이메일 형식이 아닙니다.';
    return true;
  };
  const validateHostContact = (value: string) => {
    if (!value) return '연락처를 입력해주세요';
    if (!/^(\d{2,3})-?(\d{3,4})-?(\d{4})$/.test(value)) return '올바른 전화번호 형식이 아닙니다.';
    return true;
  };

  useEffect(() => {
    if (isValid) {
      setIsStepValid((prev) => ({
        ...prev,
        owner: true,
      }));
      setStorageRegistration({
        ...storageRegistration,
        owner: {
          name: hostName,
          email: email,
          contact: hostContact,
        },
      });
    } else {
      setIsStepValid((prev) => ({
        ...prev,
        owner: false,
      }));
    }
  }, [hostName, email, hostContact, isValid]);

  return (
    <SectionContainer>
      <SectionTitle>호스트에 대한 정보를 알려주세요</SectionTitle>
      <ContentContainer>
        <FlexBox>
          <ContentTitle htmlFor="hostName">관리자 이름</ContentTitle>
          {errors.hostName?.message && (
            <ErrorMessage role="alert">{errors?.hostName?.message.toString()}</ErrorMessage>
          )}
        </FlexBox>

        <SectionInput
          placeholder="관리자 이름"
          id="hostName"
          {...register('hostName', {
            validate: validateHostName,
          })}
          style={{
            borderColor: errors?.hostName?.message ? '#f74726' : hostName ? '#0094ff' : '#ffffff',
          }}
        />
        <FlexBox>
          <ContentTitle htmlFor="email">이메일</ContentTitle>
          {errors.email?.message && (
            <ErrorMessage role="alert">{errors?.email?.message.toString()}</ErrorMessage>
          )}
        </FlexBox>

        <SectionInput
          placeholder="lugez@gmail.com"
          id="email"
          {...register('email', {
            validate: validateEmail,
          })}
          style={{
            borderColor: errors?.email?.message ? '#f74726' : email ? '#0094ff' : '#ffffff',
          }}
        />
        <FlexBox>
          <ContentTitle htmlFor="hostContact">연락처</ContentTitle>
          {errors.hostContact?.message && (
            <ErrorMessage role="alert">{errors?.hostContact?.message.toString()}</ErrorMessage>
          )}
        </FlexBox>

        <SectionInput
          placeholder="전화번호"
          id="hostContact"
          {...register('hostContact', {
            validate: validateHostContact,
          })}
          style={{
            borderColor: errors?.hostContact?.message
              ? '#f74726'
              : hostContact
              ? '#0094ff'
              : '#ffffff',
          }}
        />
      </ContentContainer>
    </SectionContainer>
  );
};

export default RegisterOwner;
