import styled from '@emotion/styled';
import { ChangeEventHandler } from 'react';
interface UserInputType {
  placeholder: string;
  inputValue: string;
  handleChange: ChangeEventHandler<HTMLInputElement>;
}
const UserInput = ({ placeholder, inputValue, handleChange }: UserInputType) => {
  return <Input placeholder={placeholder} value={inputValue} onChange={handleChange} />;
};
const Input = styled.input`
  all: unset;
  border-bottom: 2px solid #000000;
  margin-bottom: 20px;
  padding: 7px 0;
  ::placeholder {
    font-size: 1.25rem;
    color: #000000;
    font-family: 'DM Sans Bold';
  }
`;
export default UserInput;
