import { useRecoilState } from 'recoil';
import XLayout from '../../components/common/XLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import UserInput from '../../components/common/UserInput';
import { authIntentState } from '../../recoil/authIntentAtom';
import { authenticatedState } from '../../recoil/authenticatedAtom';

const SignUpPage = () => {
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authenticatedState);
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  const { previousPath, intent } = authIntent;
  const navigate = useNavigate();
  const location = useLocation();
  const { placeId } = location.state || {};
  const handleClickXButton = () => {
    navigate(previousPath);
  };

  const handleChangeLogin = () => {
    setAuthIntent({ ...authIntent, authMode: 'login' });
    navigate('/log-in', { state: { placeId } });
  };
  const successSignUpTest = () => {
    // 로그인 성공 시 해야할 로직
    setIsAuthenticated(true);
    if (intent === 'user') {
      navigate('/main');
    } else if (intent === 'host') {
      navigate('/host');
    } else if (intent === 'booking') {
      navigate(`/booking/${placeId}?step=date-selection`);
    }

    // 서버 오류 혹은 어떠한 오류로 실패 시 - 아마 api 요청에서의 try catch
    // 페이지 이동시키지 않고 회원가입/로그인 실패에 대한 문구를 잘 전달해줘야 함
  };
  return (
    <XLayout handleClickXButton={handleClickXButton}>
      <Container>
        <Form>
          {/* <UserInput placeholder="name" />
          <UserInput placeholder="email" />
          <UserInput placeholder="password" />
          <UserInput placeholder="confirm password" /> */}
          <SignUpButton onClick={successSignUpTest}>SIGN UP</SignUpButton>
        </Form>
        <Message>Do you have an account?</Message>
        <SignUp onClick={handleChangeLogin}>Log In!</SignUp>
      </Container>
    </XLayout>
  );
};
const Container = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 28px;
`;
const SignUpButton = styled.div`
  background-color: #000000;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 5px 0;
  font-size: 1.875rem;
  font-family: 'DM Sans Bold';
  cursor: pointer;
`;
const Message = styled.div`
  font-size: 0.875rem;
  font-family: 'DM Sans Medium';
  margin-bottom: 20px;
`;
const SignUp = styled.div`
  text-decoration: underline;
  color: #0094ff;
  font-size: 1.125rem;
  font-family: 'DM Sans Bold';
  cursor: pointer;
`;
export default SignUpPage;
