import styled from '@emotion/styled';
import LugezLogo from '../../assets/images/LugezLogoGrey.png';
import { useTranslation } from 'react-i18next';

const PersonalInfoRequiredDocument = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Title style={{ fontSize: '0.875rem' }}>{t('privacy_policy.title')}</Title>
        <SubTitle>{t('privacy_policy.sections.purpose')}</SubTitle>
        <div>{t('privacy_policy.sections.purpose_detail')}</div>
        <div>{t('privacy_policy.sections.name')}</div>
        <div>{t('privacy_policy.sections.email')}</div>
        <div>{t('privacy_policy.sections.phone')}</div>
        <SubTitle>{t('privacy_policy.sections.items')}</SubTitle>
        <div>{t('privacy_policy.sections.items_mandatory')}</div>
        <div>{t('privacy_policy.sections.items_optional')}</div>
        <SubTitle>{t('privacy_policy.sections.retention')}</SubTitle>
        <div>{t('privacy_policy.sections.retention_detail')}</div>
        <SubTitle>{t('privacy_policy.sections.third_party')}</SubTitle>
        <div>{t('privacy_policy.sections.third_party_detail')}</div>
        <div>{t('privacy_policy.sections.third_party_consent')}</div>
        <div>{t('privacy_policy.sections.third_party_law')}</div>
        <SubTitle>{t('privacy_policy.sections.security')}</SubTitle>
        <div>{t('privacy_policy.sections.security_detail')}</div>
        <div>{t('privacy_policy.sections.encryption')}</div>
        <div>{t('privacy_policy.sections.tech_measures')}</div>
        <div>{t('privacy_policy.sections.staff_training')}</div>
        <SubTitle>{t('privacy_policy.sections.contact')}</SubTitle>
        <div>{t('privacy_policy.sections.contact_detail')}</div>
        <div>{t('privacy_policy.sections.officer')}</div>
        <div>{t('privacy_policy.sections.officer_contact')}</div>
        <SubTitle>{t('privacy_policy.sections.rights')}</SubTitle>
        <div>{t('privacy_policy.sections.rights_detail')}</div>
      </Container>
    </>
  );
};
const SubTitle = styled.div`
  margin-top: 0.75rem;
`;
const Title = styled.div`
  font-family: 'DM Sans ExtraBold';
  margin-bottom: 0.75rem;
  font-size: 0.875rem;
`;
const Container = styled.div`
  & > div {
    font-size: 0.75rem;
  }
`;
const Logo = styled.img`
  margin-top: 60px;
  margin-bottom: 50px;
  width: auto;
  height: 2.5rem;
`;
export default PersonalInfoRequiredDocument;
