import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const TermsDocument = () => {
  const { t } = useTranslation();
  return (
    <Container>
      {/* general */}
      <Title>**{t('terms.general.title')}**</Title>
      <div>{t('terms.general.content1')}</div>
      <div>{t('terms.general.content2')}</div>
      <div>{t('terms.general.content3')}</div>
      {/* membership */}
      <Title>**{t('terms.membership.title')}**</Title>
      <div>{t('terms.membership.content1')}</div>
      <div>{t('terms.membership.content2')}</div>
      <div>{t('terms.membership.content3')}</div>
      <div>{t('terms.membership.content4')}</div>
      {/* serviceUsage */}
      <Title>**{t('terms.serviceUsage.title')}**</Title>
      <div>{t('terms.serviceUsage.content1')}</div>
      <div>{t('terms.serviceUsage.content2')}</div>
      <div>{t('terms.serviceUsage.content3')}</div>
      <div>{t('terms.serviceUsage.content4')}</div>
      {/* liability */}
      <Title>**{t('terms.liability.title')}**</Title>
      <div>{t('terms.liability.content1')}</div>
      <div>{t('terms.liability.content2')}</div>
      <div>{t('terms.liability.content3')}</div>
      {/* miscellaneous */}
      <Title>**{t('terms.miscellaneous.title')}**</Title>
      <div>{t('terms.miscellaneous.content1')}</div>
      <div>{t('terms.miscellaneous.content2')}</div>
      <div>{t('terms.miscellaneous.content3')}</div>
    </Container>
  );
};
const Container = styled.div`
  padding-left: 15px;
  padding-right: 10px;
  font-size: 0.875rem;
`;
const Title = styled.div`
  margin-top: 10px;
`;
export default TermsDocument;
