import { PersonalInfoType } from '../recoil/personalInfo';
import { ApiResponseType } from './adminApi';
import apiClient from './utils/apiClient';
import authApiClient from './utils/authApiClient';

export const kakaoLoginApi = async (code: string) => {
  const response = await apiClient.post('users/auth/kakao/callback', { code });
  return response;
};
export const googleLoginApi = async (code: string) => {
  const response = await apiClient.post('/login/oauth2/code/google', { code });
  return response;
};
export const naverLoginApi = async (code: string) => {
  const response = await apiClient.get('users/auth/naver/callback', {
    params: {
      code,
    },
  });
  return response;
};

export const postPersonalInfoApi = async (
  formData: PersonalInfoType,
): Promise<ApiResponseType<any>> => {
  const response = await authApiClient.put('/members/me/contact', formData);
  return response.data;
};

export interface GetPersonalInfoType {
  name: string;
  nickname: string;
  email: string;
  phoneNumber: string;
  pictureUrl: string;
}
export const getPersonalInfoApi = async (): Promise<ApiResponseType<GetPersonalInfoType>> => {
  const response = await authApiClient.get('/members/me');
  return response.data;
};
