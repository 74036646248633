import { ToggleContainer } from '../../assets/css/RegisterCommonStyles';

interface ToggleButtonType {
  toggleHandler: () => void;
  isOn: boolean;
}
const ToggleButton = ({ toggleHandler, isOn }: ToggleButtonType) => {
  return (
    <ToggleContainer onClick={toggleHandler}>
      <div className={`toggle-container ${isOn ? 'toggle--checked' : null}`} />
      <div className={`toggle-circle ${isOn ? 'toggle--checked' : null}`} />
    </ToggleContainer>
  );
};
export default ToggleButton;
