import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export interface AuthIntentStateType {
  authMode: 'login' | 'signUp';
  intent:
    | 'user'
    | 'host'
    | 'booking'
    | 'admin'
    | 'tickets_all'
    | 'tickets_by_place'
    | 'ticket_detail';
  previousPath: string;
  intentPath: string;
}

const { persistAtom } = recoilPersist({
  key: 'authIntent',
  storage: localStorage,
});
export const authIntentState = atom<AuthIntentStateType>({
  key: 'authIntentState',
  default: {
    authMode: 'login', // signup or login
    intent: 'user', // user or host or booking
    previousPath: '/main', // 버튼 누르기 전 url
    intentPath: '/main',
  },
  effects_UNSTABLE: [persistAtom],
});
