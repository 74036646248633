import React from 'react';
import styled from '@emotion/styled';
import { AdminPlaceDataType } from '../../types/AdminPlacesType';
import { GetPlaceType } from '../../types/storageType';
import useAxiosWithAuth from '../../apis/utils/useAxiosWithAuth';
import {
  ApiResponseType,
  DrawOrApproveData,
  deletePartnershipApi,
  patchWithApproveApi,
  patchWithDrawApi,
} from '../../apis/adminApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
interface AdminPlaceItemTypes {
  item: AdminPlaceDataType;
  isApproved: boolean;
  handleGetPlacePreview: () => void;
  previewData: GetPlaceType | undefined;
  page: number;
}

const AdminPlaceItem = ({
  item,
  isApproved,
  handleGetPlacePreview,
  previewData,
  page,
}: AdminPlaceItemTypes) => {
  const authApiClient = useAxiosWithAuth();
  const { reset } = useForm();
  const queryClient = useQueryClient();
  // 파트너 보관소 승인/철회 api patch
  // 보관소 get 요청 시에 approved 여부를 다르게 하여 부르기 때문에
  // '파트너 요청' 클릭 시 해제되어 있는(혹은 대기중인) 데이터를,
  // '파트너' 클릭 시 승인되어 있는 데이터를 불러와서 화면에 그린다.

  const drawMutation = useMutation<ApiResponseType<DrawOrApproveData>, Error, number>({
    mutationFn: (placeId: number) => patchWithDrawApi(placeId),
    onSuccess: (data: ApiResponseType<DrawOrApproveData>) => {
      reset();
      queryClient.refetchQueries({ queryKey: ['partnership', isApproved, page] });
    },
  });
  const approveMutation = useMutation<ApiResponseType<DrawOrApproveData>, Error, number>({
    mutationFn: (placeId: number) => patchWithApproveApi(placeId),
    onSuccess: (data: ApiResponseType<DrawOrApproveData>) => {
      reset();
      queryClient.refetchQueries({ queryKey: ['partnership', isApproved, page] });
    },
  });
  const handleApproved = async () => {
    if (isApproved && item) {
      // const response = await patchWithDrawApi(item.id);
      // console.log(response);
      drawMutation.mutate(item.id);
    } else if (!isApproved && item) {
      // const response = await patchWithApproveApi(item.id);
      // console.log(response);
      approveMutation.mutate(item.id);
    }
  };
  const deletePartnershipMutation = useMutation<ApiResponseType<{}>, Error, number>({
    mutationFn: (placeId: number) => deletePartnershipApi(placeId),
    onSuccess: (data: ApiResponseType<{}>) => {
      reset();
      queryClient.refetchQueries({ queryKey: ['partnership', isApproved, page] });
    },
  });
  const handleDeletePartnership = () => {
    if (item) {
      deletePartnershipMutation.mutate(item.id);
    }
  };
  return (
    <>
      <ListWrapper
        style={{ backgroundColor: previewData?.placeId === item.id ? '#C7CCD2' : '#dddedf' }}
      >
        <td>{item.id}</td>
        <td>{item.type}</td>
        <td>{item.name}</td>
        <td>{item.owner}</td>
        <td>{new Date(item.requestedAt).toLocaleString()}</td>
        <td
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={handleGetPlacePreview}
        >
          더보기
        </td>
        <td>
          <div
            style={{ cursor: 'pointer', padding: 5, color: '#ffffff', backgroundColor: '#1B303F' }}
            onClick={handleApproved}
          >
            {isApproved ? '제휴해제' : '승인'}
          </div>
        </td>
        <td>
          <div style={{ cursor: 'pointer' }} onClick={handleDeletePartnership}>
            파트너 삭제
          </div>
        </td>
      </ListWrapper>
      {previewData && previewData.placeId === item.id && (
        <tr>
          <td colSpan={7}>
            <AddInfoWrapper>
              <div>수량 : {previewData?.capacity}</div>
              <div>이름 : {previewData?.owner.name}</div>
              <div>이메일 : {previewData?.owner.email}</div>
              <div>연락처 : {previewData?.owner.contact}</div>
            </AddInfoWrapper>
          </td>
        </tr>
      )}
    </>
  );
};
const AddInfoWrapper = styled.div`
  display: flex;
  border: 1px solid #1b303f;
  padding: 20px 0;
  padding-left: 20px;
  & > div {
    margin-right: 40px;
  }
`;
const ListWrapper = styled.tr`
  /* display: flex; */
  width: 100%;
  background-color: #dddedf;
  color: #1b303f;
  margin-bottom: 10px;

  & > td {
    padding: 8px;
    text-align: center;
  }
`;
export default AdminPlaceItem;
