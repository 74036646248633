import { GetPlaceType } from '../types/storageType';
import { RegisterPlaceType } from '../types/storageType';
import { ApiResponseType } from './adminApi';
import apiClient from './utils/apiClient';
import authApiClient from './utils/authApiClient';

export interface GetPresignedUrlType {
  status: 'string';
  data: {
    key: 'string';
    presignedUrl: 'string';
  };
}
export const getPresignedUrlApi = async (ext: string) => {
  const response: ApiResponseType<GetPresignedUrlType> = await authApiClient.get(
    '/files/uploadUrl',
    {
      params: {
        ext,
      },
    },
  );
  return response.data;
};

export interface GetPlacesParamsType {
  lat: number;
  lng: number;
  zoomLevel: number;
}
export const getPlacesApi = async (params: GetPlacesParamsType) => {
  const response = await apiClient.get('/places/nearby', {
    params: params,
  });
  return response.data;
};
export const getPlaceById = async (placeId: number): Promise<ApiResponseType<GetPlaceType>> => {
  const response = await apiClient.get(`/places/${placeId}`);
  return response.data;
};
export interface PostPlaceResponseType {
  placeId: number;
}
export const postPlaceApi = async (
  registerData: RegisterPlaceType,
): Promise<ApiResponseType<PostPlaceResponseType>> => {
  const response = await authApiClient.post('/places', registerData);
  return response.data;
};

export const getPlacesByHostApi = async (): Promise<ApiResponseType<GetPlaceType>> => {
  const response = await authApiClient.get('/places/me');
  return response.data;
};

export interface GetBookingsByPlaceParamsType {
  status: 'CANCELLED' | 'WAIT' | 'IN_PROGRESS' | 'COMPLETED';
  page?: number;
  size?: number;
  sort?: string[];
}
export const getBookingsByPlaceApi = async (
  placeId: number | undefined,
  params: GetBookingsByPlaceParamsType,
) => {
  if (!placeId) return;
  const response = await authApiClient.get(`/places/${placeId}/bookings`, {
    params,
  });
  return response.data;
};
export interface StatusResponseDataType {
  enabled: boolean;
}
export const patchPlaceStatusApi = async (
  placeId: number,
): Promise<ApiResponseType<StatusResponseDataType>> => {
  const response = await authApiClient.patch(`/places/${placeId}/status`);
  return response.data;
};

export interface GetRemainingCapacityParamsType {
  dropOffAt: string;
  pickUpAt: string;
}
export const getRemainingCapacityApi = async (
  placeId: number,
  params: GetRemainingCapacityParamsType,
) => {
  const response = await authApiClient.get(`/places/${placeId}/remaining-capacity`, {
    params,
  });
  return response.data;
};
