export const maskLastFourDigits = (phone: string) => {
  let maskedPhone = '';
  let digitCount = 0;

  // 역순으로 문자열을 순회하면서 숫자만 4개를 *로 바꿈
  for (let i = phone.length - 1; i >= 0; i--) {
    if (digitCount < 4 && /\d/.test(phone[i])) {
      maskedPhone = '*' + maskedPhone;
      digitCount++;
    } else {
      maskedPhone = phone[i] + maskedPhone;
    }
  }

  return maskedPhone;
};
export const splitFullNumber = (str: string) => {
  const firstSpaceIndex = str.indexOf(' ');

  if (firstSpaceIndex === -1) {
    // 공백이 없는 경우 원래 문자열을 반환
    return str;
  }

  const left = str.substring(0, firstSpaceIndex);
  const right = str.substring(firstSpaceIndex + 1);

  return right;
};
export const formatNumberWithDash = (number: string, format: string) => {
  let formattedNumber = '';
  let numberIndex = 0;

  for (let i = 0; i < format.length; i++) {
    if (format[i] === ' ') {
      formattedNumber += '-';
    } else if (format[i] === '.') {
      if (numberIndex < number.length) {
        formattedNumber += number[numberIndex];
        numberIndex++;
      }
    } else {
      formattedNumber += format[i];
    }
  }
  return formattedNumber;
};
export const formatNumberWithSpace = (number: string, format: string) => {
  let formattedNumber = '';
  let numberIndex = 0;

  for (let i = 0; i < format.length; i++) {
    if (format[i] === ' ') {
      formattedNumber += ' ';
    } else if (format[i] === '-') {
      formattedNumber += '-';
    } else if (format[i] === '.') {
      if (numberIndex < number.length) {
        formattedNumber += number[numberIndex];
        numberIndex++;
      }
    } else {
      formattedNumber += format[i];
    }
  }
  return formattedNumber;
};
