import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RecoilRoot } from 'recoil';
import '../src/locale/i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { hotjar } from 'react-hotjar';

// Hotjar 설정 (Hotjar ID와 스니펫 버전 설정)
// hotjar.initialize({ id: 5036453, sv: 6 });
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
