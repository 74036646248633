import { atom } from 'recoil';
// 호스트 보관소 등록 시 영업시간 state

export interface EachDayType {
  [key: string]: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export const defaultEachDayOn: EachDayType = {
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
};
export const businessHoursState = atom({
  key: 'businessHoursState',
  default: {
    isAllDayOn: false,
    isEachDayOn: defaultEachDayOn,
  },
});
