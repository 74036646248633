import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;
// const baseURL = 'https://dev-api.jimventory.com';
const apiClient = axios.create({
  baseURL: baseURL,
  withCredentials: true, // 이 옵션을 추가하여 자격 증명 포함
});

export default apiClient;
