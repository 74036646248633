import styled from '@emotion/styled';
import { BeatLoader } from 'react-spinners';

const Spinner = () => {
  return (
    <SpinnerWrapper>
      <BeatLoader color="#0094ff" margin={5} size={15} />
    </SpinnerWrapper>
  );
};
const SpinnerWrapper = styled.div`
  /* @media only screen and (max-width: 600px) {
    width: 100vw;
    height: 100vh;
  }
  @media only screen and (min-width: 601px) {
    width: 100%;
    height: 100%;
  } */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export default Spinner;
