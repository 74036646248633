import styled from '@emotion/styled';
import { SectionTitle, SectionContainer } from '../../assets/css/RegisterCommonStyles';
import { MouseEventHandler, useEffect, useState } from 'react';
import { RegisterPlaceType } from '../../types/storageType';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { registrationValidState, storageRegistrationState } from '../../recoil/storageRecoil';
import StairUp from '../../assets/images/StairUp.png';
import StairDown from '../../assets/images/StairDown.png';

const RegisterFacilities = () => {
  const setIsStepValid = useSetRecoilState(registrationValidState);

  const [storageRegistration, setStorageRegistration] =
    useRecoilState<RegisterPlaceType>(storageRegistrationState);
  const { facilities } = storageRegistration;

  // facilities 배열에 F를 포함하는 item이 있는지 검사
  const isIncludesCheck = (array: string[]): boolean => {
    return array.some((item) => item.includes('F'));
  };
  const [stairs, setStairs] = useState<number>(
    facilities
      ? isIncludesCheck(facilities)
        ? Number(facilities.filter((it) => it.includes('F'))[0]?.slice(0, -1))
        : 1
      : 1,
  );
  const facilityContents = [
    '휠체어 프렌들리',
    '엘리베이터 없음',
    '화장실',
    '엘리베이터',
    '와이파이',
  ];
  function renderFacilityName(name: string) {
    // `\n`을 기준으로 문자열을 분리하고, 각각의 부분을 `<div>`로 감싸서 반환
    return name.split(' ').map((part, index) => <div key={index}>{part}</div>);
  }

  // facilities 배열 업데이트
  const handleClickFacilities = (facility: string) => {
    // 기존 facilities 배열을 가져와서
    const updatedFacilities = facilities || [];
    if (
      (facility === '엘리베이터' && updatedFacilities.includes('엘리베이터 없음')) ||
      (facility === '엘리베이터 없음' && updatedFacilities.includes('엘리베이터'))
    )
      return;

    // 이미 선택된 facility가 있는지 검사하고,
    // 있다면 제거하고, 없다면 추가한다.
    if (updatedFacilities.includes(facility)) {
      setStorageRegistration({
        ...storageRegistration,
        facilities: updatedFacilities.filter((f) => f !== facility),
      }); // updatedFacilities에서 선택된 facility랑 다른것만 filter -> 제거
    } else {
      setStorageRegistration({
        ...storageRegistration,
        facilities: [...updatedFacilities, facility],
      }); // 기존의 배열 + 새로 선택한 facility
    }
  };

  // stairs 업데이트 그리고 배열에 추가하는 로직
  const handleUpdateStair = (operation: 'up' | 'down') => {
    const updatedFacilities = facilities || [];

    let adjustment = operation === 'up' ? 1 : -1;

    if ((stairs === -1 && operation === 'up') || (stairs === 1 && operation === 'down')) {
      adjustment *= 2;
    }

    if (updatedFacilities && isIncludesCheck(updatedFacilities)) {
      // 배열에 있을 때
      const newFacilities = updatedFacilities.filter((f) => !f.includes('F'));
      setStorageRegistration({
        ...storageRegistration,
        facilities: [...newFacilities, `${(stairs + adjustment).toString()}F`],
      });
      setStairs((prev) => prev + adjustment);
    } else {
      // 배열에 없을 때
      setStorageRegistration({
        ...storageRegistration,
        facilities: [...updatedFacilities, `${(stairs + adjustment).toString()}F`],
      });
      setStairs((prev) => prev + adjustment);
    }
  };
  const handleUpStair: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    handleUpdateStair('up');
  };
  const handleDownStair: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    handleUpdateStair('down');
  };

  useEffect(() => {
    if (facilities) {
      setIsStepValid((prev) => ({
        ...prev,
        facilities: facilities.length > 0 && isIncludesCheck(facilities),
      }));
    } else {
      setIsStepValid((prev) => ({
        ...prev,
        facilities: false,
      }));
    }
  }, [facilities]);
  return (
    <SectionContainer>
      <SectionTitle>편의시설 정보를 추가해주세요</SectionTitle>
      <ContentContainer>
        <StairText
          style={{ color: facilities && isIncludesCheck(facilities) ? '#0094ff' : '#f74726' }}
        >
          층수를 입력해주세요(필수)
        </StairText>
        <FlexContainer>
          <FlexItem
            className={facilities && isIncludesCheck(facilities) ? 'clicked' : ''}
            onClick={() => handleClickFacilities(`${stairs.toString()}F`)}
          >
            <FlexItemDiv id="stair">
              <PolygonDiv onClick={handleDownStair}>
                <Polygons src={StairDown} />
              </PolygonDiv>
              <div>
                {facilities && isIncludesCheck(facilities)
                  ? facilities.filter((it) => it.includes('F'))[0]
                  : `${stairs.toString()}F`}
              </div>
              <PolygonDiv onClick={handleUpStair}>
                <Polygons src={StairUp} />
              </PolygonDiv>
            </FlexItemDiv>
          </FlexItem>
          {facilityContents.map((item, index) => (
            <FlexItem
              className={facilities?.includes(item) ? 'clicked' : ''}
              onClick={() => handleClickFacilities(item)}
              key={index}
            >
              <FlexItemDiv>{renderFacilityName(item)}</FlexItemDiv>
            </FlexItem>
          ))}
        </FlexContainer>
        <Text>Facilities</Text>
        <RowArray>
          {facilities?.map(
            (item, index) => index < 3 && <FacilityCard key={index}>{item}</FacilityCard>,
          )}
        </RowArray>
        <RowArray>
          {facilities?.map(
            (item, index) => index >= 3 && <FacilityCard key={index}>{item}</FacilityCard>,
          )}
        </RowArray>
      </ContentContainer>
    </SectionContainer>
  );
};
const PolygonDiv = styled.div`
  position: relative;
  z-index: 2;
`;
const FacilityCard = styled.span`
  border: 1px solid black;
  border-radius: 4px;
  font-size: 0.75rem;
  font-family: 'DM Sans Regular';
  width: 6rem;
  text-align: center;
  margin-right: 4px;
`;
const RowArray = styled.div`
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 6px;
`;
const Text = styled.div`
  font-size: 0.9375rem;
  font-family: 'DM Sans SemiBold';
  color: #828282;
  padding-left: 10px;
  margin-bottom: 15px;
`;
const Polygons = styled.img`
  width: 1rem;
  height: auto;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 40px;
`;

const StairText = styled.div`
  font-family: 'DM Sans Light';
  font-size: 0.75rem;
  padding: 0 5px;
`;
// const GridItem = styled.div`
//   display: flex;
//   flex-direction: column;
//   border: 2px solid #ffffff;
//   border-radius: 10px;
//   height: 100%;
//   aspect-ratio: 1; // 정사각형으로 만들어줌!!!
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   font-size: 1.125rem;
//   font-family: 'DM Sans Bold';

//   &.clicked {
//     border: 2px solid #0094ff;
//   }
//   &#stair {
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     padding: 0 10px;
//   }
// `;
const FlexItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffffff;
  border-radius: 10px;
  flex: 1 1 30%; /* 30% 너비, grow와 shrink 설정 */
  margin: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'DM Sans Bold';
  display: flex;
  justify-content: center;
  align-items: center;
  /* aspect-ratio: 1; 정사각형 유지 */

  &::before {
    content: '';
    display: block;
    padding-top: 100%; /* 1:1 Aspect Ratio */
  }

  & > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &.clicked {
    border: 2px solid #0094ff;
  }
`;
const FlexItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &#stair {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }
`;
// const GridContainer = styled.div`
//   display: grid;
//   width: 100%; /* 컨테이너의 너비 설정 */
//   grid-template-columns: repeat(3, 1fr); /* 3개의 컬럼으로 나눔 */
//   grid-gap: 10px; /* 그리드 아이템 간의 간격 설정 */
//   /* margin: 70px 20px 0 20px; 페이지 중앙 정렬 */
//   margin-top: 10px;
//   margin-bottom: 35px;
//   padding-bottom: 35px;
//   border-bottom: 2px solid #c0afaf;
// `;
const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 35px;
  padding-bottom: 35px;
  border-bottom: 2px solid #c0afaf;
`;
export default RegisterFacilities;
