import styled from '@emotion/styled';
import LugezLogo from '../../assets/images/LugezLogo.png';
import ArrowToDetail from '../../assets/images/ClickToDetail.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { authIntentState } from '../../recoil/authIntentAtom';
import { useEffect } from 'react';
import { authenticatedState } from '../../recoil/authenticatedAtom';
const BookingSuccessPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authenticatedState);

  const handleGoDetail = () => {
    navigate(`/ticket/${id}`);
  };
  return (
    <Container>
      {/* <StarBackground src={Stars} /> */}
      <Logo src={LugezLogo} />
      <TextWrapper>
        <BlueText>{t('bookingPage.reservation_complete_1')}</BlueText>
        <BlueTextBottom>{t('bookingPage.reservation_complete_2')}</BlueTextBottom>
        <div style={{ marginBottom: 5 }}>{t('bookingPage.enjoy_hassle_free')}</div>
        {/* <div>hassle-free!</div> */}
      </TextWrapper>

      <DetailContainer onClick={handleGoDetail}>
        <DetailText>{t('bookingPage.go_to_reservation')}</DetailText>
        <ArrowImage src={ArrowToDetail} />
      </DetailContainer>
      {/* <div onClick={goToManagePage}>내 보관소 보러가기</div> */}
    </Container>
  );
};
const DetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  border: 2px solid #1b303f;
  padding: 3px 5px 3px 15px;
  border-radius: 28px;
  cursor: pointer;
  width: 100%;
`;
const ArrowImage = styled.img`
  width: 3.125rem;
  height: 3.125rem;
`;
const TextWrapper = styled.div`
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const StarBackground = styled.img`
  position: absolute;
  width: 90%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -10;
`;
const DetailText = styled.div`
  font-size: 1.125rem;
  color: #1b303f;
  margin-right: 5px;
  font-family: 'DM Sans Bold';
  cursor: pointer;
`;
const BlueText = styled.div`
  /* margin-top: 40px; */
  font-size: 2.75rem;
  color: #0094ff;
  font-family: 'DM Sans Bold';
  text-align: center;
`;
const BlueTextBottom = styled.div`
  font-size: 2.75rem;
  margin-bottom: 20px;
  color: #0094ff;
  font-family: 'DM Sans Bold';
  text-align: center;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
const Logo = styled.img`
  margin-top: 100px;
  width: auto;
  height: 2.5rem;
`;
export default BookingSuccessPage;
