export const parseOperatingTime = (operatingTime: string) => {
  const [start, end] = operatingTime.split(' ~ ');
  const [startHour, startMinute] = start.split(':').map(Number);
  let [endHour, endMinute] = end.split(':').map(Number);
  if (endHour === 23 && endMinute === 59) {
    endHour = 24;
    endMinute = 0;
  }
  return { startHour, startMinute, endHour, endMinute };
};

export const splitBusinessHour = (time: string | undefined) => {
  if (time) {
    return { startTime: time.split(' ~ ')[0], endTime: time.split(' ~ ')[1] };
  }
};
