import { useEffect, useState } from 'react';
import { BookingByPlaceDataType } from '../../types/getBookingsByStorageType';
import styled from '@emotion/styled';
import { v4 as uuidv4 } from 'uuid';
import ToggleDown from '../../assets/images/TimeToggleDown.png';
import ToggleUp from '../../assets/images/TimeToggleUp.png';
import { formatDateTime, formatTimeFromFullDateString } from '../../utils/isoToString';
import { useTranslation } from 'react-i18next';
import Call from '../../assets/images/InfoCall.png';
interface HostBookingItemType {
  item: BookingByPlaceDataType;
  bookingStatus: string;
}
const HostBookingItem = ({ item, bookingStatus }: HostBookingItemType) => {
  const [moreInfo, setMoreInfo] = useState<boolean>(false);
  const { t } = useTranslation();
  const { booking, booker } = item || {};
  useEffect(() => {
    console.log(bookingStatus);
  }, [bookingStatus]);
  return (
    <Container>
      {!moreInfo ? (
        <ItemBox key={uuidv4()}>
          <Flex style={{ width: '100%' }}>
            <div style={{ flex: 1 }}>
              {formatTimeFromFullDateString(booking.dropOffAt)} -{' '}
              {formatTimeFromFullDateString(booking.pickUpAt)}
            </div>
            <div style={{ flex: 1 }}>수량 : {booking.numOfBags}개</div>
            <div style={{ flex: 1 }}>{booker.name}</div>
          </Flex>

          <Toggle onClick={() => setMoreInfo(!moreInfo)} src={moreInfo ? ToggleUp : ToggleDown} />
        </ItemBox>
      ) : (
        <div style={{ padding: 20 }}>
          <Flex style={{ marginBottom: 10, justifyContent: 'space-between' }}>
            <Flex style={{ width: '100%' }}>
              <div style={{ marginRight: 24, flex: 1 }}>
                <GreyText>
                  {bookingStatus === 'IN_PROGRESS' ? '체크아웃 날짜' : '체크인 날짜'}
                </GreyText>
                <BlackText>
                  {bookingStatus === 'WAIT' || 'COMPLETED'
                    ? formatDateTime(booking.dropOffAt)
                    : formatDateTime(booking.pickUpAt)}
                </BlackText>
              </div>
              <div style={{ flex: 1 }}>
                <GreyText>짐 갯수</GreyText>
                <BlackText>{booking.numOfBags}</BlackText>
              </div>
            </Flex>

            <Toggle onClick={() => setMoreInfo(!moreInfo)} src={moreInfo ? ToggleUp : ToggleDown} />
          </Flex>
          <Flex style={{ marginBottom: 10, width: 'calc(100% - 1.125rem)' }}>
            <div style={{ marginRight: 24, flex: 1 }}>
              <GreyText>보관 시간</GreyText>
              <BlackText>
                {formatTimeFromFullDateString(booking.dropOffAt)} ~{' '}
                {formatTimeFromFullDateString(booking.pickUpAt)}
              </BlackText>
            </div>
            <div style={{ flex: 1 }}>
              <GreyText>가격</GreyText>
              <BlackText>{t('paymentAmount', { paymentAmount: booking.paymentAmount })}</BlackText>
            </div>
          </Flex>
          <Flex style={{ alignItems: 'center' }}>
            <CallImage src={Call} />
            <CallText>전화걸기</CallText>
          </Flex>
        </div>
      )}
    </Container>
  );
};
const Flex = styled.div`
  display: flex;
`;
const CallText = styled.span`
  font-size: 0.75rem;
  font-family: 'DM Sans Medium';
  text-decoration: underline;
`;
const CallImage = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 10px;
`;
const Toggle = styled.img`
  width: 1.125rem;
  height: 1.125rem;
`;
const Container = styled.div`
  border-bottom: 2px solid #e4e4e4;
  color: #1b303f;
  font-size: 0.875rem;
  font-family: 'DM Sans Medium';
  /* padding: 20px; */
`;

const ItemBox = styled.div`
  padding: 20px;
  border-bottom: 2px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const GreyText = styled.div`
  color: #675e5e;
  font-size: 0.625rem;
  font-family: 'DM Sans Regular';
`;
const BlackText = styled.div`
  color: #1b303f;
  font-size: 0.9375rem;
  font-family: 'DM Sans Regular';
  white-space: 0.5px;
`;

export default HostBookingItem;
