import styled from '@emotion/styled';
import MenuFooter from './MenuFooter';
import StartFooter from './StartFooter';
import RegisterFooter from './RegisterFooter';

interface FooterType {
  footer: string;
}
const Footer = ({ footer }: FooterType) => {
  return (
    <Container>
      {/* {footer === 'booking' && <BookingFooter />} */}
      {footer === 'menu' && <MenuFooter />}
      {footer === 'start' && <StartFooter />}
      {/* {footer === 'register' && <RegisterFooter />} */}
    </Container>
  );
};

const Container = styled.div`
  height: 70px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media only screen and (max-width: 600px) {
    position: fixed;
  }
`;
export default Footer;
