import styled from '@emotion/styled';
import Hamburger from '../../assets/images/Hamburger.png';
import { useRecoilState } from 'recoil';
import LugezLogo from '../../assets/images/LugezLogo.png';
import { modalState } from '../../recoil/modalAtom';
interface HeaderType {
  header: string;
}
const Header = ({ header }: HeaderType) => {
  const [modal, setModal] = useRecoilState(modalState);
  const handleMenuOpen = () => {
    setModal('menu');
  };
  return (
    <>
      {header === 'menu' && (
        <Container>
          <Menu onClick={handleMenuOpen}></Menu>
          <HeaderLogo src={LugezLogo}></HeaderLogo>
          <div style={{ width: '2.5rem', height: '2.5rem' }}></div>
        </Container>
      )}
      {header === 'admin' && <div>admin header</div>}
    </>
  );
};

const Container = styled.div`
  padding: 10px;
  padding-top: 20px;
  width: 100%;
  /* height: 70px; */
  border-bottom: 2px solid #000000;
  background-color: #f6f6f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Menu = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  background-image: url(${Hamburger});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
const HeaderLogo = styled.img`
  height: 1.875rem;
  width: auto;
`;
export default Header;
