import styled from '@emotion/styled';
import InfoCall from '../../assets/images/InfoCall.png';
import InfoTime from '../../assets/images/InfoTime.png';
import InfoCopy from '../../assets/images/InfoCopy.png';
import InfoLocation from '../../assets/images/InfoLocation.png';
import TimeToggleUp from '../../assets/images/TimeToggleUp.png';
import TimeToggleDown from '../../assets/images/TimeToggleDown.png';
import PrevButton from '../../assets/images/PrevButton.png';
import { useNavigate } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { GetPlaceType } from '../../types/storageType';
import { useEffect, useState } from 'react';
import { getTodayStringForShow } from '../../utils/dateFilter';
interface AdminPlacePreviewType {
  previewData: GetPlaceType;
}
const AdminPlacePreview = ({ previewData }: AdminPlacePreviewType) => {
  const { name, pictures, description, addr, roadAddr, facilities, contact, businessHours } =
    previewData;
  const [timeOpen, setTimeOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  return (
    <Container>
      <div>
        <Image src={pictures[0]?.url} />
      </div>
      <TitleLine>
        <TitleWrapper>
          <Title>{name}</Title>
          <StoreIntroduction>{description}</StoreIntroduction>
        </TitleWrapper>
      </TitleLine>
      <DetailInfoWrapper>
        <DetailInfo>
          <DetailIcon src={InfoLocation} />
          <div>{roadAddr}</div>
          <CopyToClipboard text={roadAddr} onCopy={() => alert('주소가 복사되었습니다')}>
            <img src={InfoCopy} style={{ width: '1.5rem', height: '1.5rem' }} />
            {/* <button></button> */}
          </CopyToClipboard>
        </DetailInfo>
        <DetailInfo>
          <DetailIcon src={InfoCall} />
          {/* <div style={{ textDecoration: 'underline', cursor: 'pointer' }}>{contact_info}</div> */}
          <a style={{ color: 'black' }} href={`tel:${contact}`}>
            {contact}
          </a>
        </DetailInfo>
        <DetailInfo>
          <DetailIcon src={InfoTime} />
          <div>
            {
              businessHours.filter((it) => it.dayOfWeek === getTodayStringForShow(businessHours))[0]
                .businessHour
            }
          </div>
          <ToggleDiv onClick={() => setTimeOpen(!timeOpen)}>
            <ToggleImg src={timeOpen ? TimeToggleUp : TimeToggleDown} />
          </ToggleDiv>
        </DetailInfo>
        {timeOpen &&
          businessHours.map((it, index) =>
            it.dayOfWeek === getTodayStringForShow(businessHours) ? (
              <BusinessHourWrapper
                style={{ color: '#0094FF', fontFamily: 'DM Sans Bold' }}
                key={index}
              >
                <div>{it.dayOfWeek}</div>
                <div>{it.closed ? 'CLOSE' : it.businessHour}</div>
              </BusinessHourWrapper>
            ) : (
              <BusinessHourWrapper key={index}>
                <div>{it.dayOfWeek}</div>
                <div>{it.closed ? 'CLOSE' : it.businessHour}</div>
              </BusinessHourWrapper>
            ),
          )}
        <FacilityWrapper>
          <Facility>Facility</Facility>
          {facilities.map((item, index) => {
            if (index === 2)
              return (
                <div key={index}>
                  <FacilityDetail style={{ backgroundColor: '#fff2a8' }}>{item}</FacilityDetail>
                </div>
              );
            return <FacilityDetail key={index}>{item}</FacilityDetail>;
          })}
        </FacilityWrapper>
        {/* <MenuWrapper>
          <Menu>Menu</Menu>
          <MenuCard />
          <MenuCard />
          <MenuCard />
        </MenuWrapper> */}
      </DetailInfoWrapper>
      <div style={{ width: '100%', height: 70 }}></div>
    </Container>
  );
};
const BusinessHourWrapper = styled.div`
  padding: 0 30px 18px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #bebebe;
  font-size: 0.875rem;
  font-family: 'DM Sans Regular';
`;
const ToggleDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
const ToggleImg = styled.img`
  width: 1.5rem;
  height: auto;
`;
const PrevButtonImg = styled.img`
  width: 3.125rem;
  height: auto;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
`;
const TitleLine = styled.div`
  width: 100%;
  height: 0;
  position: relative;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -55px;
  background-color: #ffffff;
  border: 2px solid #000000;
  margin: 0 20px;
  padding: 18px 30px;
  border-radius: 6px;
  background-color: #fcfafa;
  min-width: 320px;
  min-height: 140px;
`;

const Title = styled.div`
  font-size: 1.875rem;
  font-family: 'DM Sans Bold';
  margin-bottom: 10px;
`;
const StoreIntroduction = styled.div`
  font-size: 0.9375rem;
  font-family: 'DM Sans Regular';
`;

const DetailInfoWrapper = styled.div`
  margin: 120px 22px 0 22px;
`;
const DetailInfo = styled.div`
  border-top: 2px solid #000000;
  font-size: 0.9375rem;
  font-family: 'DM Sans Regular';
  padding: 12px 6px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;

  *:not(:last-child) {
    margin-right: 14px;
  }
`;
const FacilityWrapper = styled.div`
  border-top: 2px solid #000000;
  padding: 20px 6px;
`;
const Facility = styled.div`
  font-size: 0.9375rem;
  font-family: 'DM Sans SemiBold';
  color: #868686;
  margin-bottom: 15px;
`;
const FacilityDetail = styled.span`
  font-size: 0.75rem;
  font-family: 'DM Sans Regular';
  border-radius: 4px;
  border: 1px solid #000000;
  margin-right: 3px;
  padding: 0 4px;
`;

const MenuWrapper = styled.div`
  border-top: 2px solid #000000;
  padding-top: 22px;
`;
const Menu = styled.div`
  font-size: 0.9375rem;
  font-family: 'DM Sans SemiBold';
  color: #868686;
  margin-bottom: 19px;
  margin-left: 6px;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;

  /* Chrome, Safari */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  scrollbar-width: none;

  /* Internet Explorer */
  -ms-overflow-style: none;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const DetailIcon = styled.img`
  width: 1.875rem;
  height: 1.875rem;
`;
export default AdminPlacePreview;
