import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import LugezLogo from '../../assets/images/LugezLogo.png';
import SearchIcon from '../../assets/images/SearchIcon.png';
import AdminPlaceItem from '../components/AdminPlaceItem';
import { GetPlaceType } from '../../types/storageType';
import AdminPlacePreview from '../components/AdminPlacePreview';
import {
  ApiResponseType,
  GetPartnerShipParamsType,
  GetPartnershipData,
  getFromKeywordApi,
  getPartnershipApi,
  getPlacePreviewApi,
} from '../../apis/adminApi';
import { useQuery } from '@tanstack/react-query';

const AdminPlacesPage = () => {
  // const authApiClient = useAxiosWithAuth();
  // const [data, setData] = useState<AdminPlaceDataType[]>([]);
  // const [previewData, setPreviewData] = useState<GetPlaceType>();
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string | number>('');
  const [queryKeyword, setQueryKeyword] = useState<string | number>('');
  const [selectedId, setSelectedId] = useState<number | null>(null);
  // useEffect(() => {
  //   // isApproved를 파트너/파트너 요청 텍스트를 누를때마다 토글하여
  //   // 파트너 : 승인된, 파트너 요청 : 승인대기중 혹은 철회된
  //   // 데이터를 받아온다.\
  //   const getPartnership = async () => {
  //     const apiParams: GetPartnerShipType = {
  //       page: page,
  //       approved: isApproved,
  //       keyword: keyword && keyword,
  //     };
  //     try {
  //       const response = await getPartnershipApi(apiParams);
  //       setData(response.data.data.data);
  //       setTotalPages(response.data.data.totalPages);
  //     } catch (error) {
  //       console.error(error, 'get partnership error');
  //     }
  //   };
  //   getPartnership();
  // }, [page, isApproved]); // keyword를 의존성 배열에 추가하면 값이 바뀔때마다
  // 데이터가 정신없이 바뀌기 때문에 넣지 않음. 어차피 keyword 검색 함수로 가능함.

  const {
    data: getPartnershipData,
    isLoading,
    isError,
    refetch,
    error,
  } = useQuery({
    queryKey: ['partnership', isApproved, page, queryKeyword],
    queryFn: async (): Promise<ApiResponseType<GetPartnershipData>> => {
      const apiParams: GetPartnerShipParamsType = {
        page,
        approved: isApproved,
        keyword: queryKeyword,
      };
      return getPartnershipApi(apiParams);
    },
  });
  useEffect(() => {
    console.log(getPartnershipData);
  }, [getPartnershipData]);
  if (isError) {
    console.log(error, 'get partnership error');
  }
  const { data: previewData } = useQuery({
    queryKey: ['preview', selectedId],
    queryFn: async (): Promise<ApiResponseType<GetPlaceType>> =>
      getPlacePreviewApi(selectedId as number),

    enabled: !!selectedId, // selectedId가 있을 때만 쿼리 실행
    staleTime: Infinity, // 데이터를 무효화하지 않도록 설정
  });
  // v5에서 바뀐 형식으로 useQuery({ queryKey : , queryFn : , ...options})
  // => option를 객체안에서 풀어내어 사용한다

  // if (isLoading) {
  //   return <div>is loading....</div>;
  // }
  // if (isError) {
  //   return <div>is Error...</div>;
  // }
  const handlePageClick = (pageNumber: number) => {
    setPage(pageNumber);
  };
  const handleGetPlacePreview = (id: number) => {
    setSelectedId(id);
  };

  // const handleGetPlacePreview = async (id: number) => {
  //   try {
  //     const response = await getPlacePreviewApi(id);
  //     setPreviewData(response.data.data);
  //   } catch (error) {
  //     console.error(error, 'get place for preview error');
  //   }
  // };

  // const handleSearchKeyword = async () => {
  //   const apiParams: GetPartnerShipParamsType = {
  //     page: page,
  //     approved: isApproved,
  //     keyword: keyword && keyword,
  //   };
  //   try {
  //     const response = await getFromKeywordApi(apiParams);
  //     // setData(response.data.data);
  //     setTotalPages(response.data.totalPages);
  //   } catch (error) {
  //     console.error(error, 'get partnership error');
  //   }
  // };
  const handleSearchKeyword = () => {
    setQueryKeyword(keyword);
  };
  const handleChangeApproved = () => {
    setIsApproved(!isApproved);
    setPage(0);
  };

  return (
    <Container>
      {/* header */}
      <div>
        <img style={{ width: 'auto', height: '2.5rem' }} src={LugezLogo} />
      </div>
      {/* list + layout */}
      <ContentsWrapper>
        {/* list */}
        <div style={{ width: '100%', paddingRight: 70 }}>
          <SearchWrapper>
            <PartnerWrapper>
              <div
                style={{
                  marginRight: 34,
                  fontFamily: !isApproved ? 'DM Sans SemiBold' : 'DM Sans Regular',
                  textDecoration: !isApproved ? 'underline' : 'none',
                }}
                onClick={handleChangeApproved}
              >
                파트너 요청
              </div>
              <div
                style={{
                  textDecoration: isApproved ? 'underline' : 'none',
                  fontFamily: isApproved ? 'DM Sans SemiBold' : 'DM Sans Regular',
                }}
                onClick={handleChangeApproved}
              >
                파트너
              </div>
            </PartnerWrapper>
            <div style={{ position: 'relative' }}>
              <SearchInput
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="검색"
              ></SearchInput>
              <div
                onClick={handleSearchKeyword}
                style={{ position: 'absolute', right: 20, top: 15 }}
              >
                <img style={{ width: '1.25rem', height: 'auto' }} src={SearchIcon} />
              </div>
            </div>
          </SearchWrapper>

          <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0px 5px' }}>
            <thead>
              <TitleWrapper>
                <th>ID</th>
                <th>타입</th>
                <th style={{ fontSize: '1.25rem' }}>업체명</th>
                <th>호스트</th>
                <th>요청일자</th>
              </TitleWrapper>
            </thead>
            <tbody>
              {getPartnershipData?.data?.data?.map((item: any) => (
                <AdminPlaceItem
                  handleGetPlacePreview={() => handleGetPlacePreview(item.id)}
                  key={item.id}
                  item={item}
                  isApproved={isApproved}
                  page={page}
                  previewData={
                    previewData && item.id === previewData?.data.placeId
                      ? previewData.data
                      : undefined
                  }
                />
              ))}
            </tbody>
          </table>
          <PageNumberWrapper>
            {Array.from({ length: getPartnershipData?.data.totalPages || 1 }, (_, index) => (
              <PageNumbers key={index} onClick={() => handlePageClick(index)}>
                {index + 1}
              </PageNumbers>
            ))}
          </PageNumberWrapper>
        </div>
        {/* layout */}
        <div style={{ marginTop: 40 }}>
          <PreviewLayout>
            {previewData && <AdminPlacePreview previewData={previewData.data} />}
          </PreviewLayout>
        </div>
      </ContentsWrapper>
    </Container>
  );
};
const PreviewLayout = styled.div`
  width: 360px;
  height: 640px;
  min-width: 360px;
  border: 2px solid #1b303f;
  border-radius: 28px;
  overflow: hidden;
`;
const PreviewOwnerInfo = styled.div`
  border: 2px solid #1b303f;
  border-radius: 28px;
  width: 360;
  padding: 15px;
  margin-bottom: 20px;
`;
const PageNumbers = styled.button`
  all: unset;
  font-size: 1.125rem;
  padding: 4px 8px;
  margin-right: 5px;
  background-color: #1b303f;
  color: #ffffff;
`;
const PageNumberWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
const PartnerWrapper = styled.div`
  display: flex;
  padding-left: 34px;
  font-size: 1.875rem;
  color: #1b303f;
  cursor: pointer;
`;
const SearchInput = styled.input`
  padding: 2px 10px;
  width: 340px;
  border-radius: 28px;
  border: 2px solid #918b8b;
  height: 50px;
`;
const TitleWrapper = styled.tr`
  & > th {
    font-size: 1.25rem;
    color: #5e5959;
    padding: 10px 40px;
  }
`;
// const TitleWrapper = styled.div`
//   display: flex;
// `;
const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;
`;
const ContentsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 70px 0 70px;
`;
export default AdminPlacesPage;
