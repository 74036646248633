import styled from '@emotion/styled';
export const BigContainer = styled.div`
  width: 100%;
  background-color: #f6f6f6;
  color: #1b303f;
`;
export const CheckBoxImage = styled.img`
  width: 1.875rem;
  height: auto;
  margin-left: 5px;
`;

export const Container = styled.div`
  width: 100%;
  padding: 30px 30px 0 30px;
  /* background-color: #f6f6f6; */
  border-bottom: 2px solid #b9b3b3;
  margin-bottom: 40px;
`;
export const DropOffWrapper = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 9px;
  width: 100%;
`;
export const DropOff = styled.div`
  font-size: 1.375rem;
  font-family: 'DM Sans Medium';
`;
export const Clock = styled.img`
  width: 0.875rem;
  height: auto;
  margin-right: 6px;
`;
export const OperatingText = styled.span`
  font-family: 'DM Sans Medium';
  font-size: 0.9375rem;
  color: #807979;
`;
export const ClockWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DateInput = styled.input`
  all: unset;
  width: 90%;
  height: 1.625rem;
  font-size: 1.5625rem;
  font-family: 'DM Sans Bold';
  /* color: #c3bcbc; */
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: transparent;
  text-shadow: 0 0 0 #1b303f;
`;
export const HoursWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`;
export const HourBigText = styled.div`
  font-family: 'DM Sans Bold';
  font-size: 1.25rem;
`;
export const OneHourWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const CalculateButton = styled.span`
  font-size: 1.25rem;
  font-family: 'DM Sans Bold';
  line-height: 0.75rem;
  cursor: pointer;
`;
export const CheckOutText = styled.div`
  color: #0094ff;
`;
export const CalendarImage = styled.img`
  width: 1.875rem;
  height: auto;
  margin-right: 8px;
`;
export const CheckOutMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #0094ff;
  margin-top: 40px;
  padding-bottom: 40px;

  .check-out_1 {
    font-family: 'DM Sans Medium';
    font-size: 1.375rem;
  }
  .check-out_2 {
    font-family: 'DM Sans Bold';
    font-size: 1.0625rem;
  }
`;
export const CheckOutError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f74726;
  margin-top: 40px;
  padding-bottom: 40px;

  font-family: 'DM Sans Bold';
  font-size: 0.875rem;
`;
export const PlusMinusWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const About4Hours = styled.div`
  font-size: 0.6875rem;
  font-family: 'DM Sans Bold';
`;
export const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const MoreInfoContainer = styled.div`
  width: 100%;
  padding: 0 20px;
`;
export const MoreInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid #1b303f;
  padding: 13px 0;
`;
export const MoreInfoImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 10px;
`;
export const MoreInfo = styled.div`
  font-size: 0.8125rem;
  font-family: 'DM Sans Regular';
`;
