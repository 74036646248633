import styled from '@emotion/styled';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiResponseType } from '../../apis/adminApi';
import { GetPlaceType } from '../../types/storageType';
import {
  StatusResponseDataType,
  getBookingsByPlaceApi,
  getPlacesByHostApi,
  patchPlaceStatusApi,
} from '../../apis/placeApi';
import HamburgerIcon from '../../assets/images/Hamburger.png';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { modalState } from '../../recoil/modalAtom';
import { BookingByPlaceDataType, BookingsByPlaceType } from '../../types/getBookingsByStorageType';
import HostBookingItem from '../../components/host/HostBookingItem';
import { v4 as uuidv4 } from 'uuid';
import { useIntersectionObserverForHost } from '../../hooks/useIntersectionObserverForHost';
import HostHamburger from '../../components/host/HostHamburger';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import LugezName from '../../assets/images/LugezLogo.png';
import Spinner from '../../components/common/Spinner';
import { authIntentState } from '../../recoil/authIntentAtom';
import { authenticatedState } from '../../recoil/authenticatedAtom';
type BookingStatusType = 'WAIT' | 'IN_PROGRESS' | 'COMPLETED';

const emptyMessages: Record<BookingStatusType, string> = {
  WAIT: '체크인 예정인 게스트가 없습니다',
  IN_PROGRESS: '현재 보관중인 짐이 없습니다',
  COMPLETED: '이용 내역이 없습니다',
};
const ManageStoragePage = () => {
  // const lastElementRef = useRef<HTMLDivElement | null>(null);
  const { reset } = useForm();
  const queryClient = useQueryClient();
  const [bookingStatus, setBookingStatus] = useState<BookingStatusType>('WAIT');
  const [modal, setModal] = useRecoilState(modalState);
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authenticatedState);

  const {
    data: placeData,
    isError: getPlaceDataError,
    error,
    isLoading: placeIsLoading,
  } = useQuery({
    queryKey: ['place'],
    queryFn: async (): Promise<ApiResponseType<GetPlaceType>> => getPlacesByHostApi(),
    retry: (failCount, error) => {
      if ((error as AxiosError).response!.status === 404) return false;
      if (failCount >= 1) return false;
      return true;
    },
    enabled: !!isAuthenticated,
  });
  const enableMutation = useMutation<ApiResponseType<StatusResponseDataType>, Error, number>({
    mutationFn: (placeId: number) => patchPlaceStatusApi(placeId),
    onSuccess: (data: ApiResponseType<StatusResponseDataType>) => {
      reset();
      queryClient.removeQueries({ queryKey: ['place'] });
      console.log(data, 'status change success');
      if (data.data.enabled) {
        alert('보관소가 활성화 되었습니다');
      } else {
        alert('보관소가 비활성화 되었습니다');
      }
    },
    onError: (error) => {
      console.log(error, 'place status change error');
      alert('보관소 상태변경에 실패하였습니다. 관리자에게 문의해주세요.');
    },
  });
  const handleChangePlaceEnabled = () => {
    if (placeData) {
      enableMutation.mutate(placeData.data.placeId);
    } else {
      alert('등록된 보관소가 없습니다');
    }
  };
  const clickHamburger = () => {
    setModal('hostMenu');
  };

  // 오늘, 내일 등 구분해야 함.
  const {
    data: bookingsData,
    isError: bookingsIsError,
    error: bookingsError,
    isLoading: bookingsIsLoading,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ['bookingsByPlace', bookingStatus],
    queryFn: async ({ pageParam = 0 }): Promise<ApiResponseType<BookingsByPlaceType>> =>
      getBookingsByPlaceApi(placeData?.data.placeId, { status: bookingStatus, page: pageParam }),
    getNextPageParam: (lastPage, pages) => {
      // 여기에 lastPage의 데이터 형식을 기반으로 다음 페이지를 결정하는 로직을 추가하세요.
      // 예를 들어, lastPage.data.length가 10이면 다음 페이지가 있다고 간주합니다.
      // return lastPage.data.data.length === 10 ? lastPage.data.pageNumber + 1 : undefined;
      return lastPage.data.data.length === 10 ? pages.length : undefined;
    },
    enabled: !!placeData,
    initialPageParam: 0,
  });
  // const groupedByDate = bookingsData?.pages.map((page) => {
  //   return page.data.data.reduce(
  //     (acc: { [key: string]: BookingByPlaceDataType[] }, current: BookingByPlaceDataType) => {
  //       const date = current.booking.date;
  //       if (!acc[date]) {
  //         acc[date] = [];
  //       }
  //       acc[date].push(current);
  //       return acc;
  //     },
  //     {},
  //   );
  // });

  // const resultArray = Object.keys((groupedByDate && groupedByDate[0]) || {}).map((date) => ({
  //   date,
  //   bookings: groupedByDate![0][date],
  // }));

  // 하나의 객체로 데이터 그룹화
  const groupedByDate = bookingsData?.pages.reduce((acc, page) => {
    page.data.data.forEach((current) => {
      const date = current.booking.date;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(current);
    });
    return acc;
  }, {} as { [key: string]: BookingByPlaceDataType[] });

  const resultArray = Object.keys(groupedByDate || {}).map((date) => ({
    date,
    bookings: groupedByDate![date],
  }));
  const { target } = useIntersectionObserverForHost({
    hasNextPage,
    fetchNextPage,
  });

  const handleChangeStatus = (status: BookingStatusType) => {
    setBookingStatus(status);
  };
  if (bookingsIsLoading || placeIsLoading || enableMutation.isPending) return <Spinner />;
  if (getPlaceDataError) {
    console.log(error, 'place Data error config');
  }
  if (bookingsIsError) {
    alert('예약내역을 불러오는데에 실패하였습니다. 관리자에게 문의해주세요.');
  }
  return (
    <Container>
      {modal === 'hostMenu' && (
        <HostHamburger
          hasPlace={!!placeData}
          handleChangePlaceEnabled={handleChangePlaceEnabled}
          enabled={placeData?.data.enabled}
        />
      )}
      <HeaderContainer>
        <div>
          <HostName>{placeData ? placeData.data.owner.name : '호스트'}님 반갑습니다</HostName>
          {placeData ? (
            <PlaceName>{placeData.data.name}</PlaceName>
          ) : (
            <img style={{ width: '8.75rem', height: 'auto' }} src={LugezName} />
          )}
        </div>
        <div>
          <img
            onClick={clickHamburger}
            style={{ width: '2.5rem', height: 'auto' }}
            src={HamburgerIcon}
          />
        </div>
      </HeaderContainer>

      <ContentContainer>
        <StatusWrapper>
          <StatusMiniWrapper>
            {/* 갯수를 한번에 표시 못하는 문제 어떡하지  */}
            <div
              onClick={() => handleChangeStatus('WAIT')}
              style={{
                marginRight: 17,
                textDecoration: bookingStatus === 'WAIT' ? 'underline' : '',
              }}
            >
              체크인 예정
              {bookingStatus === 'WAIT' &&
                bookingsData &&
                '(' +
                  bookingsData?.pages.reduce((acc, page) => acc + page.data.totalElement, 0) +
                  ')'}
            </div>
            <div
              onClick={() => handleChangeStatus('IN_PROGRESS')}
              style={{ textDecoration: bookingStatus === 'IN_PROGRESS' ? 'underline' : '' }}
            >
              이용중
              {bookingStatus === 'IN_PROGRESS' &&
                bookingsData &&
                '(' +
                  bookingsData?.pages.reduce((acc, page) => acc + page.data.totalElement, 0) +
                  ')'}
            </div>
          </StatusMiniWrapper>
          <div
            onClick={() => handleChangeStatus('COMPLETED')}
            style={{ textDecoration: bookingStatus === 'COMPLETED' ? 'underline' : '' }}
          >
            이용 내역
          </div>
        </StatusWrapper>

        {resultArray && resultArray.length > 0 ? (
          resultArray?.map((item, index) => (
            <div key={uuidv4()}>
              <DayText>{item.date}</DayText>
              {item.bookings.map((booking, bookingIndex) => (
                <div
                  key={bookingIndex}
                  // ref={
                  //   index === resultArray.length - 1 && bookingIndex === item.bookings.length - 1
                  //     ? target
                  //     : null
                  // }
                  ref={
                    index === resultArray.length - 1 &&
                    bookingIndex === item.bookings.length - 1 &&
                    hasNextPage
                      ? target
                      : null
                  }
                >
                  <HostBookingItem item={booking} bookingStatus={bookingStatus} />
                </div>
              ))}
            </div>
          ))
        ) : (
          <EmptyWrapper>{emptyMessages[bookingStatus]}</EmptyWrapper>
        )}
      </ContentContainer>
    </Container>
  );
};
const ContentContainer = styled.div`
  width: 100%;
`;
const EmptyWrapper = styled.div`
  padding-top: 160px;
  width: 100%;
  text-align: center;
`;
const DayText = styled.div`
  padding: 20px;
  font-size: 1.375rem;
  color: #1b303f;
  border-bottom: 2px solid #e4e4e4;
`;
const StatusMiniWrapper = styled.div`
  display: flex;
`;
const StatusWrapper = styled.div`
  cursor: pointer;
  font-size: 0.9375rem;
  color: #1b303f;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const HostName = styled.div`
  color: #675e5e;
  margin-bottom: 5px;
  font-size: 0.75rem;
  font-family: 'DM Sans Medium';
`;
const PlaceName = styled.div`
  font-size: 1.5rem;
  color: #1b303f;
  font-family: 'DM Sans ExtraBold';
`;
const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 2px solid #5b5656;
  padding: 0 20px;
  padding-top: 40px;
  padding-bottom: 14px;
`;
const AddStorage = styled.div`
  font-size: 0.75rem;
  border: 2px solid #675e5e;
  border-radius: 30px;
  padding: 5px 15px;
  margin-bottom: 16px;
  cursor: pointer;
`;
const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  /* Chrome, Safari */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  scrollbar-width: none;

  /* Internet Explorer */
  -ms-overflow-style: none;
`;
export default ManageStoragePage;
