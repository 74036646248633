import styled from '@emotion/styled';
import { useRecoilState } from 'recoil';
import { modalState } from '../../recoil/modalAtom';
import Modal from 'react-modal';
interface ModalType {
  validateForms: () => Promise<void> | void;
  bigText: string;
  smallTextFirst: string;
  smallTextSecond?: string;
  cancelText: string;
  confirmText: string;
}

Modal.setAppElement('#root');
const ConfirmModal = ({
  validateForms,
  bigText,
  smallTextFirst,
  smallTextSecond,
  cancelText,
  confirmText,
}: ModalType) => {
  const [modal, setModal] = useRecoilState(modalState);
  const confirmSubmit = (confirm: string) => {
    if (confirm === 'yes') {
      validateForms();
      setModal('none');
    } else if (confirm === 'no') {
      setModal('none');
      return;
    }
  };
  return (
    <Modal
      isOpen={modal === 'modal'}
      onRequestClose={() => setModal('none')}
      style={customModalStyles}
      shouldCloseOnOverlayClick={true}
    >
      <Container>
        <BigText style={{ marginBottom: 8 }}>{bigText}</BigText>
        <ConfirmText>{smallTextFirst}</ConfirmText>
        {smallTextSecond && (
          <ConfirmText style={{ marginBottom: 20 }}>{smallTextSecond}</ConfirmText>
        )}

        <ConfirmWrapper>
          <TextBox>
            <ClickTextNo onClick={() => confirmSubmit('no')}>{cancelText}</ClickTextNo>
          </TextBox>
          <TextBox>
            <ClickTextYes onClick={() => confirmSubmit('yes')}>{confirmText}</ClickTextYes>
          </TextBox>
        </ConfirmWrapper>
      </Container>
    </Modal>
  );
};

const customModalStyles: ReactModal.Styles = {
  overlay: {
    backgroundColor: ' rgba(0, 0, 0, 0.4)',
    width: '100%',
    height: '100%',
    zIndex: '10',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  content: {
    padding: '0 20px',
    width: '320px',
    height: '230px',
    zIndex: '150',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
    backgroundColor: 'white',
    justifyContent: 'center',
    overflow: 'auto',
  },
};
const TextBox = styled.div`
  font-size: 1.4375rem;
  font-family: 'DM Sans Bold';
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const BigText = styled.div`
  font-size: 1.625rem;
  font-family: 'DM Sans Bold';
  /* width: 100%; */
  text-align: center;
`;
const ConfirmText = styled.div`
  font-size: 0.875rem;
  font-family: 'DM Sans Regular';
`;
const ClickTextYes = styled.div`
  color: #ffffff;
  background-color: #0094ff;
  padding: 10px 36px;
  border-radius: 16px;
`;
const ClickTextNo = styled.div`
  color: #8c8181;
`;
const ConfirmWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export default ConfirmModal;
