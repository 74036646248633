// useNavigationHook.js
import { useNavigate } from 'react-router-dom';

export const useRegisterNavigation = (
  setRegisterSection: (value: number | ((prev: number) => number)) => void,
) => {
  const navigate = useNavigate();
  const steps = [
    'category',
    'about',
    'facilities',
    'quantity',
    'addr',
    'detailAddr',
    'owner',
    'contact',
    'image',
  ];

  const navigateToStep = (index: number) => {
    const step = steps[index];
    navigate(`/host/register-storage?step=${step}`);
    setRegisterSection(index);
  };

  return { navigateToStep, steps };
};
