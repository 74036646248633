import { useNavigate } from 'react-router-dom';
import JugezLogo from '../../assets/images/LugezLogo.png';
import styled from '@emotion/styled';
import Modal from 'react-modal';
import { useEffect, useState } from 'react';
import Phone from '../../assets/images/InfoCall.png';
import Mail from '../../assets/images/Mail.png';
import ModalCloseBtn from '../../assets/images/CancelCheckIn.png';
import ArrowImage from '../../assets/images/ClickToDetail.png';
import { useRecoilState } from 'recoil';
import { authIntentState } from '../../recoil/authIntentAtom';
import { authenticatedState } from '../../recoil/authenticatedAtom';
const RegisterSuccessPage = () => {
  const navigate = useNavigate();

  const goToManagePage = () => {
    navigate('/host/manage-storage');
  };
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);
  return (
    <Container>
      <Modal
        style={customModalStyles}
        isOpen={isHelpOpen}
        onRequestClose={() => setIsHelpOpen(false)}
      >
        <div
          onClick={() => setIsHelpOpen(false)}
          style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <img style={{ width: '1.75rem', height: 'auto' }} src={ModalCloseBtn} />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',

            padding: '0 40px',
          }}
        >
          <InfoWrapper style={{ paddingBottom: 15 }}>
            <ModalIcon src={Phone} />
            <InfoText>+82 010-4018-1368</InfoText>
          </InfoWrapper>
          <InfoWrapper>
            <ModalIcon src={Mail} />
            <InfoText>jimventory@gmail.com</InfoText>
          </InfoWrapper>
        </div>
      </Modal>
      {/* <StarBackground src={Stars} /> */}
      <Logo src={JugezLogo} />
      <TextWrapper>
        <BlueText>등록 완료!</BlueText>
        <div style={{ marginBottom: 5 }}>등록된 정보를 관리자가 검토합니다</div>
        <div>검토하는데 2-3일이 소요 될 수 있습니다</div>
      </TextWrapper>
      <div
        style={{
          width: '100%',
          padding: '0 20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: 30,
        }}
      >
        <ManageContainer onClick={goToManagePage}>
          <ManageText>내 보관소 관리하기</ManageText>
          <img style={{ width: '3.125rem', height: '3.125rem' }} src={ArrowImage} />
        </ManageContainer>

        <div>
          <HelpText onClick={() => setIsHelpOpen(true)}>도움이 필요하신가요?</HelpText>
          {/* <img style={{ width: '1.625rem', height: 'auto' }} src={HelpIcon} /> */}
        </div>
      </div>
    </Container>
  );
};
const ManageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 120px; */
  margin-bottom: 20px;
  border: 2px solid #1b303f;
  padding: 3px 3px 3px 10px;
  border-radius: 28px;
  cursor: pointer;
`;
const ManageText = styled.div`
  font-size: 1.25rem;
  color: #1b303f;
  margin-right: 5px;
  font-family: 'DM Sans Bold';
  cursor: pointer;
`;
const customModalStyles: ReactModal.Styles = {
  overlay: {
    backgroundColor: ' rgba(0, 0, 0, 0.4)',
    width: '100%',
    height: '100%',
    zIndex: '10',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  content: {
    width: '320px',
    height: '180px',
    zIndex: '150',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
    backgroundColor: 'white',
    justifyContent: 'center',
    overflow: 'auto',
  },
};
const InfoText = styled.div`
  color: #1b303f;
  font-size: 0.875rem;
  text-decoration: underline;
  font-family: 'DM Sans SemiBold';
`;
const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const ModalIcon = styled.img`
  width: 1.75rem;
  height: auto;
  margin-right: 10px;
`;
const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;
const StarBackground = styled.img`
  position: absolute;
  top: 170px;
  width: 90%;
  height: auto;
  z-index: -10;
`;
const HelpText = styled.div`
  text-decoration: underline;
  font-size: 1rem;
  color: #675e5e;
  margin-right: 5px;
  font-family: 'DM Sans Bold';
  cursor: pointer;
`;
const BlueText = styled.div`
  /* margin-top: 130px; */
  margin-bottom: 30px;
  font-size: 2.75rem;
  color: #0094ff;
  font-family: 'DM Sans Bold';
`;
const Container = styled.div`
  width: 100%;
  /* height: calc(100% - 70px); */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
const Logo = styled.img`
  margin-top: 100px;
  width: auto;
  height: 2.5rem;
`;
export default RegisterSuccessPage;
