import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { googleLoginApi } from '../../apis/loginApi';
import { useRecoilState } from 'recoil';
import { authenticatedState } from '../../recoil/authenticatedAtom';
import { authIntentState } from '../../recoil/authIntentAtom';

const GoogleCallback = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authenticatedState);
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  const { intent, intentPath, previousPath } = authIntent;
  const [cookies, setCookie] = useCookies(['access_token', 'refresh_token']);
  const handleRedirect = () => {
    setTimeout(() => {
      navigate(intentPath, { replace: true });
    }, 500);
  };
  useEffect(() => {
    if (cookies.access_token && cookies.refresh_token) {
      // 쿠키에 토큰이 존재하는 경우
      setIsAuthenticated(true);
      // navigate(intentPath, { replace: true });
      handleRedirect();
    } else {
      // 쿠키에 토큰이 존재하지 않는 경우
      confirmLogin();
    }
  }, [cookies.access_token, cookies.refresh_token]);

  const confirmLogin = () => {
    const params = new URL(document.location.toString()).searchParams;
    const access_token = params.get('access');
    const refresh_token = params.get('refresh');

    if (access_token && refresh_token) {
      setCookie('access_token', access_token, {
        path: '/',
        secure: true, // HTTPS 연결을 통해서만 쿠키가 전송되도록 보장
        sameSite: 'strict', // CSRF 공격을 방지하기 위해 설정
        // HttpOnly는 서버에서 설정해야 함
      });
      setCookie('refresh_token', refresh_token, {
        path: '/',
        secure: true,
        sameSite: 'strict',
        // HttpOnly는 서버에서 설정해야 함
      });
      setIsAuthenticated(true);
      // navigate(intentPath, { replace: true });
      handleRedirect();
    }
  };
  return <div></div>;
};
export default GoogleCallback;
