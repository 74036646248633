import { useRecoilState, useSetRecoilState } from 'recoil';
import XLayout from '../common/XLayout';
import { modalState } from '../../recoil/modalAtom';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { authenticatedState } from '../../recoil/authenticatedAtom';
import { adminAuthenticatedState } from '../../recoil/adminAuthenticatedAtom';
import { useCookies } from 'react-cookie';
import { hasPersonalInfoState } from '../../recoil/personalInfo';
import Modal from 'react-modal';
import { useState } from 'react';
import ModalCloseBtn from '../../assets/images/CancelCheckIn.png';
import Phone from '../../assets/images/InfoCall.png';
import Mail from '../../assets/images/Mail.png';
import { authIntentState } from '../../recoil/authIntentAtom';
import {
  ReservationStateType,
  defaultReservationState,
  reservationState,
} from '../../recoil/reservationRecoil';
interface HostHamburgerPropsType {
  hasPlace: boolean;
  handleChangePlaceEnabled: () => void;
  enabled: boolean | undefined;
}
const HostHamburger = ({ hasPlace, handleChangePlaceEnabled, enabled }: HostHamburgerPropsType) => {
  const setModal = useSetRecoilState(modalState);
  const navigate = useNavigate();
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);
  const [, , removeCookie] = useCookies(['access_token', 'refresh_token']);
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authenticatedState);
  const setIsAdminAuthenticated = useSetRecoilState(adminAuthenticatedState);
  const setPersonalInfo = useSetRecoilState(hasPersonalInfoState);
  const [reservationInfo, setReservationInfo] =
    useRecoilState<ReservationStateType>(reservationState);
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  const handleRegisterBtn = () => {
    if (hasPlace) {
      alert('지금은 한 개 이상의 보관소 등록이 어렵습니다. 관리자에게 문의해주세요');
    } else {
      navigate('/host/register-storage?step=category');
      setModal('none');
      setAuthIntent({
        ...authIntent,
        intent: 'host',
        previousPath: '/host/manage-storage',
        intentPath: '/host/register-storage?step=category',
      }); // 해당 버튼은 로그인 한 사용자가 호스트로 전환하여 들어왔을 경우에만
    }
  };
  const handleEditBtn = () => {
    if (hasPlace) {
      alert('관리자에게 문의해주세요');
    } else {
      alert('등록된 보관소가 없습니다');
    }
  };
  const handleClickLogout = () => {
    // 로그인 상태일 시 - 로그아웃 로직
    setModal('none');
    setIsAuthenticated(false);
    setIsAdminAuthenticated(false);
    setPersonalInfo(false);
    setReservationInfo(defaultReservationState);
    removeCookie('access_token', { path: '/' });
    removeCookie('refresh_token', { path: '/' });

    // if (authIntent.intent === 'host') {
    //   navigate('/host/manage-storage');
    // } else navigate('/main');
    navigate('/main');
  };

  const handleClickLogin = () => {
    setAuthIntent({
      ...authIntent,
      intent: 'host',
      previousPath: '/host/manage-storage',
      intentPath: `/host/manage-storage`,
    });
    // navigate('/log-in');
    setModal('login');
  };
  return (
    <XLayout handleClickXButton={() => setModal('none')}>
      <Container className="host_hamburger_container">
        <Modal
          style={customModalStyles}
          isOpen={isHelpOpen}
          onRequestClose={() => setIsHelpOpen(false)}
        >
          <div
            onClick={() => setIsHelpOpen(false)}
            style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
          >
            <img style={{ width: '1.75rem', height: 'auto' }} src={ModalCloseBtn} />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',

              padding: '0 40px',
            }}
          >
            <InfoWrapper style={{ paddingBottom: 15 }}>
              <ModalIcon src={Phone} />
              <InfoText>+82 010-4018-1368</InfoText>
            </InfoWrapper>
            <InfoWrapper>
              <ModalIcon src={Mail} />
              <InfoText>jimventory@gmail.com</InfoText>
            </InfoWrapper>
          </div>
        </Modal>
        <div></div>
        <div style={{ width: '100%' }}>
          <Button onClick={() => navigate('/main')}>Switch to Player</Button>
          <Button onClick={handleRegisterBtn}>보관소 등록하기</Button>
          <Button onClick={handleEditBtn}>보관소 수정하기</Button>
          <Button onClick={handleChangePlaceEnabled}>
            {enabled ? '보관소 비활성화' : '보관소 활성화'}
          </Button>
          <Button onClick={() => setIsHelpOpen(true)}>고객센터</Button>
        </div>
        {isAuthenticated ? (
          <LogOutText onClick={handleClickLogout}>로그아웃</LogOutText>
        ) : (
          <LogOutText onClick={handleClickLogin}>로그인</LogOutText>
        )}
      </Container>
    </XLayout>
  );
};
const InfoText = styled.div`
  color: #1b303f;
  font-size: 0.875rem;
  text-decoration: underline;
  font-family: 'DM Sans SemiBold';
`;
const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const ModalIcon = styled.img`
  width: 1.75rem;
  height: auto;
  margin-right: 10px;
`;
const customModalStyles: ReactModal.Styles = {
  overlay: {
    backgroundColor: ' rgba(0, 0, 0, 0.4)',
    width: '100%',
    height: '100%',
    zIndex: '901',
    position: 'fixed',

    top: '0',
    left: '0',
  },
  content: {
    width: '320px',
    height: '180px',
    zIndex: '999',
    position: 'absolute',

    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#f6f6f6',
    justifyContent: 'center',
    overflow: 'auto',
  },
};
const Button = styled.div`
  font-size: 0.875rem;
  font-family: 'DM Sans Regular';
  cursor: pointer;
  width: 100%;
  padding: 17px 0;
  text-align: center;
  color: #1b303f;
  border: 2px solid #d9d9d9;
  border-radius: 30px;
  margin-bottom: 5px;
`;
const LogOutText = styled.div`
  text-decoration: underline;
  font-size: 0.9375rem;
  color: #828282;
  font-family: 'DM Sans Medium';
  cursor: pointer;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  position: relative;
`;
export default HostHamburger;
