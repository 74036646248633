import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export type AuthenticatedStateType = boolean;

const { persistAtom } = recoilPersist({
  key: 'authenticated',
  storage: localStorage,
});
export const authenticatedState = atom<AuthenticatedStateType>({
  key: 'authenticatedState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});
