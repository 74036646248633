import styled from '@emotion/styled';
import PrevButton from '../../assets/images/PrevButton.png';
import ProgressBar from '../common/ProgressBar';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface BookingHeaderType {
  section: number;
  setSection: (value: number | ((prev: number) => number)) => void;
  id: string | undefined;
}
const BookingHeader = ({ section, setSection, id }: BookingHeaderType) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const guideMessages: { [key: number]: string } = {
    1: `${t('bookingPage.choose_date')}`,
    2: `${t('bookingPage.how_many_items')}`,
  };
  const goToPrevSection = () => {
    if (section === 3) {
      navigate(`/booking/${id}?step=quantity-selection`);
      setSection((prev) => prev - 1);
    } else if (section === 2) {
      navigate(`/booking/${id}?step=date-selection`);
      setSection((prev) => prev - 1);
    } else if (section === 1) {
      navigate(`/booking/${id}?step=information`);
      setSection((prev) => prev - 1);
    }
  };
  return (
    <Wrapper>
      <Image onClick={goToPrevSection} src={PrevButton} />
      <GuideMessage>{guideMessages[section]}</GuideMessage>
      <div style={{ width: '3.125rem' }}></div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  border-bottom: 2px solid #1b303f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;
const Image = styled.img`
  width: 3.125rem;
  height: auto;
`;
const GuideMessage = styled.div`
  font-size: 1.125rem;
  font-family: 'DM Sans Bold';
  color: #1b303f;
`;
export default BookingHeader;
