import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const useAxiosWithAuth = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['access_token', 'refresh_token']);
  const navigate = useNavigate();
  let retry = false;
  const authApiClient = axios.create({
    baseURL: 'https://dev-api.jimventory.com',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // 요청 인터셉터 추가
  authApiClient.interceptors.request.use(
    (config) => {
      const token = cookies.access_token;
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  // 응답 인터셉터 추가
  authApiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalConfig = error.config;
      const status = error.response?.status;
      const msg = error.response?.data?.msg;

      if (status === 500 && !retry) {
        // 새로운 토큰을 발급받아서 재 요청을 보낼 때, 새로운 토큰도 유효하지 않다고
        // 판정되어 에러가 발생한다면 무한 루프가 생길 수 있음. 이를 방지하기 위해
        // originalConfig._retry를 true로 만들어 방지.
        retry = true;

        try {
          const res = await authApiClient.get('/auth/reissue', {
            headers: {
              Authorization: `Bearer ${cookies.refresh_token}`,
              //   Authorization: 'Authorization',
            },
          });

          setCookie('access_token', res.data.accessToken, {
            path: '/',
            secure: true,
            sameSite: 'strict',
          });

          setCookie('refresh_token', res.data.refreshToken, {
            path: '/',
            secure: true,
            sameSite: 'strict',
          });

          originalConfig.headers['Authorization'] = `Bearer ${res.data.accessToken}`;
          console.log(res);
          return await authApiClient(originalConfig);
        } catch (refreshError) {
          removeCookie('access_token', { path: '/' });
          removeCookie('refresh_token', { path: '/' });
          navigate('/log-in', { state: { from: 'session_expired' } }); // 세션 만료되어 재로그인 할 경우는 main으로 이동
          return Promise.reject(refreshError);
        }
      } else if (status !== 401 || status !== 500) {
        console.error('Error message:', msg);
      }

      return Promise.reject(error);
    },
  );

  return authApiClient;
};

export default useAxiosWithAuth;
