import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const StartFooter = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Container onClick={() => navigate('/main')}>
      <Text>{t('startPage.start')}</Text>
    </Container>
  );
};
const Container = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #1b303f;
`;
const Text = styled.div`
  color: #fcfafa;
  font-family: 'DM Sans Bold';
  font-size: 30px;
`;
export default StartFooter;
