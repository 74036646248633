import { useLocation } from 'react-router-dom';
import {
  SectionContainer,
  SectionInput,
  SectionTitle,
} from '../../assets/css/RegisterCommonStyles';
import styled from '@emotion/styled';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  ValidType,
  registrationValidState,
  storageRegistrationState,
} from '../../recoil/storageRecoil';
import { useEffect, useState } from 'react';
import { RegisterPlaceType } from '../../types/storageType';

const RegisterDetailAddr = () => {
  const setIsStepValid = useSetRecoilState<ValidType>(registrationValidState);
  const location = useLocation();
  const [storageRegistration, setStorageRegistration] =
    useRecoilState<RegisterPlaceType>(storageRegistrationState);
  const [detailAddress, setDetailAddress] = useState<string>(storageRegistration.detailAddr);
  const jibunAddress = location?.state?.jibunAddress || storageRegistration.addr;
  const roadAddress = location?.state?.roadAddress || storageRegistration.roadAddr;
  const lat = location?.state?.location.lat || storageRegistration.lat;
  const lng = location?.state?.location.lng || storageRegistration.lng;
  useEffect(() => {
    setIsStepValid((prev) => ({ ...prev, detailAddr: true }));
  }, []);

  return (
    <SectionContainer>
      <SectionTitle>상세 주소를 알려주세요</SectionTitle>
      <AddrContainer>
        <AddrWrapper>
          <Category>도로명</Category>
          <div>{roadAddress || storageRegistration.roadAddr}</div>
        </AddrWrapper>
        <AddrWrapper>
          <Category>지번</Category>
          <div>{jibunAddress || storageRegistration.addr}</div>
        </AddrWrapper>
      </AddrContainer>

      <SectionInput
        onChange={(e) => {
          setDetailAddress(e.target.value);
          setStorageRegistration({
            ...storageRegistration,
            detailAddr: e.target.value,
          });
        }}
        value={detailAddress}
        style={{ marginTop: 22, width: '100%' }}
        placeholder="건물명, 동/호수 등의 상세 주소"
      />
    </SectionContainer>
  );
};
const Category = styled.div`
  border: 2px solid #d9d9d9;
  padding: 2px;
  font-size: 0.75rem;
  border-radius: 8px;
  margin-right: 5px;
  width: 3rem;
  text-align: center;
`;
const AddrWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
`;
const AddrContainer = styled.div`
  width: 100%;
  margin-top: 70px;
  font-size: 1rem;
  font-family: 'DM Sans Bold';
`;
export default RegisterDetailAddr;
