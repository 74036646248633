import { createRef, useRef } from 'react';
import { Cropper, ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import styled from '@emotion/styled';
interface CropperModalTypes {
  originalImageSrc: string;
  onCrop: (croppedImage: string) => void;
  onClose: () => void;
}
const CropperModal = ({ originalImageSrc, onCrop, onClose }: CropperModalTypes) => {
  const cropperRef = createRef<ReactCropperElement>();

  const handleCrop = () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        const croppedImage = croppedCanvas.toDataURL();
        onCrop(croppedImage);
      }
    }
  };
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 'auto',
        height: 'auto',
      }}
    >
      <div
        style={{
          // width: '100%',
          height: '400px',
          // marginTop: '-200px',
          // marginBottom: '50px',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <StyledCropper
          ref={cropperRef}
          //   zoomTo={0.5}
          initialAspectRatio={1}
          src={originalImageSrc}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={false}
          autoCropArea={1}
          cropBoxResizable={false}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          guides={true}
          aspectRatio={1}
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }} // 추가
        />
      </div>
      <ButtonWrapper>
        <Button onClick={onClose}>취소</Button>
        <Register onClick={handleCrop}>적용</Register>
      </ButtonWrapper>
    </div>
  );
};
const StyledCropper = styled(Cropper)`
  .cropper-container {
    height: 100vh;
    width: 100%;
    position: relative;
  }
  .cropper-hidden {
    height: 100px;
  }
`;

const Button = styled.div`
  all: unset;
`;
const ButtonWrapper = styled.div`
  /* height: 70px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px 12px 40px;
  font-size: 1.375rem;
  font-family: 'DM Sans Bold';
  cursor: pointer;
  @media only screen and (max-width: 600px) {
    position: fixed;
    bottom: 0;
  }
  @media only screen and (min-width: 601px) {
    position: absolute;
    bottom: 0;
  }
`;

const Register = styled.button`
  all: unset;

  color: #ffffff;
  background-color: #0094ff;

  padding: 8px 17px;

  border-radius: 16px;
`;
export default CropperModal;
