import { GetPlaceType } from '../types/storageType';
import authApiClient from './utils/authApiClient';

export interface ApiResponseType<T> {
  status: string;
  data: T;
}
export interface GetPartnerShipParamsType {
  page: number;
  approved: boolean;
  keyword: string | number;
}
export interface GetPartnershipData {
  data: {
    id: number;
    type: string;
    name: string;
    owner: string;
    requestedAt: string;
  }[];
  totalElement: number;
  pageNumber: number;
  totalPages: number;
  isFirst: boolean;
  isLast: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
} // interface로 정의하는 타입도 '객체'라는 점을 명심할 것.

export const getPartnershipApi = async (
  params: GetPartnerShipParamsType,
): Promise<ApiResponseType<GetPartnershipData>> => {
  const response = await authApiClient.get('/admin/partnership', {
    params: params,
  });
  return response.data;
};

export const getPlacePreviewApi = async (id: number): Promise<ApiResponseType<GetPlaceType>> => {
  const response = await authApiClient.get(`/places/${id}`);
  return response.data;
};
export const getFromKeywordApi = async (
  params: GetPartnerShipParamsType,
): Promise<ApiResponseType<GetPartnershipData>> => {
  const response = await authApiClient.get('/admin/partnership', {
    params: params,
  });
  return response.data;
};
export interface DrawOrApproveData {
  data: {};
}

// export const patchWithDrawApi = async (placeId: number) => {
//   const response = await authApiClient.patch(`/admin/partnership/${placeId}/withdraw`, {
//     placeId,
//   });
//   return response;
// };
export const patchWithDrawApi = async (
  placeId: number,
): Promise<ApiResponseType<DrawOrApproveData>> => {
  const response = await authApiClient.patch(`/admin/partnership/${placeId}/withdraw`, {
    placeId,
  });
  return response.data;
};
export const patchWithApproveApi = async (
  placeId: number,
): Promise<ApiResponseType<DrawOrApproveData>> => {
  const response = await authApiClient.patch(`/admin/partnership/${placeId}/approve`, {
    placeId,
  });
  return response.data;
};
export const deletePartnershipApi = async (placeId: number): Promise<ApiResponseType<{}>> => {
  const response = await authApiClient.delete(`/admin/partnership/${placeId}`);
  return response.data;
};
