const faqItems = [
  {
    question: '짐을 보관할 공간이 없어도 괜찮습니다!',
    answer:
      '최소 5개 이상의 물건을 위한 공간을 두는 것을 추천하지만, 최소 또는 최대 용량 요구 사항은 없습니다. 공간이 많을수록 더 많은 수익을 올릴 수 있습니다. 수용력은 본인이 결정합니다. lug-ez팀의 담당자가 이를 파악하는 데 도움이 필요한 경우, 알려주시기 바랍니다.',
  },
  {
    question: 'lug-ez는 무엇을 하나요?',
    answer:
      'lug-ez는 업체가 사용하지 않는 공간을 활용하여 고객의 짐을 보관할 수 있게 하는 플랫폼을 제공합니다.',
  },
  {
    question: '고객이 어떻게 결제하나요?',
    answer:
      '결제는 10월 정식 앱버전이 출시 하기 전까지 현장에서 결제가 이루어지며 짐 보관 비용 전액 업체에게 지급됩니다. 앱 버전 출시 후에는 어플에서 결제가 이루어지며 짐 보관 비용의 60% 정산 비용이 매달 지급됩니다.',
  },
  {
    question: '운영 시간에 변동이 있으면 어떡하죠?',
    answer: 'lug-ez 호스트 관리 페이지에서 직접 변경할 수 있습니다.',
  },
  {
    question: '보안/훼손이 걱정되는 짐 보관을 거부할 수 있어요.',
    answer:
      "귀하가 불편하다고 느끼는 경우 언제든지 가방을 거부할 수 있는 권리가 있습니다. 고객에게 '가방을 직접 포장하셨습니까?'와 같은 일반적인 항공 보안 질문을 하세요. 질문이 있으면 즉시 lug-ez에 연락하세요.",
  },
];

export default faqItems;
