import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const FaqDocument = () => {
  const { t } = useTranslation();
  return (
    <Container>
      {/* Q1 */}
      <Question>Q: {t('faq.q1')}</Question>
      <div>A: {t('faq.a1')}</div>
      {/* Q2 */}
      <Question>Q: {t('faq.q2')}</Question>
      <div>A: 1. {t('faq.a2.step1')}</div>
      <div>2. {t('faq.a2.step2')}</div>
      <div>3. {t('faq.a2.step3')}</div>
      <div>4. {t('faq.a2.step4')}</div>
      <div>5. {t('faq.a2.step5')}</div>
      {/* Q3 */}
      <Question>Q: {t('faq.q3')}</Question>
      <div>A: {t('faq.a3')}</div>
      {/* Q4 */}
      <Question>Q: {t('faq.q4')}</Question>
      <div>A: {t('faq.a4')}</div>
      {/* Q5 */}
      <Question>Q: {t('faq.q5')}</Question>
      <div>A: {t('faq.a5')}</div>
      {/* Q6 */}
      <Question>Q: {t('faq.q6')}</Question>
      <div>A: {t('faq.a6')}</div>
      {/* Q7 */}
      <Question>Q: {t('faq.q7')}</Question>
      <div>A: {t('faq.a7')}</div>
      {/* Q8 */}
      <Question>Q: {t('faq.q8')}</Question>
      <div>A: {t('faq.a8')}</div>
      {/* Q9 */}
      <Question>Q: {t('faq.q9')}</Question>
      <div>A: {t('faq.a9')}</div>
      {/* Q10 */}
      <Question>Q: {t('faq.q10')}</Question>
      <div>A: {t('faq.a10')}</div>
      {/* Q11 */}
      <Question>Q: {t('faq.q11')}</Question>
      <div>A: {t('faq.a11')}</div>
      {/* Q12 */}
      <Question>Q: {t('faq.q12')}</Question>
      <div>A: {t('faq.a12')}</div>
    </Container>
  );
};
const Container = styled.div`
  padding-left: 15px;
  padding-right: 10px;
  font-size: 0.875rem;
`;
const Question = styled.div`
  margin-top: 10px;
`;
export default FaqDocument;
