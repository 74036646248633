export const addHoursToDateTime = (dateTimeString: string, hoursToAdd: number) => {
  // 주어진 문자열을 기반으로 Date 객체를 생성합니다.
  const dateTime = new Date(dateTimeString);

  // 사용자가 입력한 시간을 더합니다.
  dateTime.setHours(dateTime.getHours() + hoursToAdd);

  // 만약 시간이 24:00 이상이라면 날짜를 업데이트하고 시간을 조정합니다.
  if (dateTime.getHours() >= 24) {
    // 다음 날로 넘어감
    dateTime.setDate(dateTime.getDate() + 1);
    // 시간을 24시간으로 조정
    dateTime.setHours(dateTime.getHours() - 24);
  }

  // 날짜 및 시간을 문자열로 변환하여 반환합니다.
  const year = dateTime.getFullYear();
  const month = ('0' + (dateTime.getMonth() + 1)).slice(-2);
  const day = ('0' + dateTime.getDate()).slice(-2);
  const hours = ('0' + dateTime.getHours()).slice(-2);
  const minutes = ('0' + dateTime.getMinutes()).slice(-2);

  // return `${year}.${month}.${day} ${hours}:${minutes}`;
  const newDateValue = `${year}-${month}-${day} ${hours}:${minutes}`;
  return newDateValue;
};

export const addHoursAndGetCheckOutDate = (initialDate: string | undefined, hoursToAdd: number) => {
  if (initialDate !== undefined) {
    const currentDateTime = new Date(initialDate);

    currentDateTime.setHours(currentDateTime.getHours() + hoursToAdd);

    if (currentDateTime.getHours() >= 24) {
      currentDateTime.setDate(currentDateTime.getDate() + 1);
      currentDateTime.setHours(currentDateTime.getHours() - 24);
    }

    const year = currentDateTime.getFullYear();
    const month = ('0' + (currentDateTime.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDateTime.getDate()).slice(-2);
    const hours = ('0' + currentDateTime.getHours()).slice(-2);
    const minutes = ('0' + currentDateTime.getMinutes()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  } else return '';
};
