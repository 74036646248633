import { useState } from 'react';
import './LandingPage.css';
import MobileMockUpImage from '../assets/images/MobileMockUpImage.png';
import CoinImage from '../assets/images/Coin.png';
import GrowImage from '../assets/images/Grow.png';
import MegaphoneImage from '../assets/images/Megaphone.png';
import EasyManage1 from '../assets/images/EasyManage1.png';
import EasyManage2 from '../assets/images/EasyManage2.png';
import EasyManage3 from '../assets/images/EasyManage3.png';
import EasyManage4 from '../assets/images/EasyManage4.png';
import Supporter from '../assets/images/Supporter.png';
import faqItems from './faqItems';
import Image from './Images';
import { useNavigate } from 'react-router-dom';
import { authIntentState } from '../recoil/authIntentAtom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { authenticatedState } from '../recoil/authenticatedAtom';
import { modalState } from '../recoil/modalAtom';

const LandingPage = () => {
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);
  const navigate = useNavigate();
  const [authIntent, setAuthIntent] = useRecoilState(authIntentState);
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(authenticatedState);
  const setModal = useSetRecoilState(modalState);

  const prepareForLogin = () => {
    const path = window.location.pathname;
    setAuthIntent({
      ...authIntent,
      intent: 'host',
      previousPath: path,
      intentPath: '/host/manage-storage',
    });
    if (!isAuthenticated) {
      // navigate('/log-in');
      setModal('login');
    } else {
      navigate('/host/manage-storage');
    }
  };
  const toggleQuestion = (index) => {
    setOpenQuestionIndex(openQuestionIndex === index ? null : index);
  };

  return (
    <div className="landing-page">
      <header className="header">
        <section className="row-section">
          <div>
            <h1>
              lug-ez 파트너가 되어
              <br /> 새로운 수익을 창출하세요
            </h1>
            <p style={{ marginTop: 5 }}>별도의 비용없이 무료로 고객들에게 업체를 홍보하고</p>

            <p style={{ marginTop: 3 }}> 짐 보관 비용으로 추가 수익을 얻을 수 있습니다.</p>
            <button onClick={prepareForLogin} className="button first-button">
              지금 시작하세요
            </button>
          </div>
          <Image src={MobileMockUpImage} className="header-image" />
        </section>
      </header>
      <main className="main">
        <section className="benefit-section">
          <h1>다음과 같은 혜택을 누리세요</h1>
          <ul className="benefits-list">
            {[
              {
                src: CoinImage,
                title: '추가 수익',
                description: '각 가방 보관 시 보관료를 받으세요.',
              },
              {
                src: GrowImage,
                title: '더 많은 트래픽',
                description:
                  '더 많은 방문자들을 만들어 새로운 고객들에게 제품을 노출시킬 수 있어요.',
              },
              {
                src: MegaphoneImage,
                title: '홍보 효과',
                description: '플랫폼을 통해 사용자들에게 가게를 무료로 홍보하세요.',
              },
            ].map((benefit, index) => (
              <li key={index} className="benefit-item">
                <Image src={benefit.src} className="benefit-image" />
                <h2>{benefit.title}</h2>
                <span className="benefit-description">{benefit.description}</span>
              </li>
            ))}
          </ul>
        </section>
        <section className="section">
          <h1>더 많은 고객, 더 큰 수익</h1>
          <p>지금 lug-ez 파트너가 되어</p>
          <p> 새로운 수익을 창출하세요.</p>
          <button onClick={prepareForLogin} className="button">
            지금 시작하세요
          </button>
        </section>
        <section className="EasyManage-section">
          <h1>간단한 관리</h1>
          <div className="EasyManage-list">
            {[
              [EasyManage1, '한 눈에 고객의 예약을 확인하세요'],
              [EasyManage2, '보관 현황을 확인하세요'],
              [EasyManage3, '이용 내역을 확인하세요'],
            ].map((src, index) => (
              <div className="EasyMange-container" key={index}>
                <div>{src[1]}</div>
                <Image src={src[0]} className="EasyManage-image" />
              </div>
            ))}
          </div>
        </section>
        <section className="EasyManage-section">
          <h1>손쉬운 수정</h1>
          <div className="EasyManage-list-column">
            <div>손쉽게 업체 정보를 관리할 수 있어요.</div>
            <Image src={EasyManage4} className="EasyEdit-image" />
          </div>
        </section>
        <section className="section faq-section">
          <h1 className="many-question">자주 묻는 질문</h1>
          <ul className="faq-list">
            {faqItems.map((item, index) => (
              <li key={index} className={`faq-item ${openQuestionIndex === index ? 'open' : ''}`}>
                <button className="toggle-button" onClick={() => toggleQuestion(index)}>
                  {item.question}
                </button>
                <p>{item.answer}</p>
              </li>
            ))}
          </ul>
        </section>
        <section className="header">
          <section className="row-section">
            <div>
              <h1>
                lug-ez에 대해 <br />
                궁금하신점이 있으신가요?
              </h1>
              <p style={{ marginTop: 5 }}>궁금하신점이 있다면 24시간 언제든 문의 해주세요.</p>
              <p style={{ marginTop: 5 }}>대표자 강가림</p>
              <p style={{ marginTop: 5 }}>010-4018-1368</p>
              <button onClick={prepareForLogin} className="button">
                호스트 등록하기
              </button>
            </div>
            <div>
              <Image src={Supporter} className="supporter-image" />
            </div>
          </section>
        </section>
      </main>
    </div>
  );
};

export default LandingPage;
